<template>
  <div class="resources webview">
    <a-row style="height: 100%">
      <!-- tag:左侧地图 -->
      <a-col style="width:100%;height: 50%;background:#fafafa;" class="left-content">
        <!-- <button @click="next()">切换</button>
        <button @click="callback">返回</button> -->
        <!-- <div class="map-name">
          <a-breadcrumb class="breadcrumb">
            <span slot="separator" style="color: var(--blue-color)">></span>
            <a-breadcrumb-item href="">银星科技园</a-breadcrumb-item>
          </a-breadcrumb>
        </div> -->
        <div style="height: 100%" id="mapContent" :key="key">
          <a-tabs
            default-active-key="1"
            tab-position="top"
            :style="{ height: '44px' }"
            @prevClick="callback"
            @nextClick="callback"
            class="floor-change"
            v-show="tabShow"
          >
            <a-tab-pane v-for="i in 30" :key="i" :tab="`${i}F`">
              {{ i + "F" }}
            </a-tab-pane>
          </a-tabs>
        </div>
      </a-col>
      <!-- tag:右边数据栏 -->
      <a-col
        
        style="
          height: 100vh;
          position: relative;
          bottom:0;
          overflow: hidden;
          border-radius: 16px 16px 0px 0px;
          margin-top:-20px;
          box-shadow: 0px 2px 24px 0 rgba(21, 28, 81, 0.15);
          background:#ffffff;
        "
      >
        <div class="spin" v-show="spinShow">
          <a-spin tip="Loading..."> </a-spin>
        </div>
        <Allresources :id="parseInt(parkId)" :level="'park'"></Allresources>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Api } from "../../api/api.js";
import "ol/ol.css";
import { Map, View, Overlay, Feature } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorSource, ImageStatic } from "ol/source";
import { Vector as VectorLayer, Image } from "ol/layer";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
// import render3D from "ol-ext/layer/Render3D";
import { Draw, Select, DoubleClickZoom } from "ol/interaction";
import { getCenter, boundingExtent } from "ol/extent";
import { createBox } from "ol/interaction/Draw";
import { click, doubleClick } from "ol/events/condition";
import * as THREE from "three";
import Stats from "three/examples/jsm/libs/stats.module.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import Allresources from "../../components/webview/allResources.vue";
import wx from 'weixin-js-sdk';
var container, stats, controls;
var camera, scene, renderer, light, mesh;
export default {
  components: {
    Allresources,
  },
  data() {
    return {
      measureVisible: false,
      key: 0,
      tabShow: false,
      width: 0,
      height: 0,
      parkId: undefined,
      parkData: {},
      areaData: {},
      parkJson: null,
      parkImage: null,
      aId: null,
      transform: null,
      clientWidth:document.documentElement.clientWidth,
      spinShow:true,//加载中
      // clientHeight:document.documentElement.clientHeight
    };
  },
  created(){
    this.parkId = this.$route.query.parkId;
  },
  async mounted() {
    
    this.width = document.getElementById("mapContent").clientWidth;
    this.height = document.getElementById("mapContent").clientHeight;
    await this.getAreasList();
    await this.getPark();
    this.mapInit();
    setTimeout(() => {
      this.transform = "right";
    }, 500);
    var that = this;
    that.myTimer = setTimeout(function(){
      that.spinShow = false;//加载完毕
      console.log(that.spinShow)
      clearTimeout(that.myTimer)
    },500)
  },
  methods: {
    keyadd() {
      this.key++;
    },
    // done:地图初始化
    mapInit() {
      var image = new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({ color: "red", width: 1 }),
      });
      var styles = {
        Point: new Style({
          image: image,
        }),
        LineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiLineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiPoint: new Style({
          image: image,
        }),
        MultiPolygon: new Style({
          stroke: new Stroke({
            color: "black",
            width: 1,
          }),
          fill: new Fill({
            color: "white",
          }),
        }),
        Polygon: new Style({
          // stroke: new Stroke({
          //   color: "blue",
          //   lineDash: [4],
          //   width: 3,
          // }),
          fill: new Fill({
            color: "rgba(0, 0, 255, 0.1)",
          }),
        }),
        GeometryCollection: new Style({
          stroke: new Stroke({
            color: "magenta",
            width: 2,
          }),
          fill: new Fill({
            color: "magenta",
          }),
          image: new CircleStyle({
            radius: 10,
            fill: null,
            stroke: new Stroke({
              color: "magenta",
            }),
          }),
        }),
        Circle: new Style({
          stroke: new Stroke({
            color: "red",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(255,0,0,0.2)",
          }),
        }),
      };

      var styleFunction = function (feature) {
        return styles[feature.getGeometry().getType()];
      };

      this.vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(this.parkJson),
        wrapX: false,
        zIndex: 9998,
      });

      this.vectorLayer = new VectorLayer({
        source: this.vectorSource,
        style: styleFunction,
        zIndex: 9998,
      });
      var center = [500, -600];
      // let width = document.getElementById("mapContent").clientWidth;
      // let height = document.getElementById("mapContent").clientHeight;
      //   var center = transform([500, -600], "EPSG:4326", "EPSG:3857");
      //计算静态地图映射到地图上的范围，图片像素为550*344，保持比例的情况下，把分辨率放大一些
      var extent = [
        center[0] - (1700 * 2) / 2,
        center[1] - (1050 * 2) / 2,
        center[0] + (1700 * 2) / 2,
        center[1] + (1050 * 2) / 2,
      ];
      // var extent = [
      //   center[0] - (1320 * 2) / 2,
      //   center[1] - (685 * 2) / 2,
      //   center[0] + (1320 * 2) / 2,
      //   center[1] + (685 * 2) / 2,
      // ];
      this.map = new Map({
        layers: [this.vectorLayer],
        view: new View({
          center: center,
          extent: extent,
          enableRotation: false,
          //   center: [500, -600],
          zoom: 15, // 定义地图显示层级为2
          // padding: [100, 0, 0, 0],
        }),
        // 让id为map的div作为地图的容器
        target: "mapContent",
      });
      const dblClickInteraction = this.map
        .getInteractions()
        .getArray()
        .find((interaction) => {
          return interaction instanceof DoubleClickZoom;
        });
      this.map.removeInteraction(dblClickInteraction);
      // done:添加底图
      this.map.addLayer(
        new Image({
          source: new ImageStatic({
            // url: "https://minio.yshinfo.com/yinxing/mryeov2eyx6nluj3hgof3utsh56k",
            url: this.parkImage, // 静态地图
            imageExtent: extent, //映射到地图的范围
          }),
        })
      );
      // console.log(this.map);
      let that = this;

      // done:图形双击
      let selectClick = new Select({
        // 事件类型
        condition: doubleClick,
        // 点击后的样式
        style: new Style({
          fill: new Fill({
            color: "rgba(99,149,250,0.5)",
          }),
        }),
      });
      //done:图形点击
      var select = new Select({
        style: function (fea, res) {
          return [
            new Style({
              fill: new Fill({
                color: "rgba(99,149,250,0.5)",
              }),
            }),
          ];
        },
      });
      select.on("select", function (e) {
        this.getFeatures().clear();
        if(that.getPower({code: e.selected[0].values_.role})){
          wx.miniProgram.navigateTo(
          {
            url: '/pages/overview/viewArea/area?parkId='+that.parkId+'&aId='+e.selected[0].values_.id
          }
        )
         }else {
           that.$message.error('无权限访问')
         }
        
      });
      //done:弹窗展示
      // var element;
      // var itemCenter;
      // var popup;
      // var popupName;
      // let featureList = this.vectorSource.getFeatures();
      // featureList.forEach((item, index) => {
      //   popupName = "popup" + index;
      //   popup = new Overlay({
      //     element: document.getElementById(popupName),
      //   });
      //   element = popup.element;
      //   that.map.addOverlay(popup);
      //   itemCenter = getCenter(
      //     boundingExtent(item.getGeometry().getCoordinates()[0])
      //   ); // 获取图形中点
      //   itemCenter[1] = itemCenter[1] + 20;
      //   popup.setPosition(itemCenter); // 将弹窗位置设置为图形中点
      //   element.innerHTML =
      //     "<p style='color:white;font-size:20px;'> " + item.values_.id + "</p>";
      // });
      this.map.addInteraction(select);
      //   var r3D = new render3D({ height: 100, defaultHeight: 10 });
      //   vectorLayer.setRender3D(r3D);
      
    },
    getPower({ code, arType })  {
      if (null != code){
        let item = localStorage.getItem("role");
        if(item){
          let items = item.split(",")
          for (let item of items) {
            if (code.includes(item)){
              return true;
            }
          }
        }

      }

      // if (code.find((item)=>data.indexOf(item) !== -1)) {
      //   return true;
      // }
    },
 
    callback() {
      this.key++;
      setTimeout(() => {
        this.mapInit();
      }, 1000);
    },
    next() {
      this.key++;
      setTimeout(() => {
        this.map3DInit();
      }, 1000);
    },
    // done:查询所有区域
    async getAreasList() {
      const res = Api.getAreasList(this.parkId);
    },
    // done:获取区域数据
    async getAreaData(areaId) {
      const res = await Api.getAreaData(areaId);
      this.areaData = res.data;
      this.measureVisible = true;
      this.spinShow = false;//加载完毕
    },
    // done:获取园区底图和geojson
    async getPark() {
      const res = await Api.getPark(this.parkId);
      this.parkJson = res.data.parkData;
      this.parkImage = res.data.parkPicture;
      document.title = res.data.parkName;
      // wx.miniProgram.postMessage({data:{title:res.data.parkName} });
      
    },
    // done:进入
    enter() {
      this.$router.push({
        path: "/resources/area",
        query: { parkId: this.parkId, aId: this.aId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.spin {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  z-index: 9999;
  background: white;
}
.resources {
  height: 100%;
  // overflow: hidden;
  .left-content {
    height: 100%;
    position: relative;
    background: white;
    .map-name {
      height: 54px;
      width: calc(100% - 10px);
      background-image: url("../../assets/index/name_bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 9998;
      left: 50%;
      transform: translateX(-50%);
      top: 5px;
      .ol-control {
        display: none;
      }
      .breadcrumb {
        position: absolute;
        top: 50%;
        left: 18%;
        font-size: 12px;
        color: var(--blue-color);
        transform: translateY(-50%);
        span:last-child a {
          font-family: Microsoft YaHei UI;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0.12em;
          color: #055fcd;
        }
      }
    }
    .measure-popover {
      position: absolute;
      // top: 50% !important;
      left: 67% !important;
      top: 25%;
      // transform: translate();
    }
  }
}
.popover-box {
  padding: 12px;
  width: 432px;
  height: 380px;
  .box-title {
    height: 44px;
  }
}
.box-content {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .text {
    font-size: 16px;
    color: #151a30;
    line-height: 200%;
    margin-right: 20px;
  }
}
.right {
  transition: transform 0.35s;
}
.right {
  transform: translate(-600px);
}
.left {
  transition: transform 0.35s;
  transform: translate(600px, 0px);
}
</style>
<style lang="scss">
.webview {
  .breadcrumb {
    span:last-child a {
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.12em;
      color: #055fcd !important;
    }
  }
  .ol-control {
    display: none;
  }
  #mapContent {
    .ant-tabs-nav .ant-tabs-tab {
      background: #f0f5ff;
      border-radius: 6px;
      width: 54px;
    }
    .ant-tabs-nav-scroll {
      overflow-y: hidden;
      overflow-x: scroll;
      overflow: none !important;
      white-space: nowrap;
    }
    .ant-tabs-nav-scroll::-webkit-scrollbar {
      width:0 !important;
      height: 0 !important;
    }
    .ant-tabs-tab-prev-icon,
    .ant-tabs-tab-next-icon {
      line-height: 40px;
      width: 100%;
      background-color: #f0f5ff;
      /* margin-right: 20px !important; */
      color: var(--blue-color);
    }
    .ant-tabs-bar {
      border: none !important;
    }
    .ant-carousel .slick-dots {
      position: fixed !important;
      bottom: 30px !important;
    }
    .ant-carousel .slick-dots li {
      width:10px;
      height:10px;
      border:1px solid #6395FA;
      border-radius: 100%;
    }
    .ant-carousel .slick-dots li.slick-active button {
      width:10px;
      height:10px;
      border:1px solid #6395FA;
      margin-top: -1px;
      margin-left: -1px;
      background:#6395FA;
      border-radius: 100%;
    }
  }
}
</style>