export const setData = (data) => {
  localStorage.setItem("role", data.join(","));
};

export const getPower = ({ code, arType }) => {
  const data = localStorage.getItem("role");
  console.log("get", { code, arType, data });
  if (data.includes("YJQX")) {
    return true;
  }
  if (data.includes(code)) {
    return true;
  }
};
