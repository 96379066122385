<template>
  <div class="resources webview">
    <a-row style="height: 100%">
      <!-- tag:左侧地图 -->
      <a-col
        :style="{ height: !companyShow ? '50%' : '55%' }"
        style="width: 100%; backgrouond: #fafafa"
        class="left-content"
      >
        <div class="position-box">
          <a-radio-group
            v-if="buildList.length > 1"
            :value="buildIndex"
            @change="handleBuildChange"
            style="font-size: 12px; margin-bottom: 10px"
          >
            <a-radio-button
              v-for="(item, index) in buildList"
              :key="item.id"
              :value="index"
            >
              {{ item.buildingName }}
            </a-radio-button>
          </a-radio-group>
          <a-tabs
            :default-active-key="1"
            :activeKey="floorKey"
            tab-position="top"
            :animated="false"
            :style="{ height: '44px' }"
            class="floor-change"
            @tabClick="changeFloor"
          >
            <a-tab-pane
              v-for="item in floorList"
              :key="item.id"
              :tab="`${item.floorName}F`"
              :disabled="item.deleteFlag == 1"
            >
              {{ item.floorName + "F" }}
            </a-tab-pane>
          </a-tabs>
        </div>
        <div style="height: 100%" id="mapContent" :key="key"></div>
      </a-col>
      <!-- tag:右边数据栏 height: !companyShow ? 'calc(50% + 20px)' : 'calc(45% + 20px)', -->
      <a-col
        :style="{
          minHeight:'100vh',
          width: clientWidth,
        }"
        style="
          background: #ffffff;
          position: relative;
          border-radius: 16px 16px 0px 0px;
          margin-top: -20px;
          box-shadow: 0 -5px 24px 0 rgba(21, 28, 81, 0.15);
          bottom: 0;
        "
      >
        <div class="spin" v-show="spinShow">
          <a-spin tip="Loading..."> </a-spin>
        </div>
        <Allresources
          :id="parseInt(id)"
          :level="level"
          v-show="!companyShow"
        ></Allresources>
        <Company v-show="companyShow" :rId="rId"></Company>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Api } from "../../api/api.js";
import "ol/ol.css";
import { Map, View, Overlay, Feature } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorSource, ImageStatic } from "ol/source";
import { Vector as VectorLayer, Image } from "ol/layer";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
// import render3D from "ol-ext/layer/Render3D";
import { Draw, Select, DoubleClickZoom } from "ol/interaction";
import { getCenter, boundingExtent } from "ol/extent";
import { createBox } from "ol/interaction/Draw";
import { Point } from "ol/geom";
import { click, doubleClick } from "ol/events/condition";
import Allresources from "../../components/webview/allResources.vue";
import Company from "../../components/webview/company-detail.vue";
export default {
  components: {
    Allresources,
    Company,
  },
  data() {
    return {
      measureVisible: false,
      key: 0,
      width: 0,
      height: 0,
      parkId: undefined,
      parkData: {},
      fId: undefined,
      areaName: null,
      buildingGroupName: null,
      companyShow: false,
      floorList: [],
      floorKey: 2,
      floorName: null,
      floorHeight: null,
      floorWidth: null,
      rId: 0,
      spinShow: true,
      floorData: null,
      transform: null,
      mapClass: ["animate__animated", "animate__delay-0.5s", "animate__zoomIn"],
      navClass: ["map-name", "animate__animated", "animate__backInDown"],
      clientWidth: document.documentElement.clientWidth,
      buildList: [],
      buildIndex: 0,
      level: "building",//默认展示楼栋数据
      id:0,//传值id
    };
  },
  created(){
    this.bId = this.$route.query.bId;
    this.id = this.bId;
  },
  async mounted() {
    let item = localStorage.getItem("role");
    let items = item.split(",")
    let item1 = ["YJQX","EJQX","CQSJQX","DSSJQX","ZJSJQX","GYSSSJQX"];
    for (let itemElement of items) {
      if (item1.includes(itemElement)){
        let vm = this;
        vm.bId = this.$route.query.bId;
        vm.id = vm.bId;
        this.width = document.getElementById("mapContent").clientWidth;
        this.height = document.getElementById("mapContent").clientHeight;
        await vm.getFloorList();
        await vm.getRoomsJson();
        vm.mapInit();
      }
    }
  },
  methods: {
    keyadd() {
      this.key++;
    },
    // done:地图初始化
    mapInit() {
      var image = new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({ color: "red", width: 1 }),
      });
      var styles = {
        Point: new Style({
          image: image,
        }),
        LineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiLineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiPoint: new Style({
          image: image,
        }),
        MultiPolygon: new Style({
          stroke: new Stroke({
            color: "black",
            width: 1,
          }),
          fill: new Fill({
            color: "white",
          }),
        }),
        Polygon: new Style({
          // stroke: new Stroke({
          //   color: "blue",
          //   lineDash: [4],
          //   width: 3,
          // }),
          fill: new Fill({
            color: "rgba(0, 0, 255, 0.1)",
          }),
        }),
        GeometryCollection: new Style({
          stroke: new Stroke({
            color: "magenta",
            width: 2,
          }),
          fill: new Fill({
            color: "magenta",
          }),
          image: new CircleStyle({
            radius: 10,
            fill: null,
            stroke: new Stroke({
              color: "magenta",
            }),
          }),
        }),
        Circle: new Style({
          stroke: new Stroke({
            color: "red",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(255,0,0,0.2)",
          }),
        }),
      };
      var colorArr = [
        // "rgba(99,149,250,0.5)",
        "rgba(250,135,99,0.5)",
        "rgba(99,114,250,0.5)",
        "rgba(246,210,85,0.5)",
        "rgba(99,218,171,0.5)",
      ];
      var linkColorArr = ["#FA8763", "#6372FA", "#F6D255", "#63DAAB"];
      var styleFunction = function (feature) {
        // return styles[feature.getGeometry().getType()];
        return new Style({
          stroke: new Stroke({
            color: linkColorArr[feature.id % 4],
            // lineDash: [4],
            width: 2,
          }),
          fill: new Fill({
            color: colorArr[feature.id % 4],
          }),
          text: new Text({
            text: feature.values_.companyName,
            font: 'bold 14px "Open Sans", "Arial Unicode MS", "sans-serif"',
            placement: "point",
            fill: new Fill({
              color: "black",
            }),
          }),
        });
      };
      var newstyleFunction = function (feature) {
        // return styles[feature.getGeometry().getType()];
        return new Style({
          stroke: new Stroke({
            color: "rgba(192,192,192,0.5)",
            // lineDash: [4],
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(192,192,192,0.5)",
          }),
          text: new Text({
            text: feature.values_.companyName,
            font: 'bold 14px "Open Sans", "Arial Unicode MS", "sans-serif"',
            placement: "point",
            fill: new Fill({
              color: "black",
            }),
          }),
        });
      };
      var selectStyleFunction = function (feature) {
        // return styles[feature.getGeometry().getType()];
        return new Style({
          stroke: new Stroke({
            color: "rgba(99,149,250,1)",
            // lineDash: [4],
            width: 4,
          }),
          fill: new Fill({
            color: "rgba(99,149,250,0.3)",
          }),
          text: new Text({
            text: feature.values_.companyName,
            font: 'bold 14px "Open Sans", "Arial Unicode MS", "sans-serif"',
            placement: "point",
            fill: new Fill({
              color: "black",
            }),
          }),
        });
      };
      const style = new Style({
        text: new Text({
          font: 'bold 11px "Open Sans", "Arial Unicode MS", "sans-serif"',
          placement: "line",
          fill: new Fill({
            color: "white",
          }),
        }),
      });
      //   var styleFunction = function (feature) {
      //     return styles[feature.getGeometry().getType()];
      //   };

      if (this.level == "floor") {
        this.vectorSource = new VectorSource({
          features: new GeoJSON().readFeatures(this.floorData || []),
          wrapX: false,
          zIndex: 9998,
        });
      } else {
        this.vectorSource = new VectorSource({
          wrapX: false,
          zIndex: 9998,
        });
      }

      this.vectorLayer = new VectorLayer({
        source: this.vectorSource,
        style: styleFunction,
        zIndex: 9998,
      });
      var center = [500, -600];
      //计算静态地图映射到地图上的范围，图片像素为550*344，保持比例的情况下，把分辨率放大一些
      var extent = [
        center[0] - (this.floorWidth * 2.4) / 2,
        center[1] - (this.floorHeight * 2.4) / 2,
        center[0] + (this.floorWidth * 2.4) / 2,
        center[1] + (this.floorHeight * 2.4) / 2,
      ];
      this.map = new Map({
        layers: [this.vectorLayer],
        view: new View({
          center: center,
          enableRotation: false,
          // extent: extent,
          //   center: [500, -600],
          zoom: 14, // 定义地图显示层级为2
          padding: [50, 0, 0, 0],
          minZoom: 14,
        }),
        // 让id为mapContent的div作为地图的容器
        target: "mapContent",
      });
      const dblClickInteraction = this.map
        .getInteractions()
        .getArray()
        .find((interaction) => {
          return interaction instanceof DoubleClickZoom;
        });
      this.map.removeInteraction(dblClickInteraction);
      // done:添加底图
      this.map.addLayer(
        new Image({
          source: new ImageStatic({
            // url: "https://minio.yshinfo.com/yinxing/mryeov2eyx6nluj3hgof3utsh56k",
            url: this.floorImage, // 静态地图
            imageExtent: extent, //映射到地图的范围
          }),
        })
      );
      // console.log(this.map);
      let that = this;
      // done:图形双击
      let selectClick = new Select({
        // 事件类型
        condition: doubleClick,
        // 点击后的样式
        style: new Style({
          fill: new Fill({
            color: "rgba(99,149,250,0.5)",
          }),
        }),
      });
      selectClick.on("select", function (e) {
        console.log(e);
      });
      //   this.map.addInteraction(selectClick);
      //done:图形点击
      var select = new Select({
        style: selectStyleFunction,
      });
      select.on("select", function (e) {
        that.rId = e.selected[0].values_.id;
        that.spinShow = true;
        that.myTimer = setInterval(function () {
          that.spinShow = false; //加载完毕
          clearInterval(that.myTimer);
        }, 1500);
        that.transform = "left";
        setTimeout(() => {
          that.companyShow = true;
        }, 500);
        setTimeout(() => {
          that.transform = "right";
        }, 600);
        // setTimeout(() => {
        //   that.spinShow = false;
        // }, 1000);
        // var features = e.target.getFeatures().getArray();
        // var feature = features[0];
        // console.log(feature);
        // that.selectedId = e.selected[0].ol_uid;
        // var jsObj = JSON.parse(new GeoJSON().writeFeatures(features))
        //   .features[0];
        // that.measureVisible = true;
        // var jsonObj = JSON.stringify(jsObj);
        // var property = feature.getProperties();
      });
      //   添加标注
      let featureList = this.vectorSource.getFeatures();

      var labelStyle;
      var itemCenter;
      var element;
      var popup;
      var popupName;
      //   featureList.forEach((item, index) => {
      //     labelStyle = new Style({
      //       text: new Text({
      //         font: "bold 14px serif",
      //         text: item.values_.companyName,
      //         fill: new Fill({
      //           color: "rgba(255, 0, 0, 1)",
      //         }),
      //       }),
      //     });
      //     itemCenter = getCenter(
      //       boundingExtent(item.getGeometry().getCoordinates()[0])
      //     ); // 获取图形中点
      //     let labelFeature = new Feature({
      //       geometry: new Point(itemCenter),
      //     });
      //     labelFeature.setStyle(labelStyle);
      //     this.vectorSource.addFeature(labelFeature);
      //   });
      //   done:弹窗展示

      featureList.forEach((item, index) => {
        item.id = index;
        popupName = "popup" + index;
        popup = new Overlay({
          element: document.getElementById(popupName),
        });
        element = popup.element;
        that.map.addOverlay(popup);
        itemCenter = getCenter(
          boundingExtent(item.getGeometry().getCoordinates()[0])
        ); // 获取图形中点
        itemCenter[1] = itemCenter[1] + 60;
        itemCenter[0] = itemCenter[0] - 60;
        popup.setPosition(itemCenter); // 将弹窗位置设置为图形中点
      
      });
      this.map.addInteraction(select);
    },
    // done:根据组别id查询所有楼层
    async getFloorList() {
      const res = await Api.getFloorList(parseInt(this.bId));
      this.fId = res.data.data[0].floorData[0].id;
      res.data.data.forEach((item, i) => {
        if (item.deleteFlag !== 1) {
          this.buildList.push(item);
        }
      });
      // this.buildList = res.data.data;
      this.fId = this.buildList[0].floorData[0].id;
      this.floorList = this.buildList[0].floorData.reverse();
      // this.floorList = res.data.data[0].floorData.reverse();
    },

    // done:获取楼层数据、楼层底图和geojson
    async getRoomsJson() {
      const res = await Api.getRoomsJson(this.fId);
      this.areaName = res.data.areaName;
      this.buildingGroupName = res.data.buildingGroupName;
      this.floorName = res.data.floorName + "F";
      document.title = this.buildingGroupName + " > " + this.floorName;
      // this.floorKey = res.data.id;
      this.floorImage = res.data.buildingImage;
      this.floorWidth = res.data.buildingLength;
      this.floorHeight = res.data.buildingWidth;
      this.spinShow = false;
      this.floorData = res.data.data;
    },
    // done:楼层改变
    async changeFloor(id) {
      this.level = 'floor'
      this.id = id;
      this.fId = id;
      this.floorKey = id;
      this.key++;
      await this.getRoomsJson();
      this.companyShow = false;
      setTimeout(() => {
        this.mapInit();
      }, 300);
    },
    handleBuildChange(e) {
      this.buildIndex = e.target.value;
      this.floorList = this.buildList[e.target.value].floorData.reverse();
    },
  },
};
</script>
<style lang="scss" scoped>
.resources {
  height: 100%;
  // overflow: hidden;
  .left-content {
    height: 100%;
    position: relative;
    background: white;
    .map-name {
      height: 54px;
      width: calc(100% - 10px);
      background-image: url("../../assets/index/name_bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 9998;
      left: 50%;
      transform: translateX(-50%);
      top: 5px;
      box-shadow: 0px 2px 24px rgba(21, 28, 81, 0.15);
      .ol-control {
        display: none;
      }
      .breadcrumb {
        position: absolute;
        top: 50%;
        left: 18%;
        font-size: 14px;
        color: var(--blue-color);
        transform: translateY(-50%);
        span:last-child a {
          font-family: Microsoft YaHei UI;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0.12em;
          color: #055fcd;
        }
      }
    }
    .position-box {
      position: absolute;
      z-index: 9998;
      width: calc(100% - 30px);
      left: 50%;
      transform: translateX(-50%);
      top: 20px;
    }
    .floor-change {
      // position: absolute;
      // width: calc(100% - 30px);
      // z-index: 9998;
      // left: 50%;
      // transform: translateX(-50%);
      // top: 50px;
      //   background: white;
      color: var(--blue-color);
      font-size: 14px !important;
      font-weight: 600;
    }
    .measure-popover {
      position: absolute;
      bottom: 20px;
    }
  }
}
.popover-box {
  padding: 12px;
  width: 432px;
  height: 380px;
  .box-title {
    height: 44px;
  }
}
.box-content {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .text {
    font-size: 16px;
    color: #151a30;
    line-height: 200%;
    margin-right: 20px;
  }
}
.spin {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  z-index: 9999;
  background: white;
}
</style>
<style lang="scss">
.webview {
  .breadcrumb {
    span:last-child a {
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.12em;
      color: #055fcd !important;
    }
  }
  .ol-control {
    display: none;
  }

  .ant-tabs-nav .ant-tabs-tab {
    background: #f0f5ff;
    border-radius: 6px;
    // width: 54px;
    text-align: center;
    opacity: 0.85;
  }
  .ant-tabs-tab-prev-icon,
  .ant-tabs-tab-next-icon {
    line-height: 40px;
    width: 100%;
    background-color: #f0f5ff;
    /* margin-right: 20px !important; */
    color: var(--blue-color);
  }
  .ant-tabs-bar {
    border: none !important;
  }

  .ant-radio-button-wrapper {
    padding: 0 10px;
    opacity: 0.8;
    color: #151a30;
  }

  .ant-tabs-nav .ant-tabs-tab {
    padding: 10px;
  }
  .ant-carousel .slick-dots {
    position: fixed;
    bottom: 30px;
  }
  .ant-carousel .slick-dots li {
    width: 10px;
    height: 10px;
    border: 1px solid #6395fa;
    border-radius: 100%;
  }
  .ant-carousel .slick-dots li.slick-active button {
    width: 10px;
    height: 10px;
    border: 1px solid #6395fa;
    margin-top: -1px;
    margin-left: -1px;
    background: #6395fa;
    border-radius: 100%;
  }
  .ant-table-placeholder {
    border-bottom-width: 0;
  }
  // .com-webview .ant-carousel .slick-dots {
  //   margin-bottom: 50px;
  // }
}
</style>