<template>
  <div class="container">
    <div class="main">
      <div class="login-box">
        <a-row style="height: 100%">
          <a-col :span="13" class="right-content">
            <img src="../assets/login/logo.png" alt="" class="img" />
            <div class="bottom-text">
              <div class="text">打造智慧园区</div>
              <div class="text">让空间更好服务产业</div>
              <div class="line"></div>
            </div>
          </a-col>
          <a-col :span="11" class="left-content">
            <div class="title">产业运营管理系统</div>
            <a-input
              size="large"
              ref="userNameInput"
              v-model="phone"
              placeholder="手机号"
              style="width: 278px"
            >
              <a-icon slot="prefix" type="mobile" />
            </a-input>
            <a-input
              size="large"
              ref="userNameInput"
              v-model="password"
              placeholder="密码"
              style="width: 278px"
              type='password'
            >
              <a-icon slot="prefix" type="lock" />
            </a-input>
            <!-- <div>
              <a-input
                size="large"
                ref="userNameInput"
                v-model="code"
                placeholder="验证码"
                style="width: 176px"
              >
                <a-icon slot="prefix" type="safety" />
              </a-input>
            </div> -->
            <a-button
              style="
                width: 286px;
                background: #055fcd;
                color: white;
                border-radius: 8px;
                margin-top: 40px;
              "
              size="large"
              @click="login"
              >登录</a-button
            >
            <!-- <div style="margin-top: 40px; text-align: center; width: 286px">
              <span class="forget">忘记密码</span>
            </div> -->
          </a-col>
        </a-row>
      </div>
      <div class="copyright">
        Copyright © 2021 亦笙信息技术(深圳)有限公司 | 备案号：粤ICP备18110633号
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "../api/api.js";
import {setData,getPower} from "../router/power.js"
export default {
  data() {
    return {
      phone: undefined,
      password: undefined,
      code: undefined,
    };
  },
  methods: {
    async login() {
      // this.$router.push("/");
      const res = await Api.login({
        phone: this.phone,
        passwordDigest: this.password,
      });
      if (res.success == true) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("name", res.data.name);
        setData(res.data.role)
        // localStorage.setItem("role",res.data.role);
        this.$message.success("登录成功!");
        this.$router.push("/");
        return false;
      }
      this.$message.error("请输入正确的手机号和密码");
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1600px) {
  .container {
    height: 100%;
    background-image: url("../assets/login/login-background.jpg");
    background-size: 100% 100%;
    // background-position-y: 10%;
    background-repeat: no-repeat;
    .img {
      width: calc(100% - 40px);
      // height: ;
    }
    .main {
      height: 100%;
      width: 100%;
      background: rgba(16, 36, 87, 0.55);
      position: relative;
      .login-box {
        height: 60%;
        width: 70%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: rgba(17, 47, 85, 0.9);
        .right-content {
          height: 100%;
          color: white;
          padding: 64px;
          position: relative;
          .bottom-text {
            position: absolute;
            bottom: 10%;
            width: 80%;
            .text {
              font-size: 24px;
              line-height: 180%;
            }
            .line {
              height: 1px;
              background: white;
              margin-top: 40px;
              width: calc(100% - 128px);
            }
          }
        }
        .left-content {
          height: 100%;
          background: #f4f5fa;
          .title {
            color: black;
            font-size: 32px;
            font-family: Microsoft YaHei UI;
            letter-spacing: 4px;
            font-weight: 800;
            line-height: 40px;
          }
          .ant-input-affix-wrapper {
            margin-top: 32px;
          }
          .forget {
            color: #055fcd;
            cursor: pointer;
          }
        }
      }
    }
    .copyright {
      color: #e0f7ff;
      text-align: center;
      position: absolute;
      bottom: 40px;
      opacity: 0.5;
      width: 100%;
      font-size: 16px;
    }
  }
}
.container {
  height: 100%;
  background-image: url("../assets/login/login-background.jpg");
  background-size: 100% 100%;
  // background-position-y: 10%;
  background-repeat: no-repeat;
  .img {
    width: calc(100% - 40px);
    // height: ;
  }
  .main {
    height: 100%;
    width: 100%;
    background: rgba(16, 36, 87, 0.55);
    position: relative;
    .login-box {
      height: 60%;
      width: 70%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: rgba(17, 47, 85, 0.9);
      .right-content {
        height: 100%;
        color: white;
        padding: 64px;
        position: relative;
        .bottom-text {
          position: absolute;
          bottom: 10%;
          width: 80%;
          .text {
            font-size: 24px;
            line-height: 180%;
          }
          .line {
            height: 1px;
            background: white;
            margin-top: 40px;
            width: calc(100% - 128px);
          }
        }
      }
      .left-content {
        height: 100%;
        background: #f4f5fa;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title {
          color: black;
          font-size: 32px;
          font-family: Microsoft YaHei UI;
          letter-spacing: 4px;
          font-weight: 800;
          line-height: 40px;
        }
        .ant-input-affix-wrapper {
          margin-top: 32px;
        }
        .forget {
          color: #055fcd;
          cursor: pointer;
        }
      }
    }
  }
  .copyright {
    color: #e0f7ff;
    text-align: center;
    position: absolute;
    bottom: 40px;
    opacity: 0.5;
    width: 100%;
    font-size: 16px;
  }
}
</style>
<style lang="scss">
.left-content {
  .ant-input {
    background: #f4f5fa !important;
    border-radius: 8px;
  }
}
</style>>

