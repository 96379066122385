<template>
  <div class="report">
    <div class="report-box" id="report">
      <div class="report-title">
        <div>{{ "集团周工作汇报" }}</div>
        <div class="back pointer" @click="backHome">
          返回>>
        </div>
      </div>
      <div class="report-content">
        <img :src="imgUrl" alt="" style="width: 100%" />
      </div>
    </div>
  </div>
</template>

<script>
import {Api} from '../api/api';
export default {
  data() {
    return {
      imgUrl:undefined
    };
  },
  mounted(){

    let item = localStorage.getItem("role");
    let items = (item || "").split(",");
    let item1 = ["YJQX","EJQX"];
    for (let itemElement of items) {
      if (item1.includes(itemElement)){
        this.getFileUrl()
      }
    }

  },
  methods: {
      backHome(){
          this.$router.push('/home')
      },
      //获取文件地址
      getFileUrl(params){
        Api.getFileUrl(4).then((res)=>{
          // console.log(res)
          this.imgUrl = res.data.image_url
        })
      }
  },
};
</script>
<style lang="scss" scoped>
.report {
  padding: 24px;
  height: 100%;
  overflow-y: auto;
  .report-box {
    padding: 2.4rem;
    background-color: white;
    .report-title {
      background-image: url("../assets/report-title.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #151c51;
      background-color: rgba(99, 149, 250, 0.1);
      font-family: Microsoft YaHei UI;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      padding-left: 52px;
      padding-right: 24px;
      height: 48px;
      line-height: 48px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;
      .back {
        font-size: 16px;
        color: var(--blue-color);
        font-weight: normal;
      }
      .back:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>