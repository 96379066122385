<template>
  <div class="resources">
    <a-row style="height: 100%" type="flex" class="flex-row">
      <!-- tag:左侧地图 -->
      <a-col :span="18" style="height: 100%" class="left-content">
        <!-- <button @click="next()">切换</button>
        <button @click="callback">返回</button> -->
        <div :class="navClass">
          <a-breadcrumb class="breadcrumb">
            <span slot="separator" style="color: var(--blue-color)">></span>
            <a-breadcrumb-item href="" @click.native="backPark"
              >银星科技园</a-breadcrumb-item
            >
            <a-breadcrumb-item href="" @click.native="backArea">{{
              areaName
            }}</a-breadcrumb-item>
            <a-breadcrumb-item
              @click="backBuilding"
              style="cursor: auto !important"
              >{{ buildingGroupName }}</a-breadcrumb-item
            >
            <a-breadcrumb-item href="" v-if="level == 'floor'">{{
              floorName
            }}</a-breadcrumb-item>
            <!-- <a-breadcrumb-item @click.native="callback()">
              智界三期
            </a-breadcrumb-item> -->
          </a-breadcrumb>
          <div
            style="color: var(--blue-color); font-size: 16px"
            class="pointer"
            @click="backPre"
          >
            返回上一级
          </div>
        </div>
        <!-- <a-radio-group
          :value="model"
          @change="changeModel"
          style="position: absolute; bottom: 24px; left: 24px; z-index: 9998"
        >
          <a-radio-button value="2D"> 2D </a-radio-button>
          <a-radio-button value="3D"> 2.5D </a-radio-button>
        </a-radio-group> -->
        <a-radio-group
          v-if="buildList.length > 1"
          :value="buildIndex"
          @change="handleBuildChange"
          style="position: absolute; top: 80px; left: 27px; z-index: 9998"
        >
          <a-radio-button
            v-for="(item, index) in buildList"
            :key="item.id"
            :value="index"
            v-if="item.deleteFlag !== 1"
          >
            {{ item.buildingName }}
          </a-radio-button>
        </a-radio-group>
        <a-tabs
          :default-active-key="1"
          :activeKey="floorKey"
          tab-position="left"
          @prevClick="callback"
          @nextClick="callback"
          class="floor-change"
          @tabClick="changeFloor"
        >
          <a-tab-pane
            v-for="item in floorList"
            :key="item.id"
            :tab="`${item.floorName}F`"
            :disabled="item.deleteFlag == 1"
          >
          </a-tab-pane>
        </a-tabs>

        <div
          style="height: 100%"
          id="mapContent"
          :key="key"
          :class="mapClass"
        ></div>
      </a-col>
      <!-- tag:右边数据栏 -->
      <a-col
        :span="6"
        style="
          height: 100%;
          padding-left: 24px;
          position: relative;
          right: -600px;
        "
        :class="transform"
      >
        <div class="spin" v-show="spinShow">
          <a-spin tip="Loading..."> </a-spin>
        </div>
        <Allresources
          :id="parseInt(bId)"
          :level="level"
          v-if="!companyShow"
        ></Allresources>
        <Company v-if="companyShow" :rId="rId"></Company>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Api } from "../../../api/api.js";
import "ol/ol.css";
import { Map, View, Overlay, Feature } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorSource, ImageStatic } from "ol/source";
import { Vector as VectorLayer, Image, Tile } from "ol/layer";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import render3D from "ol-ext/layer/Render3D";
import vector3D from "ol-ext/source/Vector3D";
import { Draw, Select, DoubleClickZoom } from "ol/interaction";
import { getCenter, boundingExtent } from "ol/extent";
import { createBox } from "ol/interaction/Draw";
import { Point } from "ol/geom";
import { click, doubleClick } from "ol/events/condition";
import Allresources from "../../../components/allResources.vue";
import Company from "../../../components/company-detail.vue";
export default {
  components: {
    Allresources,
    Company,
  },
  data() {
    return {
      measureVisible: false,
      key: 0,
      width: 0,
      height: 0,
      parkId: undefined,
      parkData: {},
      fId: undefined,
      areaName: null,
      buildingGroupName: null,
      companyShow: false,
      floorList: [],
      floorKey: undefined,
      floorName: null,
      floorHeight: null,
      floorWidth: null,
      rId: null,
      spinShow: false,
      floorData: null,
      transform: null,
      mapClass: ["animate__animated", "animate__delay-0.5s", "animate__zoomIn"],
      navClass: ["map-name", "animate__animated", "animate__backInDown"],
      buildList: [],
      buildIndex: 0,
      model: "2D",
      zoom: 16,
      level: "building",
    };
  },
  created () {
    this.bId = this.$route.query.bId;
  },
  async mounted() {
    let item = localStorage.getItem("role");
    let items = item.split(",")
    let item1 = ["YJQX","EJQX","CQSJQX","DSSJQX","ZJSJQX","GYSSSJQX"];
    for (let itemElement of items) {
      if (item1.includes(itemElement)){
        let vm = this;
        // vm.fId = this.$route.query.bId;
        this.width = document.getElementById("mapContent").clientWidth;
        this.height = document.getElementById("mapContent").clientHeight;
        await vm.getFloorList();
        await vm.getFloor();
        await vm.getRoomsJson();
        if (window.innerWidth < 1900 && window.innerWidth > 1600) {
          // this.zoom = 15.8;
        } else if (window.innerWidth < 1600) {
          this.zoom = 15.6;
        }
        vm.mapInit();
        // vm.init();
        setTimeout(() => {
          this.transform = "right";
        }, 400);
        return;
      }
    }
  },
  methods: {
    keyadd() {
      this.key++;
    },
    // done:地图初始化
    mapInit() {
      var image = new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({ color: "red", width: 1 }),
      });
      var styles = {
        Point: new Style({
          image: image,
        }),
        LineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiLineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiPoint: new Style({
          image: image,
        }),
        MultiPolygon: new Style({
          stroke: new Stroke({
            color: "black",
            width: 1,
          }),
          fill: new Fill({
            color: "white",
          }),
        }),
        Polygon: new Style({
          // stroke: new Stroke({
          //   color: "blue",
          //   lineDash: [4],
          //   width: 3,
          // }),
          fill: new Fill({
            color: "rgba(0, 0, 255, 0.1)",
          }),
        }),
        GeometryCollection: new Style({
          stroke: new Stroke({
            color: "magenta",
            width: 2,
          }),
          fill: new Fill({
            color: "magenta",
          }),
          image: new CircleStyle({
            radius: 10,
            fill: null,
            stroke: new Stroke({
              color: "magenta",
            }),
          }),
        }),
        Circle: new Style({
          stroke: new Stroke({
            color: "red",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(255,0,0,0.2)",
          }),
        }),
      };
      var colorArr = [
        // "rgba(99,149,250,0.5)",
        "rgba(250,135,99,0.5)",
        "rgba(99,114,250,0.5)",
        "rgba(246,210,85,0.5)",
        "rgba(99,218,171,0.5)",
      ];
      var linkColorArr = ["#FA8763", "#6372FA", "#F6D255", "#63DAAB"];
      var styleFunction = function (feature) {
        // return styles[feature.getGeometry().getType()];
        return new Style({
          stroke: new Stroke({
            color: "rgba(99,149,250,1)",
            // lineDash: [4],
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(99,149,250,0.5)",
          }),
          text: new Text({
            text: feature.values_.companyName,
            font: 'bold 14px "Open Sans", "Arial Unicode MS", "sans-serif"',
            placement: "point",
            fill: new Fill({
              color: "black",
            }),
          }),
        });
      };
      var newstyleFunction = function (feature) {
        // return styles[feature.getGeometry().getType()];
        return new Style({
          stroke: new Stroke({
            color: "rgba(192,192,192,0.5)",
            // lineDash: [4],
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(192,192,192,0.5)",
          }),
          text: new Text({
            text: feature.values_.companyName,
            font: 'bold 14px "Open Sans", "Arial Unicode MS", "sans-serif"',
            placement: "point",
            fill: new Fill({
              color: "black",
            }),
          }),
        });
      };
      var selectStyleFunction = function (feature) {
        // return styles[feature.getGeometry().getType()];
        return new Style({
          stroke: new Stroke({
            color: "rgba(99,149,250,1)",
            // lineDash: [4],
            width: 4,
          }),
          fill: new Fill({
            color: "rgba(99,149,250,0.3)",
          }),
          text: new Text({
            text: feature.values_.companyName,
            font: 'bold 14px "Open Sans", "Arial Unicode MS", "sans-serif"',
            placement: "point",
            fill: new Fill({
              color: "black",
            }),
          }),
        });
      };
      const style = new Style({
        text: new Text({
          font: 'bold 11px "Open Sans", "Arial Unicode MS", "sans-serif"',
          placement: "line",
          fill: new Fill({
            color: "white",
          }),
        }),
      });
      //   var styleFunction = function (feature) {
      //     return styles[feature.getGeometry().getType()];
      //   };
      if (this.level == "floor") {
        this.vectorSource = new VectorSource({
          features: new GeoJSON().readFeatures(this.floorData),
          wrapX: false,
          zIndex: 9998,
        });
      } else {
        this.vectorSource = new VectorSource({
          wrapX: false,
          zIndex: 9998,
        });
      }

      if (this.model == "3D") {
        this.vectorLayer = new VectorLayer({
          source: this.vectorSource,
          style: newstyleFunction,
          zIndex: 9998,
        });
      } else {
        this.vectorLayer = new VectorLayer({
          source: this.vectorSource,
          style: styleFunction,
          zIndex: 9998,
        });
      }

      var center = [500, -600];
      //计算静态地图映射到地图上的范围，图片像素为550*344，保持比例的情况下，把分辨率放大一些
      var extent = [
        center[0] - (this.floorWidth * 2.4) / 2,
        center[1] - (this.floorHeight * 2.4) / 2,
        center[0] + (this.floorWidth * 2.4) / 2,
        center[1] + (this.floorHeight * 2.4) / 2,
      ];
      var extent2 = [
        center[0] - (this.floorWidth * 4.4) / 2,
        center[1] - (this.floorHeight * 4.4) / 2,
        center[0] + (this.floorWidth * 4.4) / 2,
        center[1] + (this.floorHeight * 4.4) / 2,
      ];
      // var overlay = new Overlay({
      //   element: container,
      //   autoPan: true,
      //   autoPanAnimation: {
      //     duration: 250,
      //   },
      // });
      this.map = new Map({
        layers: [this.vectorLayer],
        view: new View({
          center: center,
          //   center: [500, -600],
          zoom: this.zoom, // 定义地图显示层级为2
          padding: [100, 0, 0, 0],
          minZoom: 14.5,
        }),
        // 让id为mapContent的div作为地图的容器
        target: "mapContent",
        // overlays:[overlay]
      });
      const dblClickInteraction = this.map
        .getInteractions()
        .getArray()
        .find((interaction) => {
          return interaction instanceof DoubleClickZoom;
        });
      this.map.removeInteraction(dblClickInteraction);
      if (this.model == "3D") {
        this.map.addLayer(
          new Image({
            source: new ImageStatic({
              // url: "https://minio.yshinfo.com/yinxing/mryeov2eyx6nluj3hgof3utsh56k",
              url: this.floorImage, // 静态地图
              imageExtent: extent, //映射到地图的范围
            }),
          })
        );
      } else {
        this.map.addLayer(
          new Image({
            source: new ImageStatic({
              // url: "https://minio.yshinfo.com/yinxing/mryeov2eyx6nluj3hgof3utsh56k",
              url: this.floorImage, // 静态地图
              imageExtent: extent, //映射到地图的范围
            }),
          })
        );
      }
      // done:添加底图

      let that = this;
      //监听缩放
      this.map.on("moveend", function (e) {
        if (that.zoom == 16) {
          if (e.target.values_.view.values_.zoom < 15.02) {
            that.backPre();
          }
        } else if (that.zoom == 15.6) {
          if (e.target.values_.view.values_.zoom < 14.6) {
            that.backPre();
          }
        }
      });
      // done:图形双击
      let selectClick = new Select({
        // 事件类型
        condition: doubleClick,
        // 点击后的样式
        style: new Style({
          fill: new Fill({
            color: "rgba(99,149,250,0.5)",
          }),
        }),
      });
      selectClick.on("select", function (e) {});
      //   this.map.addInteraction(selectClick);
      //done:图形点击
      var select = new Select({
        style: selectStyleFunction,
        // stroke: new Stroke({
        //   color: "rgba(99,149,250,1)",
        //   // lineDash: [4],
        //   width: 2,
        // }),
        // fill: new Fill({
        //   color: "rgba(99,149,250,0.5)",
        // }),
        // text: new Text({
        //   text: feature.values_.companyName,
        //   font: 'bold 14px "Open Sans", "Arial Unicode MS", "sans-serif"',
        //   placement: "point",
        //   fill: new Fill({
        //     color: "black",
        //   }),
        // }),
      });
      select.on("select", function (e) {
        // that.spinShow = true;
        that.transform = "left";
        that.companyShow = true;
        setTimeout(() => {
          that.rId = e.selected[0].values_.id;
          console.log('select rid')
        }, 100);
        setTimeout(() => {
          that.transform = "right";
        }, 400);
      });
      //   添加标注
      let featureList = this.vectorSource.getFeatures();

      var labelStyle;
      var itemCenter;
      var element;
      var popup;
      var popupName;
      //   featureList.forEach((item, index) => {
      //     labelStyle = new Style({
      //       text: new Text({
      //         font: "bold 14px serif",
      //         text: item.values_.companyName,
      //         fill: new Fill({
      //           color: "rgba(255, 0, 0, 1)",
      //         }),
      //       }),
      //     });
      //     itemCenter = getCenter(
      //       boundingExtent(item.getGeometry().getCoordinates()[0])
      //     ); // 获取图形中点
      //     let labelFeature = new Feature({
      //       geometry: new Point(itemCenter),
      //     });
      //     labelFeature.setStyle(labelStyle);
      //     this.vectorSource.addFeature(labelFeature);
      //   });
      //   done:弹窗展示

      featureList.forEach((item, index) => {
        item.id = index;
        popupName = "popup" + index;
        popup = new Overlay({
          element: document.getElementById(popupName),
        });
        element = popup.element;
        that.map.addOverlay(popup);
        itemCenter = getCenter(
          boundingExtent(item.getGeometry().getCoordinates()[0])
        ); // 获取图形中点
        itemCenter[1] = itemCenter[1] + 60;
        itemCenter[0] = itemCenter[0] - 60;
        popup.setPosition(itemCenter); // 将弹窗位置设置为图形中点
        // element.innerHTML =
        //   "<p style='color: #0e0e0e; font-size: 14px;width: 80px;text-align:center;cursor:default;font-weight: 600;'> " +
        //   item.values_.companyName +
        //   //  '亦笙信息'+
        //   "</p>";
      });
      this.map.addInteraction(select);
      var r3D = new render3D({
        height: 100,
        defaultHeight: 10,
        style: new Style({
          stroke: new Stroke({
            color: "rgba(192,192,192,0.5)",
            // lineDash: [4],
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(192,192,192,0.5)",
          }),
        }),
      });
      if (this.model == "3D") {
        this.vectorLayer.setRender3D(r3D);
      }
      // const ol2dMap = this.map;
      // // console.log(new OLCesium());
      // const ol3d = new OLCesium({ map: ol2dMap }); // ol2dMap 是openlayers绑定的地图对象
      // ol3d.setEnabled(true);
      //   var r3D = new render3D({ height: 100, defaultHeight: 10 });
      //   vectorLayer.setRender3D(r3D);
    },
    // init() {
    //   var center = [500, -600];
    //   var extent = [
    //     center[0] - (this.floorWidth * 2.4) / 2,
    //     center[1] - (this.floorHeight * 2.4) / 2,
    //     center[0] + (this.floorWidth * 2.4) / 2,
    //     center[1] + (this.floorHeight * 2.4) / 2,
    //   ];
    //   var imageLayer = new AMap.ImageLayer({
    //     url: this.floorImage,
    //     bounds: new AMap.Bounds(
    //       // [116.327911, 39.939229],
    //       // [116.342659, 39.946275]
    //        [500, -600],
    //       [500.020020,-600.010581]
    //     ),
    //     zooms: [15, 18],
    //   });
    //   var map = new AMap.Map("mapContent", {
    //     resizeEnable: true,
    //     // center: [116.33719, 39.942384],
    //     center: center,
    //     zoom: 15,
    //     layers: [imageLayer],
    //   });
    //   var geojson = new AMap.GeoJSON({
    //     geoJSON: this.floorData,
    //     // 还可以自定义getMarker和getPolyline
    //     getPolygon: function (geojson, lnglats) {
    //       // 计算面积
    //       var area = AMap.GeometryUtil.ringArea(lnglats[0]);

    //       return new AMap.Polygon({
    //         path: lnglats,
    //         fillOpacity: 1 - Math.sqrt(area / 8000000000), // 面积越大透明度越高
    //         strokeColor: "white",
    //         fillColor: "red",
    //       });
    //     },
    //   });

    //   geojson.setMap(map);
    // },
    callback() {
      //   this.key++;
      //   setTimeout(() => {
      //     console.log("mapint");
      //     this.mapInit();
      //   }, 1000);
    },
    // 2/3D切换
    changeModel(e) {
      this.model = e.target.value;
      this.key++;
      setTimeout(() => {
        this.mapInit();
      }, 300);
    },
    // done:查询所有区域
    // async getAreasList(){
    //   const res =
    // }
    // done:获取楼层数据
    async getFloor() {
      const res = await Api.getRoomsJson(parseInt(this.fId));
      this.areaName = res.data.areaName;
      this.buildingGroupName = res.data.buildingGroupName;
      this.floorName = res.data.floorName + "F";
      // this.floorKey = res.data.id;
      this.floorImage = res.data.buildingImage;
      this.floorWidth = res.data.buildingLength;
      this.floorHeight = res.data.buildingWidth;
      this.floorData = res.data.data;
    },
    // done:根据组别id查询所有楼层
    async getFloorList() {
      const res = await Api.getFloorList(parseInt(this.bId));
      // if (res.data.data[0].floorData.length == 1) {
      //   this.fId = res.data.data[0].floorData[0].id;
      // } else {
      //   this.fId = res.data.data[1].floorData[2].id;
      // }
      this.buildList = res.data.data;
      for (var i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].deleteFlag !== 1) {
          for (var j = 0; j < res.data.data[i].floorData.length; j++) {
            if (res.data.data[i].floorData[j].deleteFlag !== 1) {
              this.buildIndex = i;
              this.fId = res.data.data[i].floorData[0].id;
              this.floorList = res.data.data[i].floorData.reverse();
              break;
            }
            // this.fId = res.data.data[i].floorData.reverse()[1].id;
          }
        }
      }
    },
    // done:获取楼层底图和geojson
    async getRoomsJson() {
      // const res = await Api.getRoomsJson(this.fId);
      // console.log(res);
      // this.floorData = res.data;
    },
    // done:楼层改变
    async changeFloor(id) {
      this.level = "floor";
      this.transform = "left";
      this.fId = id;
      this.bId = id;
      this.floorKey = id;

      this.key++;
      await this.getFloor();
      await this.getRoomsJson();
      //   this.spinShow = true;
      this.companyShow = false;
      this.rId = 0;
      setTimeout(() => {
        this.transform = "right";
      }, 500);
      //   setTimeout(() => {
      //     this.spinShow = false;
      //   }, 500);
      setTimeout(() => {
        this.mapInit();
      }, 300);
    },
    handleBuildChange(e) {
      this.buildIndex = e.target.value;
      this.floorList = this.buildList[e.target.value].floorData;
    },
    backPark() {
      this.mapClass.splice(1, 1);
      this.mapClass.push("animate__zoomOut");
      setTimeout(() => {
        this.$router.push({
          path: "/resources/park",
          query: {
            parkId: this.$route.query.parkId,
          },
        });
      }, 400);
    },
    backArea() {
      this.mapClass.splice(1, 1);
      this.mapClass.push("animate__zoomOut");
      setTimeout(() => {
        this.$router.push({
          path: "/resources/area",
          query: {
            parkId: this.$route.query.parkId,
            aId: this.$route.query.aId,
          },
        });
      }, 400);
    },
    backBuilding() {
      //   this.$router.push({
      //     path: "/resources/building",
      //     query: {
      //       parkId: this.$route.query.parkId,
      //       aId: this.$route.query.aId,
      //       bId: this.$route.query.bId,
      //     },
      //   });
    },
    backPre() {
      setTimeout(() => {
        this.$router.push({
          path: "/resources/area",
          query: {
            parkId: this.$route.query.parkId,
            aId: this.$route.query.aId,
          },
        });
      }, 400);
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1600px) {
  .map-name {
    height: 44px !important;
    width: calc(100% - 50px);
    background-image: url("../../../assets/index/yinxingName.png");
    background-size: 100% 112%;
    position: absolute;
    z-index: 9998;
    left: 25px;
    // left: 50%;
    // transform: translateX(-50%);
    top: 16px;
  }
}
.resources {
  height: 100%;
  padding: 24px;
  .left-content {
    height: 100%;
    position: relative;
    background: white;
    // background-image: url("../../assets/index/yinxingBg.png");
    // background-size: 100% 100%;
    // background-position: center;
    .map-name {
      height: 60px;
      width: calc(100% - 50px);
      background-image: url("../../../assets/index/yinxingName.png");
      background-size: 100% 112%;
      position: absolute;
      z-index: 9998;
      left: 25px;
      top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 86px;
      .ol-control {
        display: none;
      }
    }
    .floor-change {
      position: absolute;
      width: 64px;
      z-index: 9998;
      //   transform: translateX(-50%);
      top: 130px;
      height: 60%;
      left: 25px;
      color: var(--blue-color);
      font-size: 20px !important;
      font-weight: 600;
    }
    .breadcrumb {
      font-size: 16px;
      color: var(--blue-color);
      span:last-child a {
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        font-size: 20px;
        letter-spacing: 0.12em;
        color: #055fcd;
      }
      span:last-child {
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        font-size: 20px;
        letter-spacing: 0.12em;
        color: #055fcd;
      }
    }
    .measure-popover {
      position: absolute;
      bottom: 20px;
    }
  }
}
.popover-box {
  padding: 12px;
  width: 432px;
  height: 380px;
  .box-title {
    height: 44px;
  }
}
.box-content {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .text {
    font-size: 16px;
    color: #151a30;
    line-height: 200%;
    margin-right: 20px;
  }
}
.spin {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  z-index: 9999;
  background: white;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: white !important;
  background: #1890ff !important;
  border-color: #1890ff;
}
</style>
<style lang="scss">
.breadcrumb {
  a {
    color: #055fcd !important;
  }
  span:last-child a {
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.12em;
    color: #055fcd !important;
  }
}
.ol-control {
  display: none;
}
.floor-change {
  .ant-tabs-nav .ant-tabs-tab {
    background: #f0f5ff;
    border-radius: 6px;
    width: 64px;
  }
  .ant-tabs-tab-prev-icon,
  .ant-tabs-tab-next-icon {
    line-height: 40px !important;
    width: 100% !important;
    background-color: #f0f5ff !important;
    /* margin-right: 20px !important; */
    color: var(--blue-color);
  }
  .ant-tabs-bar {
    border: none !important;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: white !important;
    background-color: #6395fa !important;
  }
}
.ant-tabs
  .ant-tabs-left-bar
  .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
  padding: 52px 0 !important;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  display: none !important;
}
</style>
