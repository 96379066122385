<template>
  <div class="data">
    <div class="nav">
      <!-- <span
        :class="index == 1 ? 'nav-item nav-focus' : 'nav-item'"
        @click="navTo(1)"
        >所有园区</span
      > -->
      <span
        :class="index == 1 ? 'nav-item nav-focus' : 'nav-item'"
        @click="navTo(1)"
        >银星科技园</span
      >
      <span
        :class="index == 2 ? 'nav-item nav-focus' : 'nav-item'"
        @click="navTo(2)"
        >银星坪山 生命健康科技园</span
      >
    </div>
    <div class="main animate__animated animate__zoomIn">
      <div class="top-row">
        <div class="left-card main-card">
          <div class="title-background-long title">房产类资源类型分布</div>
          <div class="left-card-content" id="pie"></div>
        </div>
        <div class="right-card main-card">
          <div class="title-background-longgest title">租赁资源（万m²）</div>
          <div class="right-card-content" id="bar"></div>
          <div class="showDataBox">
            <div class="monthData">{{ monthData }}</div>
            <!-- <a-icon type="rise" style="font-size:20px"/> -->

            <div
              style="display: flex; align-items: center; margin-left: 20px"
              v-if="proportion && proportion > 0"
            >
              <a-icon type="arrow-up" style="font-size: 16px; color: #149d52" />
              <span style="margin-left: 4px; font-size: 16px; color: #149d52">{{
                proportion + "%"
              }}</span>
              <span style="margin-left: 16px">同比增长</span>
            </div>
            <div
              style="display: flex; align-items: center; margin-left: 20px"
              v-if="proportion && proportion < 0"
            >
              <a-icon
                type="arrow-down"
                style="font-size: 16px; color: rgb(235 22 22)"
              />
              <span
                style="margin-left: 4px; font-size: 16px; color: rgb(235 22 22)"
                >{{ proportion + "%" }}</span
              >
              <span style="margin-left: 16px">同比下降</span>
            </div>
            <!-- <div>2.1% 同比增长</div> -->
          </div>
          <div class="selectBox">
            <a-date-picker 
              v-model:value="rentYear" 
              mode="year" 
              format="YYYY"
              placeholder="请选择年份"
              style="width: 120px; margin-right: 12px" 
              :open="yearPickShow" 
              @panelChange="panelChange"
              @openChange="yearPickShow = true"
            />
            <!--<a-select
              style="width: 120px; margin-right: 12px"
              @change="handleRentDataChange"
              v-model="rentYear"
            >
              <a-icon
                slot="suffixIcon"
                type="caret-down"
                style="color: #6395fa"
              />
              <a-select-option :value="2023"> 2023年 </a-select-option>
              <a-select-option :value="2022"> 2022年 </a-select-option>
              <a-select-option :value="2021"> 2021年 </a-select-option>
              <a-select-option :value="2020"> 2020年 </a-select-option>
              <a-select-option :value="2019"> 2019年 </a-select-option>
              <a-select-option :value="2018"> 2018年 </a-select-option>
              <a-select-option :value="2017"> 2017年 </a-select-option>
              <a-select-option :value="2016"> 2016年 </a-select-option>
            </a-select>-->
            <a-select
              default-value="lucy"
              style="width: 120px"
              @change="handleRentDataChange"
              class="select-style"
              v-model="rentType"
            >
              <a-icon
                slot="suffixIcon"
                type="caret-down"
                style="color: #6395fa"
              />
              <a-select-option :value="0"> 全部类型 </a-select-option>
              <a-select-option v-for="item in projectTypeOption" :value="item.id" :key="item.id">{{ item.name }}</a-select-option>
            </a-select>
          </div>
        </div>
      </div>
      <div class="bottom-row">
        <div class="left-card main-card">
          <div class="title-background-long title">招商进度</div>
          <div
            class="left-card-content animate__animated"
            :class="investmentClass"
          >
            <div class="selectBox">
              <div class="time">{{ (startDate || '00:00') + "—" + (endDate || '00:00') }}</div>
              <div>
                <a-button
                  size="small"
                  class="button-style pre-button"
                  @click="changeInvestmentTime(-1)"
                >
                  上一周
                </a-button>
                <a-button
                  size="small"
                  class="button-style next-button"
                  @click="changeInvestmentTime(1)"
                >
                  下一周
                </a-button>
              </div>
            </div>
            <div>
              <div class="investment-data scroll-style" v-if="weekInvestmentData != null && weekInvestmentData.length>0">
                <div class="data-row" >
                  <span class="row-name">厂房：</span>
                  <span class="row-data"
                  >本周出租{{
                      weekInvestmentData[0][0].weekNewRentWorkShopArea.toFixed(0)
                    }}
                  m²,退租
                  {{
                      weekInvestmentData[0][0].weekSurrenderWorkShopArea.toFixed(
                          0
                      )
                    }}
                  m²</span
                  >
                  <span class="row-detail"
                  >（总面积
                  {{
                      (weekInvestmentData[0][0].AllWorkShopArea / 10000).toFixed(
                          2
                      )
                    }}
                  万m²,已租
                  {{
                      (
                          weekInvestmentData[0][0].rentedWorkShopArea / 10000
                      ).toFixed(2)
                    }}
                  万m²,待租
                  {{
                      weekInvestmentData[0][0].waitRentedWorkShopArea.toFixed(2)
                    }}
                  m²,出租率
                  {{
                      (weekInvestmentData[0][0].workShopRentPer * 100).toFixed(1)
                    }}%）</span
                  >
                </div>
                <div class="data-row">
                  <span class="row-name">办公：</span>
                  <span class="row-data"
                  >本周出租{{
                      weekInvestmentData[1][0].weekNewRentOfficeArea.toFixed(0)
                    }}
                  m²,退租
                  {{
                      weekInvestmentData[1][0].weekSurrenderOfficeArea.toFixed(0)
                    }}
                  m²</span
                  >
                  <span class="row-detail"
                  >（总面积
                  {{
                      (weekInvestmentData[1][0].allOfficeArea / 10000).toFixed(2)
                    }}
                  万m²,已租
                  {{
                      (weekInvestmentData[1][0].rentedOfficeArea / 10000).toFixed(
                          2
                      )
                    }}
                  万m²,待租
                  {{ weekInvestmentData[1][0].waitRentedOfficeArea.toFixed(2) }}
                  m²,出租率
                  {{
                      (weekInvestmentData[1][0].officeRentPer * 100).toFixed(1)
                    }}%）</span
                  >
                </div>
                <div class="data-row">
                  <span class="row-name">商业：</span>
                  <span class="row-data"
                  >本周出租{{
                      weekInvestmentData[2][0].weekNewRentSaleArea.toFixed(0)
                    }}
                  m²,退租
                  {{
                      weekInvestmentData[2][0].weekSurrenderSaleArea.toFixed(0)
                    }}
                  m²</span
                  >
                  <span class="row-detail"
                  >（总面积
                  {{
                      (weekInvestmentData[2][0].allSaleArea / 10000).toFixed(2)
                    }}
                  万m²,已租
                  {{
                      (weekInvestmentData[2][0].rentedSaleArea / 10000).toFixed(2)
                    }}
                  万m²,待租
                  {{ weekInvestmentData[2][0].waitRentedSaleArea.toFixed(2) }}
                  m²,出租率
                  {{
                      (weekInvestmentData[2][0].saleRentPer * 100).toFixed(1)
                    }}%）</span
                  >
                </div>
                <div class="data-row">
                  <span class="row-name">公寓：</span>
                  <span class="row-data"
                  >本周出租{{
                      weekInvestmentData[3][0].weekNewRentFlatArea.toFixed(0)
                    }}
                  套,退租
                  {{
                      weekInvestmentData[3][0].weekSurrenderFlatArea.toFixed(0)
                    }}
                  套</span
                  >
                  <span class="row-detail"
                  >（总面积
                  {{ weekInvestmentData[3][0].allFlatArea.toFixed(0) }}
                  套,已租
                  {{ weekInvestmentData[3][0].rentedFlatArea.toFixed(0) }}
                  套,待租
                  {{ weekInvestmentData[3][0].waitRentedFlatArea.toFixed(0) }}
                  套,出租率
                  {{
                      (weekInvestmentData[3][0].flatRentPer * 100).toFixed(1)
                    }}%）</span
                  >
                </div>
                <div class="data-row">
                  <span class="row-name">宿舍：</span>
                  <span class="row-data"
                  >本周出租{{
                      weekInvestmentData[4][0].weekNewRentDormArea.toFixed(0)
                    }}
                  套,退租
                  {{
                      weekInvestmentData[4][0].weekSurrenderDormArea.toFixed(0)
                    }}
                  套</span
                  >
                  <span class="row-detail"
                  >（总面积
                  {{ weekInvestmentData[4][0].allDormArea.toFixed(0) }}
                  套,已租
                  {{ weekInvestmentData[4][0].rentedDormArea.toFixed(0) }}
                  套,待租
                  {{ weekInvestmentData[4][0].waitRentedDormArea.toFixed(0) }}
                  套,出租率
                  {{
                      (weekInvestmentData[4][0].dormRentPer * 100).toFixed(1)
                    }}%）</span
                  >
                </div>
              </div>
              <div class="investment-data" v-else style="font-size:20px">
                <a-empty></a-empty>
              </div>

            </div>

          </div>
        </div>
        <div class="right-card main-card">
          <div class="title-background-long title">企业融资情况</div>
          <div class="right-card-content scroll-style" style="margin-bottom:10px">
            <a-row class="table-header table-row">
              <a-col :span="12">公司名称</a-col>
              <a-col :span="12">融资详情</a-col>
            </a-row>
            <a-row
              class="table-row"
              v-for="(item, index) in financingData"
              :key="index"
              style="font-weight: 600"
            >
              <a-col :span="12">{{ item.name }}</a-col>
              <a-col :span="12">{{ item.value }}</a-col>
            </a-row>
          </div>
          <a-pagination v-model:current="pagination.page" :total="pagination.total" show-less-items @change="changePage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "../../api/api.js";
// import SMenu from "./menu.js";
import * as echarts from "echarts";
import moment from "moment";
var barEchart;
export default {
  // components: { SMenu },
  data() {
    return {
      index: 1,
      analyseData: {},
      weekInvestmentData: [],
      investmentTime: "02月14日 — 02月20日",
      investKey: 0,
      startDate: null,
      endDate: null,
      investmentClass: null,
      financingData: [],
      nowYearData: {},
      preYearData: {},
      leasedAreaRatio: {},
      nowYear: null,
      preYear: null,
      rentYear: moment().format('YYYY'),
      yearPickShow: false,//年选择器开关
      rentType: 0,
      monthData: "",
      proportion: undefined,
      projectTypeOption: [], //类型
      pagination:{
        page:1,
        size:10,
        total:0,
      }
    };
  },
  async created() {
    await this.getType(); //获取类型
    await this.getParkAnalyse();
    await this.getWeekAnalyse();
    await this.getFinancing();
    await this.getLeasedArea();
    setTimeout(() => {
      this.getAnalysePie();
      this.getMeasureBar();
    }, 400);
  },
  methods: {
    navTo(index) {
      if (index == 2) {
        this.$message.info("暂无其他园区数据！");
      } else {
        this.index = index;
      }
    },
    getType(){
      Api.getProjectTypeList().then((res) => {
        this.projectTypeOption = res.data;
      })
    },
    //根据园区ID查询资源类型分布
    async getParkAnalyse() {
      const res = await Api.getParkAnalyse(4);
      this.analyseData = res.data.data[0];
      var allArea = 0;
      this.analyseData.forEach((item) => {
        // allArea = allArea + item.value;
        item.value = (item.value / 10000).toFixed(2);
        allArea = allArea + Number(item.value);
      });
    },
    //根据园区ID查询招商进度
    async getWeekAnalyse() {
      const res = await Api.getWeekAnalyse(4 + "/" + this.investKey);
      this.weekInvestmentData = res.data.data || [];

      /*this.startDate = res.data.startDate;
      this.endDate = res.data.endDate;*/
      let year = moment().format("YYYY");
      let weekOfday = parseInt(moment().format("d"));
      weekOfday = weekOfday + -this.investKey * 7;
      if(res.data.startDate){
        this.startDate = year + "/"+ res.data.startDate.split(".")[0].toString().padStart(2,"0") + "/" + res.data.startDate.split(".")[1].toString().padStart(2,"0")
      }else{
        this.startDate =  moment().subtract(1,"weeks")
            .subtract(weekOfday + 1, "days")
            .format("YYYY/MM/DD") ;
      }

      if(res.data.endDate){
        this.endDate = year + "/"+ res.data.endDate.split(".")[0].toString().padStart(2,"0") + "/" + res.data.endDate.split(".")[1].toString().padStart(2,"0")
      }else{
        this.endDate =moment().subtract(1,"weeks")
            .add(5 - weekOfday, "days")
            .format("YYYY/MM/DD");
      }

    },
    //根据园区ID查询企业融资情况
    async getFinancing() {
      let params = {
        key:4,
        size:this.pagination.size,
        page:this.pagination.page
      }
      const res = await Api.getFinancing_pager(params);
      this.financingData = res.data.data;
      this.pagination.total = res.data.total;
    },
    //page 切换
    changePage(ev){
      this.pagination.page = ev;
      this.getFinancing();
    },
    //改变招商进度时间
    async changeInvestmentTime(num) {
      this.investKey = this.investKey + num;
      this.investmentClass = ["animate__fadeOutLeftBig"];
      await this.getWeekAnalyse();
      this.investmentClass = ["animate__fadeInLeftBig"];
    },
    //获取租赁详情
    async getLeasedArea() {
      const params = {
        year: this.rentYear,
        projectId: undefined, //4
        type: this.rentType == 0 ? undefined : this.rentType
      }
      const res = await Api.getLeasedArea_rentarea(params);
      let keys = [];
      for(let key in res.data) {
        keys.push(key);
      }
      this.nowYear = keys[1];
      this.preYear = keys[0];
      this.nowYearData = res.data[this.nowYear];
      this.preYearData = res.data[this.preYear];
      var arr = [];
      var ratio = 0;
      this.preYearData.forEach((item, index) => {
        item.name = (index+1) + '月'
        item.value = (item.buildingArea / 10000).toFixed(2);
      });
      this.nowYearData.forEach((item,index) => {
        item.name = (index+1) + '月'
        item.value = (item.buildingArea / 10000).toFixed(2);
        ratio = this.preYearData[index]  ? (
          (item.value - this.preYearData[index].value) / item.value *
          100
        ).toFixed(1) : 0;
        if (ratio == "Infinity") {
          ratio = 0;
        }
        arr.push({ name: item.name, value: ratio });
      });
      this.leasedAreaRatio = arr;
    },
    panelChange(ev){
      this.rentYear = moment(ev).format('YYYY');
      this.yearPickShow = false;
      this.handleRentDataChange();
    },
    //柱状图数据改变
    async handleRentDataChange() {
      await this.getLeasedArea();
      setTimeout(() => {
        this.monthData = undefined;
        this.proportion = undefined;
        this.getMeasureBar();
      }, 500);
    },
    //绘制饼图
    async getAnalysePie() {
      let designSize = 1920; // 设计图尺寸
      let html = document.documentElement;
      let wW = html.offsetWidth; // 窗口宽度
      let ratio = wW / designSize;
      const chart = document.getElementById("pie");
      const pieData = this.analyseData;
      var myChart = echarts.init(chart);
      var option = {
        legend: {
          // data: [
          //   { name: "北区厂房", icon: "circle" },
          //   { name: "智界厂房", icon: "circle" },
          //   { name: "写字楼", icon: "circle" },
          //   { name: "商铺", icon: "circle" },
          //   { name: "宿舍", icon: "circle" },
          //   { name: "公寓", icon: "circle" },
          // ],
          formatter: function (name) {
            let data = pieData;
            let target;
            for (var i = 0; i < data.length; i++) {
              if (data[i].name == name) {
                target = data[i].value;
              }
            }
            let arr = ["{a|" + name + "}", "{b|" + target + "万m²}"];
            return arr.join("");
          },
          textStyle: {
            rich: {
              a: {
                fontSize: 14,
                align: "left",
                padding: [3, 0, 0, 0],
                width: 90,
                color: "black",
              },
              b: {
                fontSize: 14,
                align: "right",
                padding: [3, 0, 0, 0],
                width: 70,
                color: "black",
              },
            },
          },
          orient: "vertical",
          top: 10 * ratio + "%",
          right: "10%",
          itemWidth: 20 * ratio,
          itemHeight: 20 * ratio,
          // borderRadius: [5, 5, 0, 0],
          padding: 5 * ratio,
          itemGap: 20 * ratio,
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["80%", "60%"],
            position: "left",
            center: ["25%", "53%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20 * ratio,
                fontWeight: "bold",
                color: "auto",
                lineHeight: 36,
                // color: "black",
                formatter: function (data) {
                  return (
                    data.name +
                    "\n" +
                    data.percent +
                    "%" +
                    "\n" +
                    data.value +
                    "万m²"
                  );
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: pieData,
          },
          {
            name: "外边框",
            type: "pie",
            clockWise: false,
            radius: ["90%", "90%"], //边框大小
            center: ["25%", "53%"], //边框位置
            labelLine: {
              show: false,
            },
            // avoidLabelOverlap: false,
            data: [
              {
                value: 0,
                itemStyle: {
                  normal: {
                    borderWidth: 1, //设置边框粗细
                    borderColor: "#B4CCFD", //边框颜色
                  },
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
    //绘制柱状图
    async getMeasureBar() {
      if (barEchart != null && barEchart != "" && barEchart != undefined) {
        barEchart.dispose(); //销毁
      }
      let designSize = 1920; // 设计图尺寸
      let html = document.documentElement;
      let wW = html.offsetWidth; // 窗口宽度
      let ratio = wW / designSize;
      const chart = document.getElementById("bar");
      barEchart = echarts.init(chart);
      barEchart.on("click", (params) => {
        if (params.seriesType == "bar") {
          this.monthData =
            params.seriesName + params.name + "  " + params.value;
          this.proportion = Number(
            this.leasedAreaRatio[params.dataIndex].value
          );
          console.log(params, this.proportion);
        }
      });
      var option = {
        // title: {
        //   text: "Rainfall vs Evaporation",
        //   subtext: "Fake Data",
        // },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          // data: [this.nowYear+'年', this.preYear+"年"],
          data: [
            { name: this.nowYear + "年", icon: "pin" },
            { name: this.preYear + "年", icon: "pin" },
            "增长率",
          ],
          bottom: -5 * ratio,
          // icon: "pin",
        },
        grid: {
          left: "8%",
          right: "8%",
          bottom: 8 + "%",
          containLabel: true,
        },
        toolbox: {
          show: true,
          feature: {
            // dataView: { show: true, readOnly: false },
            // magicType: { show: true, type: ["line", "bar"] },
            // restore: { show: true },
            // saveAsImage: { show: true },
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            // prettier-ignore
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisTick: {
              show: false,
            },
            // boundaryGap: ['30%', '30%']
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "面积",
            nameLocation: "start",
            nameTextStyle: {
              align: "right",
            },
          },
          {
            type: "value",
            name: "增长率",
            // min: 0,
            // max: 25,
            // interval: 5,
            nameLocation: "start",
            axisLabel: {
              formatter: "{value} %",
            },
            nameTextStyle: {
              align: "left",
            },
            splitLine: {
              show: false,
            },
          },
        ],

        series: [
          {
            name: this.nowYear + "年",
            type: "bar",
            color: "#6395FA",
            data: this.nowYearData,
            barGap: "40%",
            barWidth: "20%",
            // markPoint: {
            //   data: [
            //     { type: "max", name: "Max" },
            //     { type: "min", name: "Min" },
            //   ],
            // },
            // markLine: {
            //   data: [{ type: "average", name: "Avg" }],
            // },
          },
          {
            name: this.preYear + "年",
            type: "bar",
            color: "#C0C0C0",
            barWidth: "20%",
            data: this.preYearData,
            // markPoint: {
            //   data: [
            //     { name: "Max", value: 182.2, xAxis: 7, yAxis: 183 },
            //     { name: "Min", value: 2.3, xAxis: 11, yAxis: 3 },
            //   ],
            // },
            // markLine: {
            //   data: [{ type: "average", name: "Avg" }],
            // },
          },
          {
            name: "增长率",
            type: "line",
            data: this.leasedAreaRatio,
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + " %";
              },
            },
            // markPoint: {
            //   data: [
            //     { name: "Max", value: 182.2, xAxis: 7, yAxis: 183 },
            //     { name: "Min", value: 2.3, xAxis: 11, yAxis: 3 },
            //   ],
            // },
            // markLine: {
            //   data: [{ type: "average", name: "Avg" }],
            // },
          },
        ],
      };
      barEchart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.data {
  height: 100%;
  padding: 2.4rem;
  .nav {
    height: 48px;
    background: transparent;
    border-bottom: #d9d9d9 1px solid;
    display: flex;
    align-items: center;
    .nav-item {
      display: inline-block;
      // width: 80px;
      font-size: 16px;
      text-align: center;
      color: #191e42;
      cursor: pointer;
      height: 100%;
      line-height: 48px;
      margin-right: 2.4rem;
    }
    .nav-focus {
      color: #6395fa;
      opacity: 1 !important;
      font-size: 20px;
      font-weight: 700;
      // width: 100px !important;
      position: relative;
    }
    .nav-focus::after {
      content: "";
      height: 3px;
      width: 100%;
      position: absolute;
      background: #6395fa;
      bottom: -1px;
      left: 0;
    }
  }
  .main {
    height: calc(100% - 72px);
    margin-top: 2.4rem;
    .title {
      height: 4.8rem;
      display: flex;
      align-items: center;
      line-height: 4.8rem;
    }
    .top-row {
      display: flex;
      height: calc(50% - 12px);
      .left-card {
        flex: 2;
        margin-right: 2.4rem;
        display: flex;
        flex-direction: column;
        .left-card-content {
          height: 100%;
        }
      }
      .right-card {
        height: 100%;
        flex: 3;
        position: relative;
        // display: flex;
        // flex-direction: column;
        .right-card-content {
          height: calc(100% - 4.8rem);
        }
        .selectBox {
          position: absolute;
          top: calc(7.2rem + 10px);
          right: 2.4rem;
        }
        .showDataBox {
          position: absolute;
          top: calc(7.2rem + 10px);
          left: 2.4rem;
          display: flex;
          align-items: center;
          .monthData {
            font-weight: bold;
            font-size: 20px;
            letter-spacing: 0.5px;
            color: #151a30;
          }
        }
      }
    }
    .bottom-row {
      margin-top: 2.4rem;
      display: flex;
      height: calc(50% - 12px);
      .left-card {
        flex: 1;
        margin-right: 2.4rem;
        display: flex;
        flex-direction: column;
        .left-card-content {
          height: 100%;
          .selectBox {
            margin-top: 1.8rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .button-style {
              border-radius: 2px !important;
            }
            .pre-button {
              color: white;
              background-color: #6395fa;
              margin-right: 8px;
            }
            .next-button {
              background-color: #fbfcfe;
              color: #6395fa;
            }
            .time {
              font-weight: bold;
              font-size: 18px;
              letter-spacing: 0.5px;
              color: black;
            }
          }
          .investment-data {
            margin-top: 1.8rem;
            height: calc(100% - 27px - 3.6rem);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-y: scroll;
            .data-row {
              font-size: 1.8rem;
              font-weight: 700;
              color: #484e65;
              margin-bottom: 10px;
              .row-name {
              }
              .row-data {
                color: #ff6636;
              }
              .row-detail {
                color: #484e65;
                font-weight: 600;
              }
            }
          }
        }
      }
      .right-card {
        flex: 1;
        display: flex;
        flex-direction: column;
        .right-card-content {
          height: calc(100% - 43px);
          overflow: auto;
          margin-top: 1.8rem;
          .table-header {
            color: #777d97;
          }
          .table-row {
            padding: 1.2rem;
            line-height: 2.2rem;
            border-bottom: 1px solid #0167ff;
            font-size: 1.6rem;
            color: #0b2143;
          }
        }
      }
    }
    .main-card {
      padding: 2.4rem;
      background-color: white;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
<style lang="scss">
.selectBox {
  .ant-select-selection {
    border-radius: 2px !important;
    color: #6395fa;
    font-weight: 600;
    border: 0.5px solid #dde4f0;
  }
}
</style>
