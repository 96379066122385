import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import Index from '../views/index.vue'
import Home from '../views/home/index.vue'
import Data from '../views/data/index.vue'
import Resources from '../views/resources/index.vue'
// import Rent from '../views/resources/rent-detail.vue'
// import Measure from '../views/resources/measure-detail.vue'
// import Contract from '../views/resources/contract-detail.vue'
// import AllResources from '../views/resources/allResources.vue'
// import Company from '../views/resources/company-detail.vue'
// import Map from '../views/resources/3Dmap.vue'
import Park from '../views/resources/park/park.vue'
import Area from '../views/resources/area/area.vue'
import Building from '../views/resources/building/building.vue'
import Floor from '../views/resources/floor/floor.vue'
import CompanyList from '../views/resources/companyList.vue'
import Report from '../views/report.vue'
import EditReport from '../views/editReport.vue'


import webviewPark from '../views/webView/park.vue'
import webviewArea from '../views/webView/area.vue'
import webviewBuild from '../views/webView/building.vue'
import webviewFloor from '../views/webView/floor.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'index',
    component: Index,
    redirect: '/home',
    children: [{
        path: '/home',
        name: 'home',
        component: Home,
      },
      {
        path: '/data',
        name: 'data',
        component: Data,
      },
      {
        path: '/resources',
        name: 'resources',
        component: Resources,
        redirect: '/resources/park',
        children: [{
            path: '/resources/park',
            name: 'resources',
            component: Park,
          },
          {
            path: '/resources/area',
            component: Area,
          },
          {
            path: '/resources/building',
            component: Building,
          },
          {
            path: '/resources/floor',
            component: Floor,
          },
          {
            path: '/resources/companyList',
            component: CompanyList,
          },
          // {
          //   path: '/resources/measure',
          //   component: Measure,
          // },
          // {
          //   path: '/resources/contract',
          //   component: Contract,
          // },
          // {
          //   path: '/resources/allResources',
          //   component: AllResources,
          // },
          // {
          //   path: '/resources/company',
          //   component: Company,
          // },
          // {
          //   path: '/resources/map',
          //   component: Map,
          // }
        ]
      },
      {
        path: '/webView',
        name: 'webView',
        component: Resources,
        redirect: '/webView/park',
        children: [{
            path: '/webView/park',
            name: 'webView',
            component: webviewPark,
            meta: {
              title: "银星产业运营管理系统",
              show: true
            }
          },
          {
            path: '/webView/area',
            component: webviewArea,
            meta: {
              title: "银星产业运营管理系统",
              show: true
            }
          },
          {
            path: '/webView/build',
            component: webviewBuild,
            meta: {
              title: "银星产业运营管理系统",
              show: true
            }
          },
          {
            path: '/webView/floor',
            component: webviewFloor,
            meta: {
              title: "银星产业运营管理系统",
              show: true
            }
          }
        ]
      },
      {
        path: '/report',
        component: Report,
      },
    ]
  },
  {
    path: '/editReport',
    component: EditReport,
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router