<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <div id="nav">
        <router-link to="/login"></router-link>
      </div>
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>
<style>
html {
  max-width: 1920px;
  font-size: 62.5%;
}
body,
#app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
}
body {
  font-size: 1.4rem !important;
}
</style>
