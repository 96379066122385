<template>
  <div class="report">
    <div class="report-spin" v-if="spinShow">
      <a-spin tip="Loading..." size="large" />
    </div>
    <div class="report-box">
      <div class="report-title">
        <div>{{ "集团周工作汇报" }}</div>
        <!-- <div class="back pointer" @click="ExportSavePdf('PDF名称', '')">
          返回>>
        </div> -->
      </div>
      <div class="operation">
        <a-button class="btn-style" @click="save(false)">保存</a-button>  <!-- 保存页面数据到数据库表中 -->
        <a-button class="btn-style" @click="refresh">刷新</a-button>       <!--重新从我家云 获取报表数据-->
        <a-button class="btn-style" @click="getFile">更新报表</a-button>    <!--保存页面为图片 并将地址存入到数据库 -->
        <a-button class="btn-style" @click="downloadNew">下载报表</a-button>  <!-- 下载pdf或者 图 -->
      </div>
      <div id="report">
        <div class="investment-data">
          <div class="investment-title">
            上周招商进度({{ startDate + "-" + endDate }})
          </div>
          <div class="data-row">
            <span class="row-name">1,厂房：</span>
            <span class="row-data"
              >本周出租{{
                weekInvestmentData[0][0].weekNewRentWorkShopArea.toFixed(0)
              }}
              m²,退租
              {{
                weekInvestmentData[0][0].weekSurrenderWorkShopArea.toFixed(0)
              }}
              m²</span
            >
            <span class="row-detail"
              >（总面积
              {{
                (weekInvestmentData[0][0].AllWorkShopArea / 10000).toFixed(2)
              }}
              万m²,已租
              {{
                (weekInvestmentData[0][0].rentedWorkShopArea / 10000).toFixed(2)
              }}
              万m²,待租
              {{ weekInvestmentData[0][0].waitRentedWorkShopArea.toFixed(2) }}
              m²,出租率
              {{
                (weekInvestmentData[0][0].workShopRentPer * 100).toFixed(1)
              }}%）</span
            >
          </div>
          <div class="data-row">
            <span class="row-name">2,办公：</span>
            <span class="row-data"
              >本周出租{{
                weekInvestmentData[1][0].weekNewRentOfficeArea.toFixed(0)
              }}
              m²,退租
              {{ weekInvestmentData[1][0].weekSurrenderOfficeArea.toFixed(0) }}
              m²</span
            >
            <span class="row-detail"
              >（总面积
              {{ (weekInvestmentData[1][0].allOfficeArea / 10000).toFixed(2) }}
              万m²,已租
              {{
                (weekInvestmentData[1][0].rentedOfficeArea / 10000).toFixed(2)
              }}
              万m²,待租
              {{ weekInvestmentData[1][0].waitRentedOfficeArea.toFixed(2) }}
              m²,出租率
              {{
                (weekInvestmentData[1][0].officeRentPer * 100).toFixed(1)
              }}%）</span
            >
          </div>
          <div class="data-row">
            <span class="row-name">3,商业：</span>
            <span class="row-data"
              >本周出租{{
                weekInvestmentData[2][0].weekNewRentSaleArea.toFixed(0)
              }}
              m²,退租
              {{ weekInvestmentData[2][0].weekSurrenderSaleArea.toFixed(0) }}
              m²</span
            >
            <span class="row-detail"
              >（总面积
              {{ (weekInvestmentData[2][0].allSaleArea / 10000).toFixed(2) }}
              万m²,已租
              {{ (weekInvestmentData[2][0].rentedSaleArea / 10000).toFixed(2) }}
              万m²,待租
              {{ weekInvestmentData[2][0].waitRentedSaleArea.toFixed(2) }}
              m²,出租率
              {{
                (weekInvestmentData[2][0].saleRentPer * 100).toFixed(1)
              }}%）</span
            >
          </div>
          <div class="data-row">
            <span class="row-name">4,公寓：</span>
            <span class="row-data"
              >本周出租{{
                weekInvestmentData[3][0].weekNewRentFlatArea.toFixed(0)
              }}
              套,退租
              {{ weekInvestmentData[3][0].weekSurrenderFlatArea.toFixed(0) }}
              套</span
            >
            <span class="row-detail"
              >（总面积
              {{ weekInvestmentData[3][0].allFlatArea.toFixed(0) }}
              套,已租
              {{ weekInvestmentData[3][0].rentedFlatArea.toFixed(0) }}
              套²,待租
              {{ weekInvestmentData[3][0].waitRentedFlatArea.toFixed(0) }}
              套,出租率
              {{
                (weekInvestmentData[3][0].flatRentPer * 100).toFixed(1)
              }}%）</span
            >
          </div>
          <div class="data-row">
            <span class="row-name">5,宿舍：</span>
            <span class="row-data"
              >本周出租{{
                weekInvestmentData[4][0].weekNewRentDormArea.toFixed(0)
              }}
              套,退租
              {{ weekInvestmentData[4][0].weekSurrenderDormArea.toFixed(0) }}
              套</span
            >
            <span class="row-detail"
              >（总面积
              {{ weekInvestmentData[4][0].allDormArea.toFixed(0) }}
              套,已租
              {{ weekInvestmentData[4][0].rentedDormArea.toFixed(0) }}
              套,待租
              {{ weekInvestmentData[4][0].waitRentedDormArea.toFixed(0) }}
              套,出租率
              {{
                (weekInvestmentData[4][0].dormRentPer * 100).toFixed(1)
              }}%）</span
            >
          </div>
        </div>
        <Table ref="child"></Table>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "../api/api.js";
import html2canvas from "html2canvas";
import Table from "../components/table.vue";
import JSPDF from "jspdf";
export default {
  components: {
    Table,
  },
  data() {
    return {
      weekInvestmentData: {},
      investKey: 0,
      startDate: null,
      endDate: null,
      pdfsrc: "",
      spinShow: false,
    };
  },
  mounted() {
    let item = localStorage.getItem("role");
    let items = (item || "").split(",");
    let item1 = ["YJQX","EJQX"];
    for (let itemElement of items) {
      if (item1.includes(itemElement)){
        this.getWeekAnalyse();
      }
    }
  },
  methods: {
    //根据园区ID查询招商进度
    async getWeekAnalyse() {
      const res = await Api.getWeekAnalyse(4 + "/" + this.investKey);
      this.weekInvestmentData = res.data.data;
      this.startDate = res.data.startDate;
      this.endDate = res.data.endDate;
      //每次进入周报表页面时，自动保存一份图片到数据库
      //this.ExportSavePdf(0);

  /*    let weekOfday = parseInt(moment().format("d"));
      weekOfday = weekOfday + -this.investKey * 7;
      this.startDate = moment()
        .subtract(weekOfday - 1, "days")
        .format("M.DD"); // 周一日期

      this.endDate = moment()
        .add(7 - weekOfday, "days")
        .format("M.DD");*/ // 周日日期
      // console.log(this.startDate,this.endDate)
    },
    toPdf() {
      this.$PDFSave(this.$refs["report"], "我的文件");
    },
    ExportSaveImg() {
      var width = document.querySelector("#report").clientWidth;
      var height = document.querySelector("#report").clientHeight;
      // console.log(height, width);
      html2canvas(document.querySelector("#report"), {
        width: width,
        height: height,
        allowTaint: true,
        useCORS: true,
        dpi: 300,
      }).then((canvas) => {
        // document.querySelector("#box1").appendChild(canvas);
        // document.getElementById("test").src = canvas.toDataURL();
        var dataURL = canvas.toDataURL("image/png");
        // var imgFile = this.dataURLtoFile(dataURL);
        // console.log("imgFile====>", dataURL);
        Api.uploadFile({ base64: dataURL }).then((res) => {
          // console.log(res);
          if (res) {
          }
        });
        // const img = require('../assets/index/yellow.png')
        // console.log(img)
        // console.log(dataURL);
        // this.saveFile(dataURL, "test.jpg");
      });
    },
    saveFile(data, filename) {
      var save_link = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
      );
      save_link.href = data;
      save_link.download = filename;

      var event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      save_link.dispatchEvent(event);
    },

    ExportSavePdf(state) {
      var imgUrl;
      var pdfUrl;
      this.spinShow = true;
      const that = this;
      html2canvas(document.querySelector("#report"), {
        allowTaint: true,
        scale: 2,
      }).then(function (canvas) {
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let pdfX = ((contentWidth + 10) / 2) * 0.75;
        let pdfY = ((contentHeight + 200) / 2) * 0.75;
        var imgX = pdfX;
        var imgY = (contentHeight / 2) * 0.75;
        let imgWidth = 595.28;
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        let PDF = new JSPDF("", "pt", [pdfX, pdfY]);
        PDF.addImage(pageData, "JPEG", 0, 10, imgX, imgY);
        var datauri = PDF.output("dataurlstring");
        // console.log(datauri)
        //Api.uploadFile({ base64: pageData }).then((res) => {
        Api.uploadFilePrivate({ base64: pageData  }).then((res) => {
          // console.log(res);
          imgUrl = res.url;
          if (res) {
            Api.uploadFilePrivate({ base64: datauri }).then(async (result) => {
              // console.log(result);
              pdfUrl = result.url;
              if (result) {
               const save = await Api.saveFileToMinio({
                  linkParkId: 4,
                  pdfUrl: pdfUrl,
                  imageUrl: imgUrl,
                });
                if (save.success == "success") {
                  //下载报表前自动保存一份新的图片和pdf数据
                 // console.log(state==1)
                  that.spinShow = false;
                  if(state==1){
                   // alert("进来")
                    //console.log("进来");

                  //  Api.getFileUrl(4).then((res) => {
                      //Api.getFileUrlPrivate({filename: '/20230317/1679046647125.pdf'}).then((res) => {
                      Api.getFileUrlPrivate({filename: pdfUrl}).then((rres) => {
                       //alert("23333")
                        //console.log(rres)
                        const aLink = document.createElement('a');
                        const blob = new Blob([rres], { type: 'application/pdf'});
                        aLink.style.display = 'none';
                        aLink.href = URL.createObjectURL(blob);

                        aLink.setAttribute('download', '工作报表.pdf'); // 设置下载文件名称
                        document.body.appendChild(aLink);
                        aLink.click();
                        document.body.removeChild(aLink);
                        //fake_click(save_link);
                        //alert("4444444444")

                      //const PDF = res.data.pdf_url;
                  /*    let iframe = document.createElement('iframe');
                      iframe.style.display = 'none';
                      iframe.style.height = 0;
                      iframe.src = blob;
                      document.body.appendChild(iframe);
                      setTimeout(function () {
                        iframe.remove();
                      }, 10000);
*/
                      // PDF.save('工作报表'+'.pdf')
                      // window.open(PDF);
                      // fake_click(save_link);
                      // download("工作报表.pdf", PDF);
                    });


                  }else{
                    that.$message.success("更新成功！");
                  }

                }
              }
              // if (res) {
              //
              // }
            });
          }
        });
        // PDF.save("工作报表" + ".pdf");

        // PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        // if (leftHeight < pageHeight) {
        //   PDF.addImage(pageData, "JPEG", 0, 0, imgX, imgY);
        // } else {
        //   while (leftHeight > 0) {
        //     PDF.addImage(pageData, "JPEG", 0, position, imgX, imgY);
        //     leftHeight -= pageHeight;
        //     position -= 841.89;
        //     // if (leftHeight > 0) {
        //     //   PDF.addPage();
        //     // }
        //   }
        // }
        // console.log(PDF);
        // var datauri = PDF.output("dataurlstring");
        // Api.uploadFile({ base64: datauri }).then((res) => {
        //   console.log(res);
        //   if (res) {
        //     // Api.saveFileToMinio(4 + "/" + "" + "/" + res.url);
        //   }
        // });
        // //去掉前面的字符串后，就是文件的加密字符串
        // // var base64 = datauri.substring(28);
        // // // console.log(base64);
        // // var pdfheader = "data:application/pdf;base64,";
        // // var pdfUrl = pdfheader + base64;
        // // console.log(datauri);
      });
    },

    async save(type) {
      await this.$refs.child.save(type);
      
    },
    async refresh() {
      await this.$refs.child.refresh();
      
    },
    getFile() {
      this.ExportSavePdf(0);
    },
    downloadNew(){
      this.ExportSavePdf(1);
    },
    download() {

      this.save(true).then(() => {
        // this.ExportSavePdf(1);
        Api.getFileUrl(4).then((res) => {
          const PDF = res.data.pdf_url;
          let iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.style.height = 0;
          iframe.src = PDF;
          document.body.appendChild(iframe);
          setTimeout(function () {
            iframe.remove();
          }, 100000);
          
          // PDF.save('工作报表'+'.pdf')
          // window.open(PDF);
          // fake_click(save_link);
          // download("工作报表.pdf", PDF);
        });
      })
      
    },
    dataURLtoFile(dataurl, filename = "file") {
      // let arr = dataurl.split(",");
      // let mime = arr[0].match(/:(.*?);/)[1];
      // let suffix = mime.split("/")[1];
      // let bstr = atob(arr[1]);
      // let n = bstr.length;
      // let u8arr = new Uint8Array(n);
      // while (n--) {
      //   u8arr[n] = bstr.charCodeAt(n);
      // }
      // return new File([u8arr], `${filename}.${suffix}`, {
      //   type: mime,
      // });
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        undefined;
        u8arr[n] = bstr.charCodeAt(n);
      }
      var obj = new Blob([u8arr], { type: mime });
      var fd = new FormData();
      fd.append("file", obj, "image.png");
      // fd.append("sex", sex);
      // console.log(fd);
    },
  },
};
</script>
<style lang="scss" scoped>
.report {
  padding: 24px;
  height: 100%;
  overflow-y: auto;
  background-color: #f0f2f5;
  .report-spin {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(214, 212, 212, 0.4);
  }
  .report-box {
    padding: 2.4rem;
    background-color: white;
    .operation {
      .btn-style {
        margin-right: 12px;
        background-color: var(--blue-color);
        color: white;
      }
    }
    .report-title {
      background-image: url("../assets/report-title.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #151c51;
      background-color: rgba(99, 149, 250, 0.1);
      font-family: Microsoft YaHei UI;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      padding-left: 52px;
      padding-right: 24px;
      height: 48px;
      line-height: 48px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;
      .back {
        font-size: 16px;
        color: var(--blue-color);
        font-weight: normal;
      }
      .back:hover {
        text-decoration: underline;
      }
    }
    .investment-data {
      margin-top: 1.8rem;
      height: calc(100% - 27px - 3.6rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 3.6rem;
      .investment-title {
        font-size: 2rem;
        color: #151c51;
        font-weight: 800;
        font-family: "SimHei";
        margin-bottom: 1.4rem;
      }
      .data-row {
        font-size: 1.8rem;
        font-weight: 700;
        color: #151c51;
        margin: 4px;
        .row-name {
        }
        .row-data {
          color: #ff6636;
        }
        .row-detail {
          color: #484e65;
          font-weight: 600;
        }
      }
    }
  }
}
</style>