<template>
  <div class="company com-webview webviewResources">
    <div class="flex-column content-box">
      <div class="title flex-row active">
        <div class="tixing">{{ companyData.roomName ? companyData.roomName : '暂无' }}</div>
      </div>
      <div class="detail-box" @click="toCompanyDetail">
        <img src="../../assets/index/companyName.png" alt="" width="40" height="40">
        <div class="texts">所属公司：{{ companyData.companyName ? companyData.companyName : '暂无' }}</div>
      </div>
      <div class="data">
         <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="基本信息">
            <div style="min-height:180px;">
              <a-row>
                <a-col :span="12" class="flex-column" style="align-items: center">
                  <div class="num blue-content content-bg">
                    {{ companyData.allArea ? companyData.allArea.toFixed(1) : '0' }}
                  </div>
                  <div class="name">租赁面积(m²)</div>
                </a-col>
                <a-col :span="12" class="flex-column" style="align-items: center">
                  <div class="num green-content content-bg">
                    {{ companyData.countAvg ? companyData.countAvg.toFixed(0) : '0' }}
                  </div>
                  <div class="name">月租金(元/m²)</div>
                </a-col>
                <!-- <a-col :span="8" class="flex-column" style="align-items: center">
                  <div class="num yellow-content content-bg">
                    {{ companyData.increaseAvg ? (companyData.increaseAvg * 100).toFixed(1) : '0' }}%
                  </div>
                  <div class="name">递增幅度(年)</div>
                </a-col> -->
              </a-row>
            </div>
            <div style="min-height:210px;">
              <a-row>
                <a-col :span="12" style="padding-left: 20px">
                  <div
                    class="num"
                    style="text-align: center; color: var(--blue-color)"
                  >
                    {{ companyData.yearAllCount ? (companyData.yearAllCount / 10000).toFixed(2) : '0' }}
                  </div>
                  <div class="name" style="text-align: center">
                    本年度已收租金（万元）
                  </div>
                  <div class="blue-line" style="margin-top: 10px"></div>
                </a-col>
                <a-col :span="12" style="padding-left: 16px">
                  <div
                    class="num"
                    style="text-align: center; color: var(--green-color)"
                  >
                    {{ companyData.monthAllCount ? (companyData.monthAllCount / 10000).toFixed(2) : '0' }}
                  </div>
                  <div class="name" style="text-align: center">
                    本月度已收租金（万元）
                  </div>
                  <div class="green-line" style="margin-top: 10px"></div>
                </a-col>
              </a-row>
              <a-row style="margin-top: 20px">
                <a-col :span="12" style="padding-left: 16px">
                  <div
                    class="num"
                    style="text-align: center; color: var(--blue-color)"
                  >
                    {{ companyData.allBillsCount ? (companyData.allBillsCount / 10000).toFixed(2) : '0' }}
                  </div>
                  <div class="name" style="text-align: center">
                    累计收入租金（万元）
                  </div>
                  <div class="blue-line" style="margin-top: 10px"></div>
                </a-col>
                <a-col :span="12" style="padding-left: 16px">
                  <div
                    class="num"
                    style="text-align: center; color: var(--green-color)"
                  >
                    {{ companyData.allNotReceivedCount ? (companyData.allNotReceivedCount / 10000).toFixed(2) : '0' }}
                  </div>
                  <div class="name" style="text-align: center">拖欠租金（万元）</div>
                  <div class="green-line" style="margin-top: 10px"></div>
                </a-col>
              </a-row>
            </div>
            <div>
              <div class="enterprise-info">
                所属行业：{{ companyData.companyBeBigIntIndustry ? companyData.companyBeBigIntIndustry : '暂无' }}
              </div>
              <div class="enterprise-info">
                企业关键人：{{ companyData.companyKeyPerson ? companyData.companyKeyPerson : '暂无' }}
              </div>
              <div class="enterprise-info">
                企业联系人：{{ companyData.companyLinkPerson ? companyData.companyLinkPerson : '暂无' }}
              </div>
              <div class="enterprise-info">
                企业资质：{{ companyData.companyEnterpriseQualification ? companyData.companyEnterpriseQualification : '暂无'}}
              </div>
              <div class="enterprise-info">
                高层次人才数量：{{ companyData.companyHighLevelTalentCount ? companyData.companyHighLevelTalentCount : '暂无' }}
              </div>
              <div class="enterprise-info">
                企业融资情况：{{ companyData.companyFinancing ? companyData.companyFinancing : '暂无' }}
              </div>
              <div class="enterprise-info">
                <div style="width:90%;text-align: justify" :class="{ line_clamp_text:!isMore }">
                  企业简介：{{ companyData.companyIntroduction ? companyData.companyIntroduction : '暂无'}}
                </div>
                <span v-if="companyData.companyIntroduction"
                  @click="isMore = !isMore" class="more-btn">{{isMore ? '收起 >' : '展开 >'}}
                </span>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="合同信息">
            <div v-if="companyData">
              <div class="contract-box">
                <div>
                  <a-row class="row-title">
                    <a-col :span="8" style="text-align: center">开始时间</a-col>
                    <a-col :span="8" style="text-align: center">结束时间</a-col>
                    <a-col :span="8" style="text-align: center"></a-col>
                  </a-row>
                  <a-row
                    v-for="(item, index) in contract"
                    :key="index"
                    class="row-content"
                  >
                    <a-col :span="8" style="text-align: center">{{
                      item.startDate
                    }}</a-col>
                    <a-col :span="8" style="text-align: center">{{
                      item.endDate
                    }}</a-col>
                    <a-col
                      :span="8"
                      class="pointer"
                      style="text-align: center; color: var(--blue-color)"
                      @click="toContract(item.id)"
                      >详情</a-col
                    >
                  </a-row>
                </div>
                <div class="popover-bottom"></div>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
        <!-- <a-carousel :after-change="onChangeCarousel" >
          <div style="min-height:250px;">
            <a-row>
              <a-col :span="12" class="flex-column" style="align-items: center">
                <div class="num blue-content content-bg">
                  {{ companyData.allArea ? companyData.allArea.toFixed(1) : '0' }}
                </div>
                <div class="name">租赁面积(m²)</div>
              </a-col>
              <a-col :span="12" class="flex-column" style="align-items: center">
                <div class="num green-content content-bg">
                  {{ companyData.countAvg ? companyData.countAvg.toFixed(0) : '0' }}
                </div>
                <div class="name">租金(元/m²)</div>
              </a-col>
              <a-col :span="8" class="flex-column" style="align-items: center">
                <div class="num yellow-content content-bg">
                  {{ companyData.increaseAvg ? (companyData.increaseAvg * 100).toFixed(1) : '0' }}%
                </div>
                <div class="name">递增幅度(年)</div>
              </a-col>
            </a-row>
          </div>
          <div style="min-height:250px;">
             <a-row style="margin-top: 20px">
              <a-col :span="12" style="padding-left: 16px">
                <div
                  class="num"
                  style="text-align: start; color: var(--blue-color)"
                >
                  {{ companyData.yearAllCount ? (companyData.yearAllCount / 10000).toFixed(2) : '0' }}
                </div>
                <div class="name" style="text-align: start">
                  本年度已收租金（万元）
                </div>
                <div class="blue-line" style="margin-top: 10px"></div>
              </a-col>
              <a-col :span="12" style="padding-left: 16px">
                <div
                  class="num"
                  style="text-align: start; color: var(--green-color)"
                >
                  {{ companyData.monthAllCount ? (companyData.monthAllCount / 10000).toFixed(2) : '0' }}
                </div>
                <div class="name" style="text-align: start">
                  本月度已收租金（万元）
                </div>
                <div class="green-line" style="margin-top: 10px"></div>
              </a-col>
            </a-row>
            <a-row style="margin-top: 20px">
              <a-col :span="12" style="padding-left: 16px">
                <div
                  class="num"
                  style="text-align: start; color: var(--blue-color)"
                >
                  {{ companyData.allBillsCount ? (companyData.allBillsCount / 10000).toFixed(2) : '0' }}
                </div>
                <div class="name" style="text-align: start">
                  累计收入租金（万元）
                </div>
                <div class="blue-line" style="margin-top: 10px"></div>
              </a-col>
              <a-col :span="12" style="padding-left: 16px">
                <div
                  class="num"
                  style="text-align: start; color: var(--green-color)"
                >
                  {{ companyData.allNotReceivedCount ? (companyData.allNotReceivedCount / 10000).toFixed(2) : '0' }}
                </div>
                <div class="name" style="text-align: start">拖欠租金（万元）</div>
                <div class="green-line" style="margin-top: 10px"></div>
              </a-col>
            </a-row>
          </div>
          <div style="min-height:250px;overflow:scroll;">
            <a-row class="enterprise-status">
               <a-col :span="8" class="flex-row" style="justify-content: center">
                <img
                  src="../../assets/index/industry.png"
                  alt=""
                  width="40"
                  height="40"
                />
                <div style="margin-left: 8px">
                  <div class="yellow-text">
                    {{ companyData.companyBeBigIntIndustry }}
                  </div>
                  <div class="name" style="text-align: start">所属行业</div>
                </div>
              </a-col> 
              <a-col :span="8" class="flex-row" style="justify-content: center">
                <img
                  src="../../assets/index/entrytime.png"
                  alt=""
                  width="40"
                  height="40"
                />
                <div style="margin-left: 8px">
                  <div class="yellow-text" style="font-size: 12px">{{companyData.insideTime}}</div>
                  <div class="name" style="text-align: start">入驻时间</div>
                </div>
              </a-col>
              <a-col :span="8" class="flex-row" style="justify-content: center">
                <img
                  src="../../assets/index/state.png"
                  alt=""
                  width="40"
                  height="40"
                />
                <div style="margin-left: 8px" @click="toList">
                  <div class="yellow-text">续约中</div>
                  <div class="name" style="text-align: start">合同状态</div>
                </div>
              </a-col>
            </a-row>
            <div style="margin-top: 30px">
              <div class="enterprise-info">
                所属行业：{{ companyData.companyBeBigIntIndustry ? companyData.companyBeBigIntIndustry : '暂无' }}
              </div>
              <div class="enterprise-info">
                企业关键人：{{ companyData.companyKeyPerson ? companyData.companyKeyPerson : '暂无' }}
              </div>
              <div class="enterprise-info">
                企业联系人：{{ companyData.companyLinkPerson ? companyData.companyLinkPerson : '暂无' }}
              </div>
              <div class="enterprise-info">
                <div style="width:90%;text-align: justify" :class="{ line_clamp_text:!isMore }">
                  企业简介：{{ companyData.companyIntroduction ? companyData.companyIntroduction : '暂无'}}
                </div>
                <span v-if="companyData.companyIntroduction" 
                  @click="isMore = !isMore" class="more-btn">{{isMore ? '收起 >' : '展开 >'}}
                </span>
              </div>
            </div>
          </div>
        </a-carousel> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "../../api/api.js";
import moment from "moment";
import wx from 'weixin-js-sdk';
export default {
  props: {
    rId: Number,
  },
  data() {
    return {
      companyData: {},//公司信息
      contract:[],//合同信息
      isMore:false,
    };
  },
  watch: {
    async rId(e) {
      console.log(e);
      this.$nextTick(() => {
        this.companyData = {};
        this.contract = [];
        this.getCompany(e);
        this.getCompanyContract(e);
      })
    },
  },
  mounted() {
    if(this.rId){
      this.companyData = {};
      this.contract = [];
      this.getCompany(this.rId);
      this.getCompanyContract(this.rId);
    }
  },
  methods: {
    //去合同详情
    toContract(id){
      console.log(id,'id')
      wx.miniProgram.navigateTo(
        {
          // url: '/pages/contract/contract?roomId='+this.rId
          url: '/pages/contract/detail?id='+id + '&roomId=' + this.rId + '&type=1'
        }
      )
    },
    //去企业信息
    toCompanyDetail(){
       wx.miniProgram.navigateTo(
        {
          url: '/pages/overview/viewRoom/detail?id='+ id + '&type=1'
        }
      )
    },
    //轮播切换
    onChangeCarousel(ev){
      // console.log(ev)
    },
    getCompanyContract(id) {
      Api.getCompanyContract(id).then(res => {
        if(res){
          this.contract = res.data.data;
          this.contract.forEach((item, index) => {
            item.startDate = moment(item.startDate).format("YYYY/MM/DD");
            item.endDate = moment(item.endDate).format("YYYY/MM/DD");
          });
        }
      });
    },
    getCompany(id) {
      Api.getCompany(id).then(res => {
        if(res.data.data.length > 0){
          this.companyData = res.data.data[0];
          this.companyData.insideTime = this.companyData.insideTime ? moment(this.companyData.insideTime).format(
            "YYYY/MM/DD" ) : '';
          this.companyData.countAvg = this.companyData.countAvg !== 'NaN' ? this.companyData.countAvg : ''
        }
        this.companyData = res.data.data ? res.data.data[0] : {};
      });

      // console.log(this.companyData,'set')
    },
  },
};
</script>
<style lang="scss" scoped>
.company {
  height: 100%;
  padding:12px;
  .content-box {
    height: 100%;
    background:#ffffff;
  }
  .title {
    height: 40px;
    background-image: url("../../assets/index/nameBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #151c51;
    font-family: Microsoft YaHei UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    padding-left: 52px;
    line-height: 40px;
    justify-content: space-between;
    .tixing {
      height: 40px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 90%;
    }
  }
  .num {
    font-family: DIN;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
  }
  .name {
    font-size: 14px;
    color: #000;
    text-align: center;
  }
  .data {
    height: 100%;
    padding: 20px 0;
    .content-bg {
      height: 96px;
      width: 96px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      padding-top: 20px;
      margin: 16px 0;
    }
    .blue-content {
      background-image: url("../../assets/index/companyBlue.png");

      color: var(--blue-color);
    }
    .green-content {
      background-image: url("../../assets/index/companyGreen.png");
      color: var(--green-color);
    }
    .yellow-content {
      background-image: url("../../assets/index/companyYellow.png");
      color: var(--yellow-color);
    }
    .blue-line {
      height: 2px;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        #6395fa,
        rgba(0, 0, 0, 0) 100%
      );
      width: 80%;
      margin: auto;
    }
    .green-line {
      height: 2px;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        #79dfb7,
        rgba(0, 0, 0, 0) 100%
      );
      width: 80%;
      margin: auto;
    }
    .enterprise-status {
      .yellow-text {
        color: var(--yellow-color);
        font-weight: bold;
        font-size: 14px;
      }
    }
    .enterprise-info {
      min-height: 20px;
      background-image: url("../../assets/index/circle.png");
      background-size: 16px 16px;
      background-repeat: no-repeat;
      padding: 0 20px;
      background-position-y: top;
      font-size: 14px;
      color: #000;
      margin: 20px 0;
      position: relative;
      .more-btn {
        position: absolute;
        right:0;
        bottom:4px;
        font-size: 14px;
      }
      // background-position: ;
    }
    .line_clamp_text{
      width:90%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .detail-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    img {
      width:40px;
      height:40px;
      margin-right: 10px;
    }
    .texts {
      color: #151C51;
      font-weight: bold;
      font-size:14px;
    }
  }
  .contract-box {
    height: 200px;
    overflow: scroll;
    .row-title {
      color: #6395fa;
      // font-size: 14px !important;
      border-bottom: 1px solid #6395fa;
      line-height: 46px;
    }
    .row-content {
      color: black;
      // font-size: 14px !important;
      border-bottom: 1px solid rgba(1, 103, 255, 0.3);
      line-height: 46px;
    }
    font-size: 16px;
  }
  
}
</style>

