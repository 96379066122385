<template>
  <div class="companyList">
    <a-row>
      <a-col :span="18" class="left-content">
        <div :class="navClass">
          <a-breadcrumb class="breadcrumb">
            <span slot="separator" style="color: var(--blue-color)">></span>
            <a-breadcrumb-item href="" @click.native="backPark"
              >银星科技园</a-breadcrumb-item
            >
            <a-breadcrumb-item href="" @click.native="backArea">{{
              areaName
            }}</a-breadcrumb-item>
            <a-breadcrumb-item href="">{{
              buildingGroupName
            }}</a-breadcrumb-item>
            <!-- <a-breadcrumb-item href="">{{ floorName }}</a-breadcrumb-item> -->
            <!-- <a-breadcrumb-item @click.native="callback()">
              智界三期
            </a-breadcrumb-item> -->
          </a-breadcrumb>
          <div
            style="color: var(--blue-color); font-size: 16px"
            class="pointer"
            @click="backPre"
          >
            返回上一级
          </div>
        </div>
        <div class="list-table" :class="mapClass">
          <a-spin
            tip="Loading..."
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index:3
            "
            size="large"
            v-show="tableSpin"
          >
          </a-spin>
          <a-table
            :columns="columns"
            :data-source="companyList"
            :pagination="false"
            :scroll="{ y: true, x: true }"
            class="auto-scroll-y"
          />
        </div>
      </a-col>
      <a-col
        :span="6"
        style="
          height: 100%;
          padding-left: 24px;
          position: relative;
          right: -600px;
        "
        :class="transform"
      >
        <Allresources
          v-if="!companyShow"
          :id="bId"
          :level="'building'"
        ></Allresources>
        <Company v-show="companyShow" :rId="rId" :showRoom="showRoom"></Company>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Allresources from "../../components/allResources.vue";
import Company from "../../components/company-detail.vue";
import { Api } from "../../api/api.js";

export default {
  components: {
    Allresources,
    Company,
  },
  data() {
    return {
      areaName: "",
      mapClass: ["animate__animated", "animate__delay-0.5s", "animate__zoomIn"],
      navClass: ["map-name", "animate__animated", "animate__backInDown"],
      columns: [],
      transform: null,
      companyShow: false,
      rId: null,
      showRoom: true,
      bId: null,
      buildingGroupName: "",
      companyList: [],
      tableSpin:false
    };
  },
  mounted() {
    let vm = this;
    vm.columns = [
      {
        title: "公司名称",
        dataIndex: "companyName",
        width: 300,
        className: "table-company",
        customCell: this.selectCompany,
      },
      {
        title: "房间名",
        dataIndex: "roomName",
        width: 200,
      },
      {
        title: "租赁面积（m²）",
        dataIndex: "roomArea",
        width: 200,
        customRender: (text, record, rowIndex) => {
          return text
            .toFixed(2)
            .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
        },
      },
      {
        title: "月租金（元/m²）",
        dataIndex: "price",
        width: 200,
        customRender: (text, record, rowIndex) => {
          return (text).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
        },
      },
      {
        title: "本年度已收租金（万元）",
        dataIndex: "yearAmount",
        width: 200,
        customRender: (text, record, rowIndex) => {
          return (text/10000)
            .toFixed(2)
            .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
        },
      },
      {
        title: "本月度已收租金（万元）",
        dataIndex: "monthAmount",
        width: 200,
        customRender: (text, record, rowIndex) => {
          return (text/10000)
            .toFixed(2)
            .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
        },
      },
      {
        title: "累计收入租金（万元）",
        dataIndex: "allAmount",
        width: 200,
        customRender: (text, record, rowIndex) => {
          return (text/10000)
            .toFixed(2)
            .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
        },
      },
      {
        title: "拖欠租金（万元）",
        dataIndex: "notReceivedAmount",
        width: 200,
        customRender: (text, record, rowIndex) => {
          return (text/10000)
            .toFixed(2)
            .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
        },
      },
    ];
    vm.bId = parseInt(this.$route.query.bId);
    vm.getBuilding();
    vm.getBuildingCompany();
    setTimeout(() => {
      this.transform = "right";
    }, 500);
  },
  methods: {
    backPre() {
      setTimeout(() => {
        this.$router.push({
          path: "/resources/area",
          query: {
            parkId: this.$route.query.parkId,
            aId: this.$route.query.aId,
          },
        });
      }, 400);
    },
    backPark() {
      this.mapClass.splice(1, 1);
      this.mapClass.push("animate__zoomOut");
      setTimeout(() => {
        this.$router.push({
          path: "/resources/park",
          query: {
            parkId: this.$route.query.parkId,
          },
        });
      }, 400);
    },
    backArea() {
      this.mapClass.splice(1, 1);
      this.mapClass.push("animate__zoomOut");
      setTimeout(() => {
        this.$router.push({
          path: "/resources/area",
          query: {
            parkId: this.$route.query.parkId,
            aId: this.$route.query.aId,
          },
        });
      }, 400);
    },
    // done:获取楼栋数据
    async getBuilding() {
      const res = await Api.getBuilding(parseInt(this.bId));
      this.areaName = res.data.areaName;
      this.buildingGroupName = res.data.buildingGroupName;
      this.buildImage = res.data.buildingGroupImage;
      this.buildJson = res.data.buildingGroupData;
    },
    async getBuildingCompany() {
      this.tableSpin = true
      const res = await Api.getBuildingCompany(this.bId);
      this.companyList = res.data.data;
      this.companyList.forEach((item,index) => {
        item.key = index+1;
      })
      this.tableSpin = false
    },
    selectCompany(record) {
      const that = this;
      return {
        on: {
          click: () => {
            // that.spinShow = true;
            that.transform = "left";
            this.$nextTick(() => {
              that.rId = record.roomId;
              that.companyShow = true;
            });
            setTimeout(() => {
              that.transform = "right";
            }, 400);
          },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.companyList {
  height: 100%;
  padding: 24px;
  .ant-row {
    height: 100%;
    .ant-col {
      height: 100%;
    }
  }
  .left-content {
    position: relative;
    background: white;
    .map-name {
      height: 60px;
      width: calc(100% - 50px);
      background-image: url("../../assets/index/yinxingName.png");
      background-size: 100% 112%;
      position: absolute;
      z-index: 9998;
      left: 25px;
      top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 86px;
      .ol-control {
        display: none;
      }
    }
    .breadcrumb {
      font-size: 16px;
      color: var(--blue-color);
    }
    .list-table {
      height: calc(100% - 108px);
      width: calc(100% - 50px);
      // border: 1px solid black;
      position: absolute;
      bottom: 16px;
      left: 25px;
    }
  }
  .right-content {
    padding-left: 24px;
  }
}

.ant-table-body {
  height: calc(100% - 27px);
}
</style>
<style lang="scss">
.table-company {
  color: var(--blue-color) !important;
  cursor: pointer;
}
.table-company:hover {
  text-decoration: underline;
}
.auto-scroll-y {
  height: 100%;
  // overflow-y: auto;
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-scroll,
  .ant-table-body-inner,
  .ant-table-fixed-left,
  .ant-table-content {
    height: 100%;
  }
  .ant-table-content {
    position: relative;
  }
  .ant-table-body,
  .ant-table-body-inner {
    // overflow-y: auto !important;
  }
  .ant-table-header {
    overflow-y: hidden !important;
    background: white !important;
  }
  .ant-table-thead > tr > th {
    background: white !important;
    color: #777d97 !important;
  }
  .ant-table-body {
    height: calc(100% - 55px);
    font-size: 15px;
    overflow: auto !important;
  }
  .ant-table-tbody > tr > td {
    color: black;
  }
  .ant-table-body::-webkit-scrollbar {
    width: 4px;
    position: relative;
    left: 10px;
  }
  .ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  .ant-table-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
  .ant-table-body-outer {
    height: calc(100% - 27px);
  }
  .ant-table-body-inner {
    overflow-x: auto;
  }
  .ant-table-placeholder {
    display: none;
  }
}
</style>