<template>
  <div class="home">
    <a-row style="height: 100%" type="flex">
      <!-- tag:左侧数据卡片 -->
      <a-col :span="6" class="left-content pointer" @click="showReport" >
        <div
          style="background: var(--blue-color-alpha); margin-top: 0 !important"
          class="card"
        >
          <div class="flex-row">
            <img src="../../assets/index/blue.png" class="img" />
            <span style="margin-left: 8px; cursor: default">
              <div style="color: var(--blue-color)" class="num-text">02</div>
              <div style="color: black">总项目数量</div>
            </span>
          </div>
        </div>
        <div class="card" style="background: var(--green-color-alpha)">
          <div class="flex-row">
            <img src="../../assets/index/green.png" class="img" />
            <span style="margin-left: 8px; cursor: default">
              <div style="color: var(--green-color)" class="num-text">
                {{ allArea ? allArea.toFixed(2) : 0 }}
              </div>
              <div style="color: black">资源面积（万m²）</div>
            </span>
          </div>
        </div>
        <div class="card" style="background: var(--yellow-color-alpha)" v-if="check1()">
          <div class="flex-row">
            <img src="../../assets/index/yellow.png" class="img" />
            <span style="margin-left: 8px; cursor: default">
              <div style="color: var(--yellow-color)" class="num-text">
                {{ yearAmount ? yearAmount.toFixed(2) : 0 }}
              </div>
              <div style="color: black">本年度已收租金（万元）</div>
            </span>
          </div>
        </div>
        <div class="card" style="background: var(--red-color-alpha)" v-if="check1()">
          <div class="flex-row">
            <img src="../../assets/index/red.png" class="img" />
            <span style="margin-left: 8px; cursor: default">
              <div style="color: var(--red-color)" class="num-text">
                {{ monthAmount ? monthAmount.toFixed(2) : 0 }}
              </div>
              <div style="color: black">本月度已收租金（万元）</div>
            </span>
          </div>
        </div>
      </a-col>
      <a-col
        :span="9"
        class="right-content"
        style="position: relative; left: 1600px"
        :class="leftTransform"
      >
        <a-popover v-model="parkVisible" trigger="click" placement="rightTop">
          <template slot="content">
            <div
              class="popover-box pointer"
              style="cursor: default"
              @click="enterPark()"
            >
              <div
                class="box-title title-background flex-row pointer"
                style="justify-content: space-between"
              >
                银星科技园
              </div>
              <div class="box-content pointer">
                <div class="text">
                  资源面积（万m²）：{{
                    (parseInt(parkData.area) / 10000).toFixed(2)
                  }}
                </div>
                <div class="text">
                  可出租面积（万m²）：{{
                    (parseInt(parkData.leasableArea) / 10000).toFixed(2)
                  }}
                </div>
                <div class="text">
                  已出租面积（万m²）：{{
                    (parseInt(parkData.rentedArea) / 10000).toFixed(2)
                  }}
                </div>
                <div class="text">
                  空置面积（万m²）：{{
                    (parseInt(parkData.availableArea) / 10000).toFixed(2)
                  }}
                </div>
                <div class="text">
                  自用面积（万m²）：{{
                    (parseInt(parkData.selfArea) / 10000).toFixed(2)
                  }}
                </div>
                <div class="text">
                  出租率（%）：{{
                    (parkData.rentedPercentage * 100).toFixed(2)
                  }}%
                </div>
                <div class="text">
                  空置率（%）：{{
                    (parkData.availablePercentage * 100).toFixed(2)
                  }}%
                </div>
                <div class="text">
                  本年度退租面积（万m²）：{{
                    (
                      parseInt(parkData.yearSurrenderTenancyArea) / 10000
                    ).toFixed(2)
                  }}
                </div>
                <div class="text">
                  本月度退租面积（万m²）：{{
                    (
                      parseInt(parkData.monthSurrenderTenancyArea) / 10000
                    ).toFixed(2)
                  }}
                </div>
                <div class="text">
                  本年度已收租金（万元）：{{
                    (parseInt(parkData.yearAmount) / 10000).toFixed(2)
                  }}
                </div>
                <div class="text">
                  本月度已收租金（万元）：{{
                    (parseInt(parkData.monthAmount) / 10000).toFixed(2)
                  }}
                </div>
              </div>
            </div>
          </template>
          <div class="park-popover"></div>
        </a-popover>
        <div
          class="card flex-column"
          style="margin-bottom: 24px"
          @click="enterPark()"
        >
          <div class="card-bg bg-yinxing"></div>
          <div class="title">银星科技园</div>
        </div>
      </a-col>
      <a-col
        :span="9"
        class="right-content flex-column"
        style="position: relative; left: 1600px"
        :class="rightTransform"
      >
        <div class="card flex-column">
          <div class="card-bg bg-pingshan"></div>
          <div class="title">银星坪山 生命健康科技园</div>
        </div>
      </a-col>
      <!-- <a-col :span="18" class="right-content"> -->

      <!-- <a-carousel :after-change="onChange">
          <div class="carousel-card bg-yinxing flex-column" @click="showData(4)">
            <div class="park-name">银星科技园</div>
          </div>
          <div class="carousel-card bg-pingshan flex-column">
            <div class="park-name">银星坪山 生命健康科技园</div>
          </div>
        </a-carousel> -->
      <!-- </a-col> -->
    </a-row>
  </div>
</template>

<script>
import { Api } from "../../api/api.js";
export default {
  data() {
    return {
      allArea: undefined, //资源面积
      monthAmount: undefined, //本月度已收租金
      yearAmount: undefined, //本年度已收租金
      parkVisible: false,
      parkData: [], //园区数据
      leftTransform: null,
      rightTransform: null,
    };
  },
  mounted() {
    this.getParksList();
    setTimeout(() => {
      this.leftTransform = "left-transform";
    }, 500);
    setTimeout(() => {
      this.rightTransform = "left-transform";
    }, 1300);
  },
  methods: {
    async showData(index) {
      const res = await Api.getPark(index);
      this.parkData = res.data;
      this.parkVisible = true;
    },
    check1() {
      return ((localStorage.getItem('role') || "").includes('YJQX') || (localStorage.getItem('role') || "").includes('EJQX'));
    },
    enterPark() {
      this.$router.push({ path: "/resources", query: { parkId: 4 } });
    },
    getParksList: async function () {
      const res = await Api.getParksList();
      this.allArea = parseInt(res.data.allArea) / 10000;
      let item = localStorage.getItem("role");
      let items = (item || "").split(",");
      let item1 = ["YJQX","EJQX"];
      for (let itemElement of items) {
        if (item1.includes(itemElement)){
          this.monthAmount = parseInt(res.data.monthAmount) / 10000;
          this.yearAmount = parseInt(res.data.yearAmount) / 10000;
          return;
        }
      }
    },
    async showReport(){
      let item = localStorage.getItem("role");
      let items = (item || "").split(",");
      let item1 = ["YJQX","EJQX"];
      for (let itemElement of items) {
        if (item1.includes(itemElement)){
          this.$router.push("/editReport")
        }
      }

    }
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1600px) {
  .home {
    height: 100%;
    padding: 24px;
    .left-content {
      height: 100%;
      background: white;
      display: flex;
      flex-direction: column;
      // padding: 16px !important;
      justify-content: space-around;
      .img {
        // height: 64px !important;
        // width: 64px !important;
      }
      .card {
        height: 100%;
        margin-top: 24px !important;
        display: flex;
        align-items: center;
        // padding-left: 24px !important;
        .num-text {
          // font-size: 32px !important;
          font-weight: 700;
          // line-height: 40px !important;
        }
      }
    }
    .right-content {
      height: 100%;
      padding-left: 24px;
      position: relative;
      .park-popover {
        position: absolute;
        top: 80% !important;
        right: 0;
      }
      .card {
        height: 100%;
        background: white;
        // padding: 16px !important;
        border-radius: 4px;
        .title {
          font-weight: bold;
          // font-size: 24px !important;
          text-align: center;
          color: black;
          font-family: Microsoft YaHei UI;
          letter-spacing: 4px;
          // height: 36px !important;
          // line-height: 50px !important;
        }
        .card-bg {
          height: 100%;
          background-position: center;
          background-size: 100% 100%;
        }
        .bg-yinxing {
          background-image: url("../../assets/index/yinxing.png");
        }
        .bg-pingshan {
          background-image: url("../../assets/index/pingshan.png");
        }
        .bg-guangmin {
          background-image: url("../../assets/index/guangmin.png");
        }
        .bg-huangjiang {
          background-image: url("../../assets/index/huangjiang.png");
        }
      }
      .card:hover {
        box-shadow: 0px 2px 6px #c8c8c8;
        cursor: pointer;
      }
    }
  }
}
.ant-carousel {
  height: 100% !important;
}
.slick-initialized {
  text-align: center;
  height: 100%;
  line-height: 100%;
  background: #364d79;
  overflow: hidden;
}
.home {
  height: 100%;
  padding: 24px;
  .left-content {
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    padding: 24px;
    justify-content: space-around;
    .img {
      height: 9.4rem;
      width: 9.4rem;
    }
    .card {
      height: 100%;
      margin-top: 24px !important;
      display: flex;
      align-items: center;
      padding-left: 4.8rem;
      .num-text {
        font-size: 4.8rem;
        font-weight: 700;
        line-height: 6rem;
      }
    }
  }
  .right-content {
    height: 100%;
    padding-left: 24px;
    position: relative;
    .park-popover {
      position: absolute;
      top: 44%;
      right: 50%;
      transform: translate(-50%, -50%);
    }
    .card {
      height: 100%;
      background: white;
      padding: 2.4rem;
      border-radius: 4px;
      .title {
        font-weight: bold;
        font-size: 2.8rem;
        text-align: center;
        color: black;
        font-family: Microsoft YaHei UI;
        letter-spacing: 4px;
        height: 4.8rem;
        line-height: 6.6rem;
      }
      .card-bg {
        height: 100%;
        background-position: center;
        background-size: 100% 100%;
      }
      .bg-guangmin {
        background-image: url("../../assets/index/guangmin.png");
      }
      .bg-huangjiang {
        background-image: url("../../assets/index/huangjiang.png");
      }
    }
    .card:hover {
      box-shadow: 0px 2px 6px #c8c8c8;
      cursor: pointer;
    }
  }
}
.park-name {
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  color: black;
  font-family: Microsoft YaHei UI;
  letter-spacing: 4px;
  height: 66px;
  line-height: 66px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
}
.bg-yinxing {
  background-image: url("../../assets/index/yinxing.png");
}
.bg-pingshan {
  background-image: url("../../assets/index/pingshan.png");
}
.carousel-card {
  height: 100%;
  background-color: white;
  // background-image: url("../../assets/index/yinxing.png");
  background-size: calc(100% - 80px) calc(100% - 80px);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 22%;
  position: relative;
}
.popover-box {
  padding: 12px;
  width: 432px;
  height: 380px;
  .box-title {
    height: 44px;
  }
}
.box-content {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .text {
    font-size: 16px;
    color: #151a30;
    line-height: 200%;
    margin-right: 20px;
  }
}
.left-transform {
  transition: transform 0.6s;
  transition-timing-function: ease-out;
  transform: translate(-1600px);
}
</style>
<style lang="scss">
.ant-carousel .slick-slider {
  height: 100%;
}
.slick-list,
.slick-track {
  height: 100%;
}
.slick-track div {
  // height: 100%;
}
.ant-carousel .slick-dots li.slick-active button {
  width: 10px !important;
  background: #6395fa !important;
}
.ant-carousel .slick-dots li button {
  width: 10px !important;
  height: 10px !important;
}
.ant-carousel .slick-dots {
  position: absolute;
  bottom: 0px;
}
</style>
