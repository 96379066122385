import {
    get,
    post,
    postJson,
    getJson,
    cancelGet
} from '@/api/request.js'
import {getArray} from "./request";

export const Api = {
    //登录
    login: (params) => {
        return postJson(`/system/users/login`, params)
    },

    //获取所有园区
    getParksList: () => {
        return get(`/system/parks`)
    },
    //获取园区数据
    getPark: (params) => {
        return get(`/system/parks/${params}`)
    },
    //获取园区底图数据
    getParkJson: (params) => {
        return get(`/system/parks/pic/${params}`)
    },
    //根据园区ID查询所有未回款详情
    getNotReceived: (params) => {
        return get(`system/parks/notReceived/${params}`)
    },
    //根据园区ID查询近一个月到期合同详情
    getOneExpired: (params) => {
        return get(`system/parks/oneExpired/${params}`)
    },
    //根据园区ID查询近三个月到期合同详情
    getThreeExpired: (params) => {
        return get(`system/parks/threeExpired/${params}`)
    },
    //根据园区ID查询所有区域
    getAreasList: (params) => {
        return get(`system/areas/get/${params}`)
    },
    //根据园区ID查询所有子菜单
    getParkTree: (params) => {
        return get(`system/parks/tree/${params}`)
    },
    //根据园区ID查询所有区域数据
    getAllAreaData: (params) => {
        return get(`system/parks/getAllAreaData/${params}`)
    },
    //根据园区ID查询所有区域金额
    getAllAreaAmount: (params) => {
        return get(`system/parks/getAllAreaAmount/${params}`)
    },
    //根据园区ID查询不同类型资源面积
    getParkGroupSpace: (params) => {
        return get(`system/parks/getGroupSpace/${params}`)
    },
    //根据园区ID查询资源类型分布
    getParkAnalyse: (params) => {
        return get(`system/parks/analyse/${params}`)
    },
    //根据园区ID查询招商进度
    getWeekAnalyse: (params) => {
        return get(`system/parks/weekAnalyse/${params}`)
    },
    //根据园区ID查询企业融资情况
    getFinancing: (params) => {
        return get(`system/parks/financing/${params}`)
    },
    //根据园区ID查询企业融资情况
    getFinancing_pager: (params) => {
        return postJson(`system/parks/financing`, params)
    },
    //根据园区ID查询租赁详情
    getLeasedArea: (params) => {
        return get(`system/parks/leasedArea/${params}`)
    },
    //根据园区ID查询租赁详情
    getLeasedArea_rentarea: (params) => {
        return get(`system/dataStai/barchart/rentarea`, params)
    },
    //根据园区ID查询各项目统计表
    getProjectStatistics(params) {
        return get(`system/project-statistics/get/${params}`)
    },
    //查询进度表数据
    getInvestmentProgresses(params) {
        return get(`system/investment-progresses/get/${params}`)
    },
    //保存项目统计表
    updateProjectStatistics(params) {
        return postJson(`system/project-statistics/update/`, params)
    },
    //保存进度表
    updateInvestment(params) {
        return postJson(`system/investment-progresses/update/`, params)
    },
    //刷新进度表
    refreshInvestment(params) {
        return postJson(`system/investment-progresses/${params}`)
    },
    //刷新项目统计表
    refreshRroject(params) {
        return postJson(`system/project-statistics/${params}`)
    },
    //上传文件
    uploadFile(params) {
        return postJson(`system/uploadFile`, params)
    },
    //上传私密文件
    uploadFilePrivate(params) {
        return postJson(`system/private/uploadFile`, params)
    },
    //获取文件地址
    getFileUrl(params) {
        return get(`system/report-documents/get/${params}`)
    },
    //根据文件url获取文件流
    getFileUrlPrivate(params) {
        return getArray(`system/downloadFile/private`,params)
    },
    //保存文件到minio
    saveFileToMinio(params) {
        return postJson(`system/report-documents`, params)
    },
    //根据园区ID查询各区域ID
    getAreaId(params) {
        return get(`system/parks/areaId/${params}`)
    },
    //根据园区ID查询月已收
    getAllAreaMonthAmount(params) {
        return get(`system/parks/getAllAreaMonthAmount/${params}`)
    },
    //根据区域ID查询未回款
    getAllAreaNotReceived(params) {
        return get(`system/parks/getAllAreaNotReceived/${params}`)
    },

    //根据园区ID查询各月各类型实收情况
    acAreaAmount([
        parkId,
        monthTime,
        type
    ]) {
        return get(`system/parks/acAreaAmount/${parkId}/${monthTime}/${type}`)
    },
    //根据园区ID查询月已收总额
    getParkAllMonthAmount(params) {
        return get(`system/parks/monthAmount/${params}`)
    },
    //根据园区ID查询未回款总额
    getParkNotReceivedAmount(params) {
        return get(`system/parks/notReceivedAmount/${params}`)
    },



    //根据区域ID查询数据
    getAreaData: (params) => {
        return get(`system/areas/${params}`)
    },
    //根据区域ID查询JSON
    getAreaJson: (params) => {
        return get(`system/areas/pic/${params}`)
    },
    //根据区域ID查询所有未回款详情
    getAreaNotReceived: (params) => {
        return get(`system/areas/notReceived/${params}`)
    },
    //根据区域ID查询楼栋组别
    getBuildingGroups: (params) => {
        return get(`system/building-groups/${params}`)
    },
    //根据区域ID查询近一个月到期合同详情
    getAreaOneExpired: (params) => {
        return get(`system/areas/oneExpired/${params}`)
    },
    //根据区域ID查询近三个月到期合同详情
    getAreaThreeExpired: (params) => {
        return get(`system/areas/threeExpired/${params}`)
    },
    //根据区域ID查询所有楼栋组别数据
    getAllBuildingGroupData: (params) => {
        return get(`system/areas/getAllBuildingGroupData/${params}`)
    },
    //根据区域ID查询所有区域金额
    getAllBuildingGroupAmount: (params) => {
        return get(`system/areas/getAllBuildingGroupAmount/${params}`)
    },
    //根据区域ID查询不同类型资源面积
    getAreaGroupSpace: (params) => {
        return get(`system/areas/getGroupSpace/${params}`)
    },
    //根据区域ID查询户型间数
    getAreaRoomNumber: (params) => {
        return get(`system/areas/getAllRoomNumber/${params}`)
    },
    //根据区域ID查询各月各类型实收情况
    getAcBldAmount: ([
        areaId,
        monthTime,
        type
    ]) => {
        return get(`system/areas/acBuildingGroupAmount/${areaId}/${monthTime}/${type}`)
    },
    //根据区域ID查询月已收总额
    getAreaAllMonthAmount(params) {
        return get(`system/areas/monthAmount/${params}`)
    },
    //根据区域ID查询未回款总额
    getAreaNotReceivedAmount(params) {
        return get(`system/areas/notReceivedAmount/${params}`)
    },
    //根据园区ID查询月已收
    getAllBuildMonthAmount(params) {
        return get(`system/areas/getAllBuildingGroupMonthAmount/${params}`)
    },
    //根据区域ID查询未回款
    getAllBuildNotReceived(params) {
        return get(`system/areas/getAllBuildingGroupNotReceived/${params}`)
    },


    //根据园区ID查询本月转款总额
    getParkTransferAmount(parkId) {
        return get(`system/parks/transferAmount/${parkId}`)
    },
    //根据园区ID查询本月收款总额
    getParkReceiptAmount(parkId) {
        return get(`system/parks/receiptAmount/${parkId}`)
    },
    //根据园区ID查询各区域本月收款总额
    getParkAreaReceiptAmount(parkId) {
        return get(`system/parks/areaReceiptAmount/${parkId}`)
    },
    //根据园区ID查询各区域本月转款总额
    getParkAreaTransferAmount(parkId) {
        return get(`system/parks/areaTransferAmount/${parkId}`)
    },
    //根据区域ID查询本月转款总额
    getAreaTransferAmount(parkId) {
        return get(`system/areas/transferAmount/${parkId}`)
    },
    //根据区域ID查询本月收款总额
    getAreaReceiptAmount(parkId) {
        return get(`system/areas/receiptAmount/${parkId}`)
    },
    //根据组别ID查询本月转款总额
    getBuildTransferAmount(buildingGroupId) {
        return get(`system/building-groups/transferAmount/${buildingGroupId}`)
    },
    //根据组别ID查询本月收款总额
    getBuildReceiptAmount(buildingGroupId) {
        return get(`system/building-groups/receiptAmount/${buildingGroupId}`)
    },
    //根据楼层ID查询本月转款总额
    getFloorTransferAmount(floorId) {
        return get(`system/floors/transferAmount/${floorId}`)
    },
    //根据楼层ID查询本月收款总额
    getFloorReceiptAmount(floorId) {
        return get(`system/floors/receiptAmount/${floorId}`)
    },
    // //根据园区ID查询各区域本月收款总额
    // getParkAreaReceiptAmount(parkId) {
    //     return get(`system/parks/areaReceiptAmount/${parkId}`)
    // },
    //根据园区ID查询各区域本月转款总额
    getParkAreaTransferAmount(parkId) {
        return get(`system/parks/areaTransferAmount/${parkId}`)
    },
    //根据区域ID查询各楼栋组本月收款总额
    getBuildAreaReceiptAmount(areaId) {
        return get(`system/areas/buildingGroupReceiptAmount/${areaId}`)
    },
    //根据区域ID查询各楼栋组本月转款总额
    getBuildAreaTransferAmount(areaId) {
        return get(`system/areas/buildingGroupTransferAmount/${areaId}`)
    },







    //根据组别ID查询楼栋数据
    getBuilding: (params) => {
        return get(`system/building-groups/${params}`)
    },

    //根据组别ID查询所有楼层
    getFloorList: (params) => {
        return get(`system/floors/buildingGroup/${params}`)
    },
    //根据组别Id查询近一个月到期合同详情
    getBuildingGroupsOneExpired: (params) => {
        return get(`system/building-groups/oneExpired/${params}`)
    },
    //根据组别Id查询近三个月到期合同详情
    getBuildingGroupsThreeExpired: (params) => {
        return get(`system/building-groups/threeExpired/${params}`)
    },
    //根据组别Id查询楼栋套数
    getBuildingRoomNumber: (params) => {
        return get(`system/building-groups/roomNumber/${params}`)
    },
    //根据楼栋分组ID查询公司
    getBuildingCompany: (params) => {
        return get(`system/building-groups/company/${params}`)
    },




    //根据楼层ID查询数据
    getFloor: (params) => {
        return get(`system/floors/getData/${params}`)
    },
    //根据楼层ID查询所有未回款详情
    getFloorNotReceived: (params) => {
        return get(`system/floors/notReceived/${params}`)
    },
    //根据楼层ID查询近一个月到期合同详情
    getFloorOneExpired: (params) => {
        return get(`system/floors/oneExpired/${params}`)
    },
    //根据楼层ID查询近三个月到期合同详情
    getFloorThreeExpired: (params) => {
        return get(`system/floors/threeExpired/${params}`)
    },
    //获取楼层json
    getRoomsJson: (params) => {
        return get(`system/roomsJson/${params}`)
    },
    //根据房间ID查询企业信息
    getCompany: (params) => {
        return get(`system/companys/${params}`)
    },
    //根据房间ID查询企业合同信息
    getCompanyContract: (params) => {
        return get(`system/companys/get/${params}`)
    },
    //根据区域ID查询所有区域金额
    getAllRoomAmount: (params) => {
        return get(`system/floors/getAllRoomAmount/${params}`)
    },


    //根据合同ID查询合同详情
    getcontractTenancyId: (params) => {
        return get(`system/contract-tenancys/${params}`)
    },
    //根据未回款ID查询合同详情
    // getNotReceivedDetail: (params) => {
    //     return get(`system/should-pay-bills/${params}`)
    // },
    //根据未回款ID查询合同详情new
    getNotReceivedDetail: (params) => {
        return get(`system/ls-fee-one/${params}`)
    },
    //根据公司ID查询公司详情
    getCompanysInfo: (params) => {
        return get(`system/companys/getInfo/${params}`)
    },

    //数据分析-租赁资源类型
    getProjectTypeList: () => {
        return get(`system/dataStai/projectType/list`)
    },

    //回款情况明细表获取
    getReceivablesAndActualReceiptsList: () => {
        return get(`system/ysh-receivables-and-actual-receipts/get`)
    },


    //根据房间ID查询企业合同信息
    getIndexLink: () => {
        return get(`/system/ysh-link/link`)
    },

}
