<template>
  <div class="all webviewResources">
     <div class="spin" v-show="spinShow">
      <a-spin tip="Loading..." > </a-spin>
    </div>
    <div class="right-content flex-column">
      <div class="tab-card">
        <a-tabs default-active-key="1" @change="callback">
          <a-tab-pane key="1" tab="资源面积（m²）">
            <div>
              <!-- tag:资源面积 -->
              <div class="resources-measure box-style">
                <!-- <div class="title" @click="toAreaDetail">资源面积（m²）</div> -->
                <a-row>
                  <a-col :span="8" @click="toAreaDetail(1)">
                    <canvas
                      id="canvas0"
                      width="210"
                      height="210"
                      style="background: transparent; width: 100%"
                    ></canvas>
                  </a-col>
                  <a-col :span="8" @click="toAreaDetail(2)">
                    <canvas
                      id="canvas1"
                      width="210"
                      height="210"
                      style="background: transparent; width: 100%"
                    ></canvas>
                  </a-col>
                  <a-col :span="8" @click="toAreaDetail(3)">
                    <canvas
                      id="canvas2"
                      width="210"
                      height="210"
                      style="background: transparent; width: 100%"
                    ></canvas>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="8" @click="toAreaDetail(4)">
                    <div class="num" style="color: var(--blue-color)">
                      {{ 
                        parkData.rentedArea ? 
                        (parseInt(parkData.rentedArea).toFixed(0) || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') :
                        0
                      }}
                    </div>
                    <div class="name">已出租</div>
                    <div class="line blue"></div>
                  </a-col>
                  <a-col :span="8" @click="toAreaDetail(5)">
                    <div class="num" style="color: var(--green-color)">
                      <!-- {{ parseInt(parkData.selfArea).toFixed(0) }} -->
                      {{ 
                        parkData.selfArea ?
                        (parseInt(parkData.selfArea).toFixed(0) || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') :
                        0 
                      }}
                    </div>
                    <div class="name">自用</div>
                    <div class="line green" style=""></div>
                  </a-col>
                  <a-col :span="8" @click="toAreaDetail(7)">
                    <div class="num" style="color: var(--yellow-color)">
                      {{
                        parkData.monthSurrenderTenancyArea ?
                        (parseInt(parkData.monthSurrenderTenancyArea).toFixed(0) || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') :
                        0
                      }}
                    </div>
                    <div class="name">本月度退租</div>
                    <div class="line yellow"></div>
                  </a-col>
                </a-row>
                <a-row style="margin-top: 24px">
                  <a-col :span="8" @click="toAreaDetail(6)">
                    <div class="num" style="color: var(--blue-color)">
                      {{ 
                        parkData.availableArea ? 
                        (parseInt(parkData.availableArea).toFixed(0) || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') :
                        0
                      }}
                    </div>
                    <div class="name">空置</div>
                    <div class="line blue"></div>
                  </a-col>
                  <a-col :span="8" @click="toAreaDetail(9)">
                    <div class="num" style="color: var(--green-color)">
                      {{ 
                        parkData.newRent ? 
                        (parseInt(parkData.newRent).toFixed(0) || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : 
                        0 
                      }}
                    </div>
                    <div class="name">本月新租</div>
                    <div class="line green" style=""></div>
                  </a-col>
                  <a-col :span="8" @click="toAreaDetail(8)">
                    <div class="num" style="color: var(--yellow-color)">
                      {{
                        parkData.yearSurrenderTenancyArea ?
                        (parseInt(parkData.yearSurrenderTenancyArea).toFixed(0) || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') :
                        0
                      }}
                    </div>
                    <div class="name">本年度退租</div>
                    <div class="line yellow"></div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" :tab="`房间数量（${parkData.allRoomNumber}套）`" v-if="areaType == 2">
            <!-- *********公寓************* -->
            <div>
              <!-- tag:房间数量 -->
              <div class="resources-rent box-style flex-column">
                <!-- <div class="title">房间数量（套）</div> -->
                <!-- <a-row>
                  <a-col :span="8" @click="toAreaDetail(0)">
                    <canvas
                      id="canvas3"
                      width="210"
                      height="210"
                      style="background: transparent; width: 100%"
                    ></canvas>
                  </a-col>
                  <a-col :span="8" @click="toAreaDetail(1)">
                    <canvas
                      id="canvas4"
                      width="210"
                      height="210"
                      style="background: transparent; width: 100%"
                    ></canvas>
                  </a-col>
                  <a-col :span="8" @click="toAreaDetail(2)">
                    <canvas
                      id="canvas5"
                      width="210"
                      height="210"
                      style="background: transparent; width: 100%"
                    ></canvas>
                  </a-col>
                </a-row> -->
                <a-row>
                  <a-col :span="8" @click="toAreaDetail(3)">
                    <div class="num" style="color: var(--blue-color)">
                      {{ parkData.rentedRoomNumber }}
                    </div>
                    <div class="name">已出租</div>
                    <div class="line blue"></div>
                  </a-col>
                  <a-col :span="8" @click="toAreaDetail(4)">
                    <div class="num" style="color: var(--green-color)">
                      <!-- {{ parseInt(parkData.selfArea).toFixed(0) }} -->
                      {{ parkData.selfRoomNumber }}
                    </div>
                    <div class="name">自用</div>
                    <div class="line green" style=""></div>
                  </a-col>
                  <a-col :span="8" @click="toAreaDetail(5)">
                    <div class="num" style="color: var(--yellow-color)">
                      {{ parkData.monthSurrenderTenancyRoomNumber }}
                    </div>
                    <div class="name">本月度退租</div>
                    <div class="line yellow"></div>
                  </a-col>
                </a-row>
                <a-row style="margin-top: 24px">
                  <a-col :span="8" @click="toAreaDetail(6)">
                    <div class="num" style="color: var(--blue-color)">
                      {{ parkData.availableRoomNumber }}
                    </div>
                    <div class="name">空置</div>
                    <div class="line blue"></div>
                  </a-col>
                  <!-- @click="toAreaDetail(8)" -->
                  <a-col :span="8" >
                    <div class="num" style="color: var(--green-color)">
                      {{ parkData.newRentRoomNumber }}
                    </div>
                    <div class="name">本月新租</div>
                    <div class="line green" style=""></div>
                  </a-col>
                  <a-col :span="8" @click="toAreaDetail(7)">
                    <div class="num" style="color: var(--yellow-color)">
                      {{ parkData.yearSurrenderTenancyRoomNumber }}
                    </div>
                    <div class="name">本年度退租</div>
                    <div class="line yellow"></div>
                  </a-col>
                </a-row>
              </div>
            </div>
            <!-- 公寓 end -->
          </a-tab-pane>
          <a-tab-pane key="3" tab="已收租金（万元）" v-if="check()">
            <div>
              <!-- tag:租金 -->
              <div class="resources-rent box-style flex-column">
                <!-- <div class="title">已收租金（万元）</div> -->
                <a-row style="margin-top: 20px" type="flex" justify="space-around">
                  <a-col
                    :span="8"
                    class="rent-box"
                    style="border-color: var(--blue-color)"
                  >
                    <div class="num" style="color: var(--blue-color)">
                      {{ numData.transferAmount ? (parseInt(numData.transferAmount) / 10000).toFixed(1) : 0 }}
                    </div>
                    <div class="name">本月度应收</div>
                    <div class="line-bottom-blue"></div>
                  </a-col>
                  <a-col
                    :span="8"
                    class="rent-box"
                    style="border-color: var(--green-color)"
                  >
                    <div class="num" style="color: var(--green-color)" v-if="allMonthAmount !== undefined">
                      {{ allMonthAmount ? (parseInt(allMonthAmount) / 10000).toFixed(1) : 0 }}
                    </div>
                    <div class="num" style="color: var(--green-color)" v-else>
                      {{ parkData.monthAmount ? (parseInt(parkData.monthAmount) / 10000).toFixed(1) : 0 }}
                    </div>
                    <!-- <div class="num" style="color: var(--green-color)">
                      {{(numData.monthAmount && numData.transferAmount) 
                      ? (parseInt(numData.monthAmount + numData.transferAmount) / 10000).toFixed(2) 
                      : 0}}
                    </div> -->
                    <div class="name">本月度已收</div>
                    <div class="line-bottom-green"></div>
                  </a-col>
                </a-row>
                <a-row style="margin-top: 20px" type="flex" justify="space-around">
                  <a-col
                    :span="8"
                    class="rent-box"
                    style="border-color: #6170F4;"
                    @click="toRentDetail()"
                  >
                    <div class="num" style="color: #6170F4">
                      {{ numData.monthAmount ? (parseInt(numData.monthAmount) / 10000).toFixed(1) : 0 }}
                    </div>
                    <div class="name">本月实收金额</div>
                    <div class="line-bottom-violet"></div>
                  </a-col>
                  <a-col
                    :span="8"
                    class="rent-box"
                    style="border-color: var(--yellow-color);"
                    @click="toRentDetail()"
                  >
                    <div class="num" style="color: var(--yellow-color)">
                      {{ parkData.yearAmount ? (parseInt(parkData.yearAmount) / 10000).toFixed(1) : 0 }}
                    </div>
                    <!-- <div class="name">本月转款金额</div> -->
                    <div class="name">本年度</div>
                    <div class="line-bottom-yellow"></div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="4" :tab="`当月未回款（${notReceived.length || 0 }）`">
            <div>
              <!-- tag:未回租金 -->
              <div class="resources-rent box-style flex-column">
                <!-- <div class="title">未回款（{{parkData.notReceivedCount }}）</div> -->
                <a-row class="content-row"
                  :style="{ height: clientHeight > 800 ? '680px' : '480px'}" 
                >
                <!-- :style="{ height: clientHeight > 800 ? '330px' : '265px'}" -->
                  <a-table 
                    :columns="columns" 
                    :rowKey="(record,index) => { return index }" 
                    :data-source="notReceived" 
                    :showHeader="false" 
                    :pagination="false"
                    :customRow="Rowclick"
                  >
                    <div slot="amount" slot-scope="scope">
                      <span style="color:#63DAAB;font-weight:blod;">{{scope}}</span>万元
                    </div>
                  </a-table>
                </a-row>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="5" :tab="`近一月合同将到期（${parkData.ExpireOneMonthCount}）`">
            <div>
              <!-- tag:合同 -->
              <div class="resources-expiredContract box-style">
                <template>
                  <div class="popover-box" :style="{ height: clientHeight > 800 ? '700px' : '550px'}">
                    <div class="box-content"
                      :style="{ height: clientHeight > 800 ? '700px' : '530px'}"
                    >
                      <div
                        v-for="(item, index) in contractArr"
                        :key="index"
                        style="margin-bottom: 16px"
                        @click="toDetail(item,'1')"
                      >
                        <div class="contract-data-title flex-row">
                          <img
                            src="../../assets/index/ball.png"
                            width="16"
                            height="16"
                          />
                          <div style="color: #6395fa; margin-left: 10px">
                            {{ item.endDate }}
                          </div>
                        </div>
                        <div class="contract-data flex-row">
                          <div>{{ item.companyName }}</div>
                          <div>
                            <span class="num">{{
                              parseFloat(item.rentAmount || 0).toFixed(0)
                            }}</span>
                            <span>元</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="6" :tab="`近三月合同将到期（${parkData.ExpireThreeMonthCount}）`">
            <div>
              <!-- tag:合同 -->
              <div class="resources-expiredContract box-style">
                <template>
                  <div class="popover-box" :style="{ height: clientHeight > 800 ? '700px' : '550px'}">
                    <div class="box-content"
                      :style="{ height: clientHeight > 800 ? '700px' : '530px'}"
                    >
                      <div
                        v-for="(item, index) in contractArrThree"
                        :key="index"
                        style="margin-bottom: 16px"
                        @click="toDetail(item,'1')"
                      >
                        <div class="contract-data-title flex-row">
                          <img
                            src="../../assets/index/ball.png"
                            width="16"
                            height="16"
                          />
                          <div style="color: #6395fa; margin-left: 10px">
                            {{ item.endDate }}
                          </div>
                        </div>
                        <div
                          class="contract-data flex-row"
                        >
                          <div>{{ item.companyName }}</div>
                          <div>
                            <span class="num">{{
                              parseFloat(item.rentAmount || 0).toFixed(0)
                            }}</span>
                            <span>元</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
      <!-- <a-carousel :after-change="onChangeCarousel" ></a-carousel> -->
    </div>
  </div>
</template>

<script>
import { Api } from "../../api/api.js";
import moment from "moment";
import wx from 'weixin-js-sdk';
import { create } from 'ol/transform';

const columns = [
  {
    title: 'companyName',
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title:'amount',
    dataIndex: 'amount',
    key: 'amount',
    slots: { title: 'amount' },
    scopedSlots: { customRender: 'amount' },
    width:120,
  },
];
export default {
  props: {
    id: Number,
    level: String,
    areaType: Number,
  },
  data() {
    return {
      resources: {
        measure: undefined, //面积资源
        rent: undefined, //租金
        expiredCcontract: undefined, //到期合同
      },
      canvasArr: [
        { color: "#6395FA", bg: "rgba(99, 149, 250, 0.2)", name: "总面积" },
        { color: "#79DFB7", bg: "rgba(99, 218, 171, 0.2)", name: "出租率" },
        { color: "#FFCA14", bg: "rgba(246, 210, 85, 0.2)", name: "空置率" },
      ],
      roomCanvasArr: [
        { color: "#6395FA", bg: "rgba(99, 149, 250, 0.2)", name: "总数", index: 3 },
        { color: "#79DFB7", bg: "rgba(99, 218, 171, 0.2)", name: "出租率", index: 4 },
        { color: "#FFCA14", bg: "rgba(246, 210, 85, 0.2)", name: "空置率", index:5 },
      ],
      rentVisible: false,
      contractVisible: false,
      contractArr: [],
      contractArrThree:[],//近三月合同将到期，数据
      parkId: undefined, //园区ID
      parkData: {}, //园区总数据
      canvasData: [], //环形图数据
      canvasDataRooms: [], //房间数量，环形图数据
      contractName: undefined, //合同到期名称
      contractCount: undefined, //合同到期数量
      allMonthAmount: undefined,
      allNotReceivedAmount: undefined,
      allNotReceivedCount: undefined,
      numData:{
        transferAmount: 0, //本月转款金额
        monthAmount: 0, //本月收款金额
      },
      notReceived: [], //未回款列表
      columns,//表头
      clientHeight:document.documentElement.clientHeight,//手机高度
      spinShow:false,
      Rowclick(record,index) {
        return {
          on:{
            click:() => {
              console.log(record,index)
                wx.miniProgram.navigateTo(
                  {
                    url: '/pages/contract/detail?id='+record.id + '&type=2'
                  }
                )
            }
          }
        }
      },
      activePage:0
    };
  },
  watch: {
    // areaType(newValue,old){
    //   console.log(newValue,old,'areaType')
    //   if(newValue == 2){
    //     this.getRoomNum();
    //   }
    // },
    id(e) {
      const vm = this;
      vm.spinShow = true;
      vm.myTimer = setInterval(function(){
        vm.spinShow = false;//加载完毕
        clearInterval(vm.myTimer)
      },1000)
      // vm.init();
    //   setTimeout(async () => {
    //     console.log(this.id, this.level,'id level');
    //     switch (this.level) {
    //       case "park":
    //         await this.getParkData();
    //         break;
    //       case "area":
    //         await this.getAreaData();
    //         break;
    //       case "building":
    //         await this.getBuilding();
    //         break;
    //       case "floor":
    //         await this.getFloor();
    //         break;
    //     }
        
    //     // await this.getParkData(this.id);
    //     vm.canvasArr.forEach((item, index) => {
    //       vm.toCanvas(
    //         "canvas" + index,
    //         vm.canvasData[index],
    //         item.name,
    //         item.color,
    //         item.bg
    //       );
    //     });
    //     if(this.areaType == 2){
    //       // this.getRoomNum();
    //     }
    //     this.showRent();
    //     this.showOneContract();//默认展示 近一个月合同
    //   }, 300);
    },
  },
  created(){
    this.rentLevel = this.level;
    
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init(){
      console.log(this.id, this.level,'id level');
      let vm = this;
        vm.showRent();
        vm.getParkAllMonthAmount();
        switch (vm.level) {
          case "park":
            await vm.getParkData();
            break;
          case "area":
            await vm.getAreaData();
            break;
          case "building":
            await vm.getBuilding();
            break;
          case "floor":
            await vm.getFloor();
            break;
        }
        
        // await this.getParkData(this.id);
        vm.canvasArr.forEach((item, index) => {
          vm.toCanvas(
            "canvas" + index,
            vm.canvasData[index],
            item.name,
            item.color,
            item.bg
          );
        });
        if(vm.areaType == 2){
          vm.$nextTick(function(){
            // this.getRoomNum();
          })
          
        }
        vm.showOneContract();//默认展示 近一个月合同
        vm.showThreeContract();
        vm.getParkNotReceivedAmount();
        vm.canvasArr.forEach((item, index) => {
        vm.toCanvas("canvas" + index, vm.canvasData[index], item.name, item.color, item.bg);
      });

    },
    //去合同详情
    toDetail(item,type){
      console.log(item,'item')
      wx.miniProgram.navigateTo(
        {
          url: '/pages/contract/detail?id='+item.id + '&type=' + type
        }
      )
    },
    //去 实收金额详情
    toRentDetail(){
      wx.miniProgram.navigateTo(
        {
          url: '/pages/overview/rent/data?id='+this.id + '&level=' + this.level
        }
      )
    },
    callback(ev){
      // console.log(ev)
      this.isRooms = (ev == 2 && this.areaType == 2) ? true : false
    },
    check() {
      console.log(this.level)
     /* alert("1111")
      alert(localStorage.getItem('role'))*/
      if (this.level == "park"){
        return (localStorage.getItem('role').includes('YJQX') || localStorage.getItem('role').includes('EJQX'));
      }else if (this.level == "area"){
        return (localStorage.getItem('role').includes('YJQX') || localStorage.getItem('role').includes('EJQX')
            || localStorage.getItem('role').includes('CQSJQX') || localStorage.getItem('role').includes('DSSJQX')
            || localStorage.getItem('role').includes('ZJSJQX') || localStorage.getItem('role').includes('GYSSSJQX'));
      }
    },
    //轮播切换
    // onChangeCarousel(ev){
    //   // console.log(ev)
    //   this.isRooms = (ev == 1 && this.areaType == 2) ? true : false
    // },
    //根据园区ID查询月已收总额
    async getParkAllMonthAmount() {
      if (this.level == "park") {
        Api.getParkAllMonthAmount(4).then((res) => {
          this.allMonthAmount = res.data.monthAmount;
        });
      } else if (this.level == "area") {
        Api.getAreaAllMonthAmount(this.id).then((res) => {
          this.allMonthAmount = res.data.monthAmount;
        });
      }
    },
    //根据园区ID查询未回款总额
    getParkNotReceivedAmount() {
      if (this.check()){
        if (this.level == "park") {
          Api.getParkNotReceivedAmount(4).then((res) => {
            this.allNotReceivedAmount = res.data.notReceivedAmountCount;
            this.allNotReceivedCount = res.data.notReceivedCount;
          });
        } else if (this.level == "area") {
          Api.getAreaNotReceivedAmount(this.id).then((res) => {
            this.allNotReceivedAmount = res.data.notReceivedAmountCount;
            this.allNotReceivedCount = res.data.notReceivedCount;
          });
        } else if (this.level == "building") {
          
        }
      }
    },
    //跳转资源面积更多详情
    toAreaDetail(index,type){
      if(this.level == 'park' || this.level == 'area'){
        let parkId = this.$route.query.parkId;
        let aId = this.$route.query.aId;
        if(this.check1()) {
          if(this.areaType == 2 && this.isRooms){
            wx.miniProgram.navigateTo(
              {
                url: '/pages/overview/apartment/apartment?id=' + this.id + '&level=' + this.level 
                + '&parkId=' + parkId + '&index=' + index + '&isRooms=' + this.isRooms
              }
            )
          }else {
            wx.miniProgram.navigateTo(
              {
                url: '/pages/overview/area/detail?id=' + this.id + '&level=' + this.level 
                + '&parkId=' + parkId + '&index=' + index
              }
            )
          }
        }
      }
      
    },
    // done:资源面积圆形图
    toCanvas(id, progress, name, color, bgColor) {
      //canvas进度条
      var canvas = document.getElementById(id);
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      var percent = progress; //最终百分比
      var circleX = canvas.width / 2; //中心x坐标
      var circleY = canvas.height / 2; //中心y坐标
      var radius = 75; //圆环半径
      var lineWidth = 14; //圆形线条的宽度
      //中间的字
      var fontSize = 35;
      // var fontWeight = 600
      // var fontWeight = 600
      ctx.font = "900" + fontSize + "px April";
      ctx.font = " 700 35px arial";
      ctx.textAlign = "center";
      // fontWeight = null
      ctx.fillStyle = color;
      ctx.fillText(percent, circleX, circleY + 8);
      fontSize = 16;
      ctx.font = " 500 22px arial";
      ctx.fillStyle = "black";
      ctx.fillText(name, circleX, circleY + 35);
      // ctx.fillStyle = "#999";
      // ctx.fillText("还差20积分升级会员", circleX, circleY + 40);
      //画圆
      function circle(cx, cy, r) {
        ctx.beginPath();
        //ctx.moveTo(cx + r, cy);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = bgColor;
        ctx.arc(cx, cy, r, 0, (Math.PI * 6) / 3);
        ctx.stroke();
      }
      // 画弧线
      function sector(cx, cy, r, startAngle, endAngle) {
        ctx.beginPath();
        //ctx.moveTo(cx, cy + r); // 从圆形底部开始画
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;
        //圆弧两端的样式
        ctx.lineCap = "butt";
        //圆弧
        ctx.arc(cx, cy, r, (Math.PI * 1) / 2, (Math.PI * 1) / 2 + (endAngle / 100) * ((Math.PI * 6) / 3), false);
        ctx.stroke();
      }
      circle(circleX, circleY, radius);

      //圆弧
      if (name == "总面积") {
        sector(circleX, circleY, radius, (Math.PI * 2) / 3, 100);
      } else {
        sector(circleX, circleY, radius, (Math.PI * 2) / 3, parseInt(percent));
      }
    },
    // done:未回款详情
    async showRent() {
      var res;
      switch (this.level) {
        case "park":
          res = await Api.getNotReceived(this.id);
          break;
        case "area":
          res = await Api.getAreaNotReceived(this.id);
          break;
        case "building":
          // res = await Api.getbuildingNotReceived(this.id);
          break;
        case "floor":
          res = await Api.getFloorNotReceived(this.id);
          break;
      }
      if(res){
        res.data.data.forEach((item,index) => {
          item.notReceiveAmount = parseFloat(item.notReceiveAmount/10000).toFixed(2)
        })
        this.notReceived = res.data.data;
      }
      
    },
    // done:查询近一个月到期合同详情
    async showOneContract() {
      let item = localStorage.getItem("role");
      let items = item.split(",")
      let item1 = ["YJQX","EJQX","CQSJQX","DSSJQX","ZJSJQX","GYSSSJQX"];
      for (let itemElement of items) {
        if (item1.includes(itemElement)){
          var res;
          switch (this.level) {
            case "park":
              res = await Api.getOneExpired(this.id);
              break;
            case "area":
              res = await Api.getAreaOneExpired(this.id);
              break;
            case "building":
              res = await Api.getBuildingGroupsOneExpired(this.id);
              break;
            case "floor":
              res = await Api.getFloorOneExpired(this.id);
              break;
          }
          if(res){
            this.contractName = "近一月合同将到期";
            this.contractCount = res.data.count;
            this.contractArr = res.data.data;
            this.contractArr.forEach((item) => {
              item.endDate = moment(item.endDate).format("YYYY/MM/DD HH:mm:ss");
            });
            this.contractVisible = true;
          }
        }
      }
    
    },
    // done:查询近三个月到期合同详情
    async showThreeContract() {
      var res;
      switch (this.level) {
        case "park":
          res = await Api.getThreeExpired(this.id);
          break;
        case "area":
          res = await Api.getAreaThreeExpired(this.id);
          break;
        case "building":
          res = await Api.getBuildingGroupsThreeExpired(this.id);
          break;
        case "floor":
          res = await Api.getFloorThreeExpired(this.id);
          break;
      }
      this.contractName = "近三月合同将到期";
      this.contractCount = res.data.count;
      this.contractArrThree = res.data.data;
      this.contractArrThree.forEach((item) => {
        item.endDate = moment(item.endDate).format("YYYY/MM/DD HH:mm:ss");
      });
      this.contractVisible = true;
      // console.log(this.contractArrThree,'contractArrThree')
    },
    // done:获取园区数据
    async getParkData() {
      const res = await Api.getPark(this.id);
      // console.log(res,'parkData');
      var arr = [];
      res.data.area = ((res.data.area).toFixed(0) || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      arr.push(res.data.area);
      arr.push((res.data.rentedPercentage * 100).toFixed(2) + "%");
      arr.push((res.data.availablePercentage * 100).toFixed(2) + "%");
      this.canvasData = arr;
      this.parkData = res.data;

      // 房间数量
      var roomArr = [];
      roomArr.push(res.data.allRoomNumber);
      roomArr.push((res.data.rentedNumberPercentage * 100).toFixed(2) + "%");
      roomArr.push((res.data.availableNumberPercentage * 100).toFixed(2) + "%");
      this.canvasDataRooms = roomArr;
      
      if (this.check()){
        //本月转款金额
        Api.getParkTransferAmount(4).then((res) => {
          this.numData.transferAmount = res.data.transfer;
        })
        //本月收款金额
        Api.getParkReceiptAmount(4).then((res) => {
          this.numData.monthAmount = res.data.monthAmount;
        })
      }
    },
    // done:获取区域数据
    async getAreaData() {
      const that = this;
      const res = await Api.getAreaData(this.id);
      // console.log(res.data);
      var arr = [];
      res.data.space = ((res.data.space).toFixed(0) || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      arr.push(res.data.space);
      arr.push((res.data.rentedPercentage * 100).toFixed(2) + "%");
      arr.push((res.data.availablePercentage * 100).toFixed(2) + "%");
      this.canvasData = arr;
      this.parkData = res.data;

      // 房间数量
      var roomArr = [];
      roomArr.push(res.data.allRoomNumber);
      roomArr.push((res.data.rentedNumberPercentage * 100).toFixed(2) + "%");
      roomArr.push((res.data.availableNumberPercentage * 100).toFixed(2) + "%");
      this.canvasDataRooms = roomArr;

      if (this.check()){
        //本月转款金额
        Api.getAreaTransferAmount(this.id).then((res) => {
          this.numData.transferAmount  = res.data.transfer;
        })
        //本月收款金额
        Api.getAreaReceiptAmount(this.id).then((res) => {
          this.numData.monthAmount = res.data.monthAmount;
        })
      }
      
    },
    // done:获取楼栋数据
    async getBuilding() {
      const res = await Api.getBuilding(parseInt(this.id));
      this.areaName = res.data.areaName;
      this.buildingGroupName = res.data.buildingGroupName;
      var arr = [];
      res.data.space = ((res.data.space).toFixed(0) || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      arr.push(res.data.space);
      arr.push((res.data.rentedPercentage * 100).toFixed(2) + "%");
      arr.push((res.data.availablePercentage * 100).toFixed(2) + "%");
      this.canvasData = arr;
      this.parkData = res.data;

      if (this.check()){
        //本月转款金额
        Api.getBuildTransferAmount(this.id).then((res) => {
          this.numData.transferAmount= res.data.transfer;
        })
        //本月收款金额
        Api.getBuildReceiptAmount(this.id).then((res) => {
          this.numData.monthAmount = res.data.monthAmount;
        })
      }
    },
    // done:获取楼层数据
    async getFloor() {
      const res = await Api.getFloor(parseInt(this.id));
      this.areaName = res.data.areaName;
      this.buildingGroupName = res.data.buildingGroupName;
      var arr = [];
      res.data.space = (parseInt(res.data.space) || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      arr.push(res.data.space);
      arr.push((res.data.rentedPercentage * 100).toFixed(2) + "%");
      arr.push((res.data.availablePercentage * 100).toFixed(2) + "%");
      this.canvasData = arr;
      this.parkData = res.data;
      
      if (this.check()){
        //本月转款金额
        Api.getFloorTransferAmount(this.id).then((res) => {
          this.numData.transferAmount = res.data.transfer;
        })
        //本月收款金额
        Api.getFloorReceiptAmount(this.id).then((res) => {
          this.numData.monthAmount = res.data.monthAmount;
        })
      }
    },
    //获取房间数量 数据
    getRoomNum(){
      const that = this;
      this.$nextTick(function(){
        that.roomCanvasArr.forEach((item,index) => {
          that.toCanvas(
            "canvas" + item.index,
            that.canvasDataRooms[index],
            item.name,
            item.color,
            item.bg
          )
        })
      })
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  // .num {
  //   font-size: 20px !important;
  // }
  // .all {
  // }
}
.spin {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  z-index: 9999;
  background: white;
}
.all {
  height: 100%;
  .num {
    font-family: DIN;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }
  .right-content {
    height: 100%;
    background:#ffffff;
    // overflow-x:scroll;
    .title {
      background-image: url("../../assets/index/nameBg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 40px;
      color: #151c51;
      font-family: Microsoft YaHei UI;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      padding-left: 52px;
      line-height: 40px;
    }

    .box-style {
      background: white;
      padding: 15px;
      position: relative;
    }

    .name {
      font-family: Microsoft YaHei UI;
      font-size: 14px;
      color: #000;
      text-align: center;
    }
    .resources-measure {
      height: 100%;
      margin-bottom: 24px;
      .blue {
        background: radial-gradient(50% 50% at 50% 50%, #6395FA 0%, rgba(99, 149, 250, 0.1) 100%);
      }
      .green {
        background: radial-gradient(50% 50% at 50% 50%, #63DAAB 0%, rgba(99, 218, 171, 0) 100%);
      }
      .yellow {
        background: radial-gradient(50% 50% at 50% 50%, #F6D255 0%, rgba(246, 210, 85, 0) 100%);
      }
      .line {
        position: relative;
        top: 4px;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: 70%;
      }
    }
    .resources-rent {
      height: 50%;
      margin-bottom: 24px;
      position: relative;
      .content-row {
        margin-top: 20px;
        // height:265px;
        height: calc(100% - 100px);
        overflow-x:scroll;
      }
      .content-row::-webkit-scrollbar { width: 0 !important }

      .rent-popover {
        content: "";
        position: absolute;
        left: -16px;
        top: 0;
      }
      .rent-box {
        min-height: 94px;
        border: 1px solid;
        box-sizing: border-box;
        width: calc(50% - 15px);
        padding: 0 12px;
        line-height: 2;
        cursor: pointer;
      }
      .line-bottom-blue {
        width:90%;
        height:2px;
        margin-top: -15px;
        background: radial-gradient(50% 50% at 50% 50%, #6395FA 0%, rgba(99, 149, 250, 0.1) 100%);
      }
      .line-bottom-green {
        width:90%;
        height:2px;
        margin-top: -15px;
        background: radial-gradient(50% 50% at 50% 50%, #63DAAB 0%, rgba(99, 218, 171, 0) 100%);
      }
      .line-bottom-violet {
        width:90%;
        height:2px;
        margin-top: -15px;
        background: radial-gradient(50% 50% at 50% 50%, #6170F4 0%, rgba(97, 112, 244, 0.1) 100%);
      }
      .line-bottom-yellow {
        width:90%;
        height:2px;
        margin-top: -15px;
        background: radial-gradient(50% 50% at 50% 50%, #F6D255 0%, rgba(246, 210, 85, 0.1) 100%);
      }
    }
    .resources-expiredContract {
      // height: 34%;
      height: 450px;
      position: relative;
      .line {
        height: 12px;
        background-image: url("../../assets/index/expiredBg.png");
        background-size: 100% 100%;
      }
      .contract-popover {
        content: "";
        position: absolute;
        left: -40px;
        top: 76px;
      }
    }
  }
}
.popover-box {
  width:100%;
  .box-title {
    height: 44px;
  }
}
.box-content {
  margin-top: 20px;
  width: 100%;
  // max-height: 294px;
  // max-height: 480px;
  overflow: auto;
  .text {
    font-size: 16px;
    color: #151a30;
  }
  .number {
    color: #63daab;
    font-size: 14px;
  }
  .contract-data-title {
    // padding: 0 16px;
  }
  .contract-data {
    width:98%;
    padding: 0 0 0 22px;
    justify-content: space-between;
    color: black !important;
    font-size: 14px;
    .text {
      width:70%;
    }
    .num {
      width: 30%;
      color: #6395fa;
      font-weight: 700;
      font-size: 16px;
    }
  }
}
.box-content::-webkit-scrollbar {
  width: 4px;
  position: relative;
  left: 10px;
}
.box-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.box-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
</style>
<style lang="scss">
.webviewResources {
  height: 100%;
  .tab-card {
    padding:15px;
  }
  .ant-tabs {
    width:100%;
    height: 100%;
    overflow: inherit !important;
  }
  .ant-tabs-nav-scroll {
    background:#F0F5FF;
    overflow-y: hidden;
    overflow-x: scroll;
    overflow: none !important;
    white-space: nowrap;
  }
  .ant-tabs-nav-scroll::-webkit-scrollbar {
    width:0 !important;
    height: 0 !important;
  }
  .ant-tabs-nav {
    width:100%;
    height:48px;
    background:#F0F5FF;
  }
  .ant-tabs-nav .ant-tabs-tab {
    background:#F0F5FF !important;
    opacity: 1 !important;
  }
  .ant-tabs-ink-bar {
    bottom: 4px;
    height: 3px;
  }
  .ant-tabs-tab-next-icon,.ant-tabs-tab-prev-icon {
    height:48px !important;
  }
}
.ant-popover-arrow {
  display: none !important;
}
.ant-popover-inner {
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #6395fa !important;
}
.popover-text {
}
.ant-carousel .slick-dots {
  position: fixed;
  bottom: 30px;
}
.ant-carousel .slick-dots li {
  width:10px;
  height:10px;
  border:1px solid #6395FA;
  border-radius: 100%;
}
.ant-carousel .slick-dots li.slick-active button {
  width:10px;
  height:10px;
  border:1px solid #6395FA;
  margin-top: -1px;
  margin-left: -1px;
  background:#6395FA;
  border-radius: 100%;
}
</style>
