<template>
  <div class="resources">
    <a-row style="height: 100%" type="flex" class="flex-row">
      <!-- tag:左侧地图 -->
      <a-col :span="18" style="height: 100%" class="left-content">
        <!-- <button @click="next()">切换</button>
        <button @click="callback">返回</button> -->
        <div :class="navClass">
          <a-breadcrumb class="breadcrumb">
            <span slot="separator" style="color: var(--blue-color)">></span>
            <a-breadcrumb-item href="" @click.native="backPark"
              >银星科技园</a-breadcrumb-item
            >
            <a-breadcrumb-item href=""> {{ areaName }} </a-breadcrumb-item>
          </a-breadcrumb>
          <div
            style="color: var(--blue-color); font-size: 16px"
            class="pointer"
            @click="backPre"
          >
            返回上一级
          </div>
        </div>
        <a-radio-group
          :value="model"
          @change="changeModel"
          style="position: absolute; bottom: 24px; left: 24px; z-index: 9998"
          v-if="aId == 19"
        >
          <a-radio-button value="2D"> 2D </a-radio-button>
          <a-radio-button value="3D"> 3D </a-radio-button>
        </a-radio-group>
        <!-- <a-button
          @click="changeModel"
          type="primary"

          >2/3D切换</a-button
        > -->
        <div
          style="
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 9999;
            justify-content: center;
            background: white;
          "
          class="flex-row"
          v-show="spinShow"
        >
          <a-spin tip="Loading..." size="large"> </a-spin>
        </div>
        <div style="height: 100%" id="mapContent" :key="key" :class="mapClass">
          <!-- <a-tabs
            default-active-key="1"
            tab-position="top"
            :style="{ height: '44px' }"
            @prevClick="callback"
            @nextClick="callback"
            class="floor-change"
            v-show="tabShow"
          >
            <a-tab-pane v-for="i in 30" :key="i" :tab="`${i}F`">
              {{ i + "F" }}
            </a-tab-pane>
          </a-tabs>
          <a-popover
            v-model="measureVisible"
            trigger="click"
            placement="leftTop"
            id="pop"
          >
            <template slot="content">
              <div class="popover-box" @click="enter">
                <div
                  class="box-title title-background flex-row pointer"
                  style="justify-content: space-between"
                >
                  {{ buildingData.buildingGroupName }}
                </div>
                <div class="box-content pointer">
                  <div class="text">
                    资源面积（万m²）：{{
                      (parseInt(buildingData.space) / 10000).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    可出租面积（万m²）：{{
                      (parseInt(buildingData.leasableArea) / 10000).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    已出租面积（万m²）：{{
                      (parseInt(buildingData.rentedArea) / 10000).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    空置面积（万m²）：{{
                      (parseInt(buildingData.availableArea) / 10000).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    自用面积（万m²）：{{
                      (parseInt(buildingData.selfArea) / 10000).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    出租率（%）：{{
                      (buildingData.rentedPercentage * 100).toFixed(2)
                    }}%
                  </div>
                  <div class="text">
                    空置率（%）：{{
                      (buildingData.availablePercentage * 100).toFixed(2)
                    }}%
                  </div>
                  <div class="text">
                    本年度退租面积（万m²）：{{
                      (
                        parseInt(buildingData.yearSurrenderTenancyArea) / 10000
                      ).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    本月度退租面积（万m²）：{{
                      (
                        parseInt(buildingData.monthSurrenderTenancyArea) / 10000
                      ).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    本年度已收租金（万元）：{{
                      (parseInt(buildingData.yearAmount) / 10000).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    本月度已收租金（万元）：{{
                      (parseInt(buildingData.monthAmount) / 10000).toFixed(2)
                    }}
                  </div>
                </div>
              </div>
            </template>
            <div class="measure-popover" :class="popoverClass"></div>
          </a-popover> -->
        </div>
      </a-col>
      <!-- tag:右边数据栏 -->
      <a-col
        :span="6"
        style="
          height: 100%;
          padding-left: 24px;
          position: relative;
          right: -600px;
        "
        :class="transform"
      >
        <Allresources :id="parseInt(aId)" :level="'area'"></Allresources>
      </a-col>
    </a-row>
    <a-modal
      v-model="roomVisible"
      :title="false"
      :footer="null"
      :centered="true"
      :maskStyle="{ background: 'rgba(16, 36, 87, 0.65)' }"
      width="800px"
      :closable="false"
      :zIndex="9999"
    >
      <div v-if="areaType == 2">
        <div class="title-background-long flex-row modal-title">
          <div class="" style="margin-left: 20px">{{ buildTitle }}</div>
          <div>
            <a-icon
              type="close"
              class="pointer"
              style="color: #6395fa; margin-left: 8px"
              @click="roomVisible = false"
            />
          </div>
        </div>
        <div class="measure-box">
          <a-spin
            tip="Loading..."
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
            size="large"
            v-show="roomSpin"
          >
          </a-spin>
          <a-tabs
            default-active-key="1"
            v-if="BuildingRoomData"
            v-model="roomActiveKey"
            :tabBarStyle="{
              color: 'black',
              fontSize: '16px',
              fontWeight: '700',
            }"
          >
            <a-tab-pane :key="0" tab="总面积">
              <div class="roomModal-row">
                <div
                  class="roomModal-col"
                  v-for="(item, index) in BuildingRoomData[8]"
                >
                  <div class="name">{{ item.name }}</div>
                  <div>
                    <span class="number">{{
                      item.roomArea
                        .toFixed(2)
                        .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
                    }}</span
                    ><span>m²</span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="1" tab="总间数">
              <div class="roomModal-row">
                <div
                  class="roomModal-col"
                  v-for="(item, index) in BuildingRoomData[0]"
                >
                  <div class="name">{{ item.name }}</div>
                  <div>
                    <span class="number">{{
                      item.Number.toFixed(0).replace(
                        /\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,
                        "$&,"
                      )
                    }}</span
                    ><span>套</span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="2" tab="出租率">
              <div class="roomModal-row">
                <div
                  class="roomModal-col"
                  v-for="(item, index) in BuildingRoomData[1]"
                >
                  <div class="name">{{ item.name }}</div>
                  <div>
                    <span class="number" v-if="item.rentedRoomPer !== 'NaN'">{{
                      (item.rentedRoomPer * 100)
                        .toFixed(3)
                        .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
                    }}</span>
                    <span class="number" v-if="item.rentedRoomPer == 'NaN'">{{
                      0
                    }}</span>
                    <span>%</span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="3" tab="空置率">
              <div class="roomModal-row">
                <div
                  class="roomModal-col"
                  v-for="(item, index) in BuildingRoomData[2]"
                >
                  <div class="name">{{ item.name }}</div>
                  <div>
                    <span
                      class="number"
                      v-if="item.availableRoomPer !== 'NaN'"
                      >{{
                        (item.availableRoomPer * 100)
                          .toFixed(3)
                          .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
                      }}</span
                    >
                    <span
                      class="number"
                      v-if="item.availableRoomPer == 'NaN'"
                      >{{ 0 }}</span
                    >
                    <span>%</span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="4" tab="已出租">
              <div class="roomModal-row">
                <div
                  class="roomModal-col"
                  v-for="(item, index) in BuildingRoomData[3]"
                >
                  <div class="name">{{ item.name }}</div>
                  <div>
                    <span class="number">{{
                      item.rentedRoomNumber
                        .toFixed(0)
                        .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
                    }}</span
                    ><span>套</span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="5" tab="自用">
              <div class="roomModal-row">
                <div
                  class="roomModal-col"
                  v-for="(item, index) in BuildingRoomData[4]"
                >
                  <div class="name">{{ item.name }}</div>
                  <div>
                    <span class="number">{{
                      item.selfRoomNumber
                        .toFixed(0)
                        .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
                    }}</span
                    ><span>套</span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="6" tab="空置">
              <div class="roomModal-row">
                <div
                  class="roomModal-col"
                  v-for="(item, index) in BuildingRoomData[5]"
                >
                  <div class="name">{{ item.name }}</div>
                  <div>
                    <span class="number">{{
                      item.availableRoomNumber
                        .toFixed(0)
                        .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
                    }}</span
                    ><span>套</span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="7" tab="本月度退租">
              <div class="roomModal-row">
                <div
                  class="roomModal-col"
                  v-for="(item, index) in BuildingRoomData[6]"
                >
                  <div class="name">{{ item.name }}</div>
                  <div>
                    <span class="number">{{
                      item.monthRoomNumber
                        .toFixed(0)
                        .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
                    }}</span
                    ><span>套</span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="8" tab="本年度退租">
              <div class="roomModal-row">
                <div
                  class="roomModal-col"
                  v-for="(item, index) in BuildingRoomData[7]"
                >
                  <div class="name">{{ item.name }}</div>
                  <div>
                    <span class="number">{{
                      item.yearRoomNumber
                        .toFixed(0)
                        .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
                    }}</span
                    ><span>套</span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="9" tab="本月新租">
              <div class="roomModal-row">
                <div
                  class="roomModal-col"
                  v-for="(item, index) in BuildingRoomData[9]"
                >
                  <div class="name">{{ item.name }}</div>
                  <div>
                    <span class="number">{{
                      item.newRentRoomNumber
                        .toFixed(0)
                        .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
                    }}</span
                    ><span>套</span>
                  </div>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Api } from "../../../api/api.js";
import "ol/ol.css";
import { Map, View, Overlay, Feature } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorSource, ImageStatic } from "ol/source";
import { Vector as VectorLayer, Image } from "ol/layer";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
// import render3D from "ol-ext/layer/Render3D";
import { Draw, Select, DoubleClickZoom } from "ol/interaction";
import { getCenter, boundingExtent } from "ol/extent";
import { createBox } from "ol/interaction/Draw";
import { click, doubleClick } from "ol/events/condition";
import * as THREE from "three";
import Stats from "three/examples/jsm/libs/stats.module.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import Allresources from "../../../components/allResources.vue";
var container, stats, controls;
var camera, scene, renderer, light, mesh;
export default {
  components: {
    Allresources,
  },
  data() {
    return {
      measureVisible: false,
      key: 0,
      tabShow: false,
      width: 0,
      height: 0,
      aId: undefined,
      buildingData: {},
      areaData: null,
      areaImage: null,
      areaName: null, // 区域名称
      bId: null,
      transform: null,
      mapClass: ["animate__animated", "animate__delay-1s", "animate__zoomIn"],
      navClass: ["map-name", "animate__animated", "animate__backInDown"],
      popoverClass: [],
      model: "2D",
      spinShow: false,
      zoom: 16,
      areaType: 0,
      roomVisible: false,
      buildTitle: "",
      roomActiveKey: 1,
      BuildingRoomData: undefined,
      roomSpin: false,
    };
  },
  beforeCreate() {
    // this.parkId = this.$route.query.id;
  },
  created(){
    this.aId = this.$route.query.aId;
  },
  async mounted() {
    let vm = this;
    vm.getAreaData();
    this.width = document.getElementById("mapContent").clientWidth;
    this.height = document.getElementById("mapContent").clientHeight;
    if (window.innerWidth < 1900 && window.innerWidth > 1600) {
      // this.zoom = 15.8;
    } else if (window.innerWidth < 1600) {
      this.zoom = 15.6;
    }
    // setTimeout(() => {

    // }, 100);
    setTimeout(() => {
      this.transform = "right";
    }, 500);
  },
  methods: {
    keyadd() {
      this.key++;
    },
    // done:地图初始化
    mapInit() {
      this.mapClass.forEach((item, index) => {
        if (item == "backImage") {
          this.mapClass.splice(1, index);
        }
      });
      let that = this;
      var image = new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({ color: "red", width: 1 }),
      });
      var styles = {
        Point: new Style({
          image: image,
        }),
        LineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiLineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiPoint: new Style({
          image: image,
        }),
        MultiPolygon: new Style({
          stroke: new Stroke({
            color: "black",
            width: 1,
          }),
          fill: new Fill({
            color: "white",
          }),
        }),
        Polygon: new Style({
          fill: new Fill({
            color: "rgba(255,255,255, 0)",
          }),
        }),
        GeometryCollection: new Style({
          stroke: new Stroke({
            color: "magenta",
            width: 2,
          }),
          fill: new Fill({
            color: "magenta",
          }),
          image: new CircleStyle({
            radius: 10,
            fill: null,
            stroke: new Stroke({
              color: "magenta",
            }),
          }),
        }),
        Circle: new Style({
          stroke: new Stroke({
            color: "red",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(255,0,0,0.2)",
          }),
        }),
      };

      var styleFunction = function (feature) {
        return styles[feature.getGeometry().getType()];
      };

      var obj = that.areaData;
      if (obj !== null) {
        this.vectorSource = new VectorSource({
          features: new GeoJSON().readFeatures(obj),
          wrapX: false,
          zIndex: 9998,
        });
      } else {
        this.vectorSource = new VectorSource({
          wrapX: false,
          zIndex: 9998,
        });
      }

      this.vectorLayer = new VectorLayer({
        source: this.vectorSource,
        style: styleFunction,
        zIndex: 9998,
      });
      var center = [500, -600];
      var extent = [
        center[0] - (1700 * 2) / 2,
        center[1] - (1050 * 2) / 2,
        center[0] + (1700 * 2) / 2,
        center[1] + (1050 * 2) / 2,
      ];
      var extent2 = [
        center[0] - (1700 * 2.6) / 2,
        center[1] - (1050 * 2.6) / 2,
        center[0] + (1700 * 2.6) / 2,
        center[1] + (1050 * 2.6) / 2,
      ];
      this.map = new Map({
        layers: [this.vectorLayer],
        view: new View({
          center: center,
          extent: extent2,
          //   center: [500, -600],
          zoom: this.zoom, // 定义地图显示层级为2
          // padding: [100, 0, 0, 0],
        }),
        // 让id为map的div作为地图的容器
        target: "mapContent",
      });
      const dblClickInteraction = this.map
        .getInteractions()
        .getArray()
        .find((interaction) => {
          return interaction instanceof DoubleClickZoom;
        });
      this.map.removeInteraction(dblClickInteraction);
      // done:添加底图
      this.map.addLayer(
        new Image({
          source: new ImageStatic({
            // url: "https://minio.yshinfo.com/yinxing/mryeov2eyx6nluj3hgof3utsh56k",
            url: this.areaImage, // 静态地图
            imageExtent: extent, //映射到地图的范围
          }),
        })
      );

      //监听缩放
      this.map.on("moveend", function (e) {
        if (that.zoom == 16) {
          if (e.target.values_.view.values_.zoom < 15.602) {
            that.backPre();
          }
        } else if (that.zoom == 15.6) {
          if (e.target.values_.view.values_.zoom < 15.302) {
            that.backPre();
          }
        }
      });
      //done:图形点击
      var select = new Select({
        style: function (fea, res) {
          return [
            new Style({
              fill: new Fill({
                color: "rgba(99,149,250,0.5)",
              }),
            }),
          ];
        },
      });
      select.on("select", function (e) {
        let item = localStorage.getItem("role");
        let items = item.split(",")
        let itemList = ["YJQX","EJQX","CQSJQX","DSSJQX","ZJSJQX","GYSSSJQX"];
        let i = 0;
        for (let item1 of items) {
          if (itemList.includes(item1)){
            i = 1;
            that.bId = e.selected[0].values_.id;
            if (that.areaType !== 2) {
              that.enter();
            } else {
              that.getBuildingRoomNumber();
            }
            var features = e.target.getFeatures().getArray();
            this.getFeatures().clear();
          }
        }
        if (i == 0){
          that.$message.error('无权限访问');
        }

      });
      this.map.addInteraction(select);
    },
    // done:3d模型初始化
    async map3DInit() {
      const vm = this;
      var model = document.getElementById("mapContent");
      container = document.createElement("div");
      model.appendChild(container);
      scene = new THREE.Scene();
      scene.rotation.set(0, 0, 0);
      // scene.background = new THREE.Color(0xffffff);
      scene.position.set(0, 0, 0);
      scene.receiveShadow = true;
      camera = new THREE.PerspectiveCamera(
        45,
        this.width / this.height,
        1,
        30000
      );
      var loader = new FBXLoader();
      // const path = require('/public/map3/model.fbx')
      loader.load("map3/model.FBX", async function (object) {
        // console.log("loader+++++++++++++++++++++++");
        for (var k in object.children) {
          // object.children[k].castShadow = true;
          // object.children[k].receiveShadow = true;
        }
        object.traverse(function (child) {
          // console.log(child)
          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            child.material.side = THREE.DoubleSide;
            child.material.shadowSide = THREE.BackSide;
            if (child.name == "Circle002") {
              child.scale.set(0.85, 0.85, 1);
            }
          }
          if (child.isGroup) {
            for (var k in child.children) {
              child.children[k].castShadow = true;
              child.children[k].receiveShadow = true;
            }
          }
        });
        // console.log(object.position);
        // console.log(object);
        object.scale.set(0.003, 0.003, 0.003);
        object.position.set(0, 0, 0);
        await scene.add(object);
        vm.mapClass.push("backImage");
        vm.animate();
        light = new THREE.DirectionalLight(0xe6e6ff, 1);
        // light = new THREE.DirectionalLight(0xFFFACD, 6);
        light.position.set(0, 1000, 400);
        light.castShadow = true;
        var d = 1300;
        light.shadow.camera.left = -d;
        light.shadow.camera.right = d;
        light.shadow.camera.top = d;
        light.shadow.camera.bottom = -d;
        light.shadow.camera.near = 100; // default
        light.shadow.camera.far = 1600;
        // //设置阴影分辨率
        light.shadow.mapSize.x = 2048; // default
        light.shadow.mapSize.y = 2048; // default
        //阴影限制
        // light.shadow.radius = 50;
        scene.add(light);

        // light = new THREE.AmbientLight(0xe6e6ff, 1);
        light = new THREE.AmbientLight(0xfff7eb, 1);
        // light = new THREE.AmbientLight(0xFFFFFF, 1);
        scene.add(light);
        camera.position.set(
          703.6778401181376,
          463.8634526235953,
          774.7704098582998
        );
        camera.rotation.set(
          -0.4158477653853748,
          0.7195334475518071,
          0.28320752390665266
        );
        camera.lookAt({ x: 0, y: 0, z: 0 });
        vm.spinShow = false;
      });

      // scene.children.traverse(function(child){
      //   console.log(child)
      // })
      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      // renderer.setClearAlpha(0.2);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(this.width, this.height);
      renderer.shadowMapType = THREE.PCFSoftShadowMap;
      renderer.shadowMapEnabled = true;
      // this.setStationName("别山");
      // console.log(renderer);
      container.appendChild(renderer.domElement);
      controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(0, 0, 0);
      controls.autoRotate = true;
      var i = 0;
      controls.autoRotateSpeed = 5;
      controls.maxPolarAngle = 1.4;
      setTimeout(() => {
        rotate();
      }, 500);
      var rotate = function () {
        setTimeout(() => {
          i = controls.getAzimuthalAngle();
          if (i >= 0.8 && i <= 1) {
            controls.autoRotate = false;
          } else {
            rotate();
          }
        }, 1000);
      };
      rotate();
      controls.update();
      mapContent.addEventListener("click", this.onMouseClick, false);
      window.addEventListener("resize", this.onWindowResize, false);
    },
    //模型点击事件
    onMouseClick(event) {
      const that = this;
      controls.autoRotate = false;
      var raycaster = new THREE.Raycaster();
      var mouse = new THREE.Vector2();
      //通过鼠标点击的位置计算出raycaster所需要的点的位置，以屏幕中心为原点，值的范围为-1到1.
      var container = document.querySelector("#mapContent");
      let getBoundingClientRect = container.getBoundingClientRect();
      mouse.x =
        ((event.clientX - getBoundingClientRect.left) / container.offsetWidth) *
          2 -
        1;
      mouse.y =
        -(
          (event.clientY - getBoundingClientRect.top) /
          container.offsetHeight
        ) *
          2 +
        1;

      // 通过鼠标点的位置和当前相机的矩阵计算出raycaster
      raycaster.setFromCamera(mouse, camera);

      // 获取raycaster直线和所有模型相交的数组集合
      var intersects = raycaster.intersectObjects(scene.children);
      // var intersects = raycaster.intersectObjects(scene);
      // console.log(intersects)
      // console.log(scene);
      // console.log(intersects[0].object.material);
      // intersects[0].object.material.color.set(0x000000);
      switch (intersects[0].object.name) {
        case "Obj3d66-445063-22-880":
        case "Box231":
          this.videoId = 3;
          this.$emit("showModal", 3);
          break;
        case "Obj3d66-445063-22-879":
        case "Box104":
          this.videoId = 2;
          this.$emit("showModal", 2);
          break;
        case "Obj3d66-445063-22-878":
        case "Box132":
          this.videoId = 1;
          this.$emit("showModal", 1);
          break;
      }
      //将所有的相交的模型的颜色设置为红色，如果只需要将第一个触发事件，那就数组的第一个模型改变颜色即可
      for (var i = 0; i < intersects.length; i++) {
        // intersects[i].object.material.color.set(0x000000);
        // console.log(intersects[i])
        if (intersects[i].object.parent.name.substr(1, 1) == "栋") {
          switch (intersects[i].object.parent.name) {
            case "A栋":
              this.getBuilding(6);
              this.bId = 6;
              break;
            case "B栋":
              this.getBuilding(15);
              this.bId = 15;
              break;
            case "C栋":
              this.getBuilding(7);
              this.bId = 7;
              break;
            case "D栋":
              this.getBuilding(8);
              this.bId = 8;
              break;
          }
          setTimeout(() => {
            that.measureVisible = true;
          }, 200);

          // that.enter();
          break;
        }
        // intersects[i].object.traverse(function (item) {
        //   // if (item.parent.isGroup) {
        //   //   console.log(item.parent.name);
        //   //
        //   // }
        // });
      }
    },
    onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    },
    animate() {
      requestAnimationFrame(this.animate);
      controls.update();

      renderer.render(scene, camera);
      // stats.update();
    },
    resetControls() {
      // console.log("reset____________");
      controls.reset();
    },

    callback() {
      this.key++;
      setTimeout(() => {
        this.mapInit();
      }, 1000);
    },
    changeModel(e) {
      this.spinShow = true;
      this.model = e.target.value;
      this.key++;
      setTimeout(async () => {
        if (e.target.value == "3D") {
          await this.map3DInit();
        } else {
          await this.mapInit();
          this.spinShow = false;
        }
      }, 1000);
      // this.key++;
      // setTimeout(() => {
      //   console.log("3Dmapint");
      //   this.map3DInit();
      // }, 1000);
    },
    backPark() {
      this.mapClass.splice(1, 1);
      this.mapClass.push("animate__zoomOut");
      this.navClass.push("animate__backOutUp");
      setTimeout(() => {
        this.$router.push({ path: "/resources", query: { parkId: 4 } });
      }, 700);
    },
    // done:查询所有区域
    // async getAreasList(){
    //   const res =
    // }
    // done:获取底图与geojson
    async getAreaData() {
      const res = await Api.getAreaJson(this.aId);
      this.areaType = res.data.areaType;
      this.areaData = res.data.areaData;
      this.areaImage = res.data.areaImage;
      this.areaName = res.data.areaName;
      await this.mapInit();
    },
    // done:根据组别ID查询楼栋数据
    async getBuilding(bId) {
      const res = await Api.getBuilding(bId);
      this.buildingData = res.data;
      // this.measureVisible = true;
      this.popoverClass = [
        "animate__animated",
        "animate__delay-10s",
        "animate__zoomIn",
      ];
    },
    // done:根据组别id查询所有楼层
    async getBuildingGroups() {
      const res = await Api.getBuildingGroups(this.bId);
      // this.fId = res.data.buildingGroupData.features[0].properties.id;
      // console.log(this.fId);
    },
    // 进入
    async enter() {
      this.transform = "left";
      this.measureVisible = false;
      this.mapClass.splice(1, 1);
      this.navClass.push("animate__backOutUp");
      this.mapClass.push("animate__zoomOut");
      // await this.getBuildingGroups();
      // setTimeout(() => {
      //   this.$router.push({
      //     path: "/resources/building",
      //     query: {
      //       parkId: this.$route.query.parkId,
      //       aId: this.$route.query.aId,
      //       bId: this.bId,
      //     },
      //   });
      // },500);
      setTimeout(() => {
        if (this.areaType == 0) {
          this.$router.push({
            path: "/resources/floor",
            query: {
              parkId: this.$route.query.parkId,
              aId: this.$route.query.aId,
              bId: this.bId,
            },
          });
        } else if (this.areaType == 1) {
          this.$router.push({
            path: "/resources/companyList",
            query: {
              parkId: this.$route.query.parkId,
              aId: this.$route.query.aId,
              bId: this.bId,
            },
          });
        }
      }, 400);
    },
    backPre() {
      setTimeout(() => {
        this.$router.push({
          path: "/resources/park",
          query: {
            parkId: this.$route.query.parkId,
            // aId: this.$route.query.aId,
            // bId: this.bId,
          },
        });
      }, 400);
    },
    //获取楼栋套数
    async getBuildingRoomNumber() {
      this.roomVisible = true;
      this.roomSpin = true;
      const res = await Api.getBuildingRoomNumber(this.bId);
      this.BuildingRoomData = res.data.data;
      this.buildTitle = res.data.buildingGroupName;
      this.roomSpin = false;
    },
    roomModalClose() {
      this.roomVisible == false;
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1600px) {
  .map-name {
    height: 44px !important;
    width: calc(100% - 50px);
    background-image: url("../../../assets/index/yinxingName.png");
    background-size: 100% 112%;
    position: absolute;
    z-index: 9998;
    left: 25px;
    // left: 50%;
    // transform: translateX(-50%);
    top: 16px;
  }
}
.resources {
  height: 100%;
  padding: 24px;
  .left-content {
    height: 100%;
    position: relative;
    background: white;
    // background-image: url("../../assets/index/yinxingBg.png");
    // background-size: 100% 100%;
    // background-position: center;
    .map-name {
      height: 60px;
      width: calc(100% - 50px);
      background-image: url("../../../assets/index/yinxingName.png");
      background-size: 100% 112%;
      position: absolute;
      z-index: 9998;
      left: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 86px;
      top: 16px;
      .ol-control {
        display: none;
      }
      .breadcrumb {
        font-size: 16px;
        color: var(--blue-color);
        span:last-child a {
          font-family: Microsoft YaHei UI;
          font-weight: bold;
          font-size: 20px;
          letter-spacing: 0.12em;
          color: #055fcd;
        }
      }
    }
    .floor-change {
      position: absolute;
      width: calc(100% - 60px);
      z-index: 9999;
      left: 50%;
      transform: translateX(-50%);
      top: 84px;
      background: white;
      color: var(--blue-color);
      font-size: 20px !important;
      font-weight: 600;
    }
    .measure-popover {
      position: absolute;
      top: 30%;
      left: 32%;
    }
  }
}
.popover-box {
  padding: 12px;
  width: 432px;
  height: 380px;
  .box-title {
    height: 44px;
  }
}
.modal-title {
  height: 44px;
  justify-content: space-between;
}
.roomModal-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.roomModal-col {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-right: 46px;
  font-size: 16px;
  color: #151a30;
  .name {
    font-weight: 700;
    font-style: normal;
  }
  .number {
    color: #63daab;
    font-weight: 700;
    letter-spacing: 1px;
  }
}
.box-content {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .text {
    font-size: 16px;
    color: #151a30;
    line-height: 200%;
    margin-right: 20px;
  }
}
.backImage {
  background-image: url("../../../assets/index/sky.jpeg");
  background-size: 100% 100%;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: white !important;
  background: #1890ff !important;
  border-color: #1890ff;
}
</style>
<style lang="scss">
.breadcrumb {
  span:last-child a {
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.12em;
    color: #055fcd !important;
  }
}

.ol-control {
  display: none;
}
#mapContent {
  .ant-tabs-nav .ant-tabs-tab {
    background: #f0f5ff;
    border-radius: 6px;
    width: 54px;
  }
  .ant-tabs-tab-prev-icon,
  .ant-tabs-tab-next-icon {
    line-height: 40px;
    width: 100%;
    background-color: #f0f5ff;
    /* margin-right: 20px !important; */
    color: var(--blue-color);
  }
  .ant-tabs-bar {
    border: none !important;
  }
}
.ant-popover {
  z-index: 9999 !important;
  // animation-name: 'bounceIn';
  // -webkit-animation-duration: 1s;
  // animation-duration: 1s;
  // -webkit-animation-duration: var(--animate-duration);
  // animation-duration: var(--animate-duration);
  // -webkit-animation-fill-mode: both;
  // animation-fill-mode: both;
}
.zoom-big-enter {
  // animation-name: "fadeInLeft" !important;
  // animation-duration: 1s !important;
  // -webkit-animation-duration: 1s;
  // animation-duration: 1s;
  // -webkit-animation-duration: var(--animate-duration);
  // animation-duration: var(--animate-duration);
  // -webkit-animation-fill-mode: both;
  // animation-fill-mode: both;
}
</style>
