<template>
  <div class="resources webview">
    <a-row style="height: 100%">
      <!-- tag:左侧地图 -->
      <a-col style="width:100%;height: 50%;background:#fafafa;" class="left-content">
        <!-- <button @click="next()">切换</button>
        <button @click="callback">返回</button> -->
        <!-- <div class="map-name">
          <a-breadcrumb class="breadcrumb">
            <span slot="separator" style="color: var(--blue-color)">></span>
            <a-breadcrumb-item href="" @click.native="backPark"
              >银星科技园</a-breadcrumb-item
            >
            <a-breadcrumb-item href="" @click.native="backArea">{{
              areaName
            }}</a-breadcrumb-item>
            <a-breadcrumb-item href="">{{
              buildingGroupName
            }}</a-breadcrumb-item>
          </a-breadcrumb>
        </div> -->
        <div style="height: 100%" id="mapContent" :key="key">
          <a-tabs
            :default-active-key="1"
            :activeKey='3'
            tab-position="top"
            :style="{ height: '44px' }"
            @prevClick="callback"
            @nextClick="callback"
            class="floor-change"
          >
            <a-tab-pane v-for="i in 30" :key="i" :tab="`${i}F`">
              {{ i + "F" }}
            </a-tab-pane>
          </a-tabs>
          <!-- <a-popover
            v-model="measureVisible"
            trigger="click"
            placement="leftTop"
          >
            <template slot="content">
              <div class="popover-box">
                <div class="box-title title-background flex-row">智界二期</div>
                <div class="box-content">
                  <div class="text">资源面积（万m²）：15.32</div>
                  <div class="text">可出租面积（万m²）：15.63</div>
                  <div class="text">已出租面积（万m²）：53.23</div>
                  <div class="text">空置面积（万m²）：7.83</div>
                  <div class="text">自用面积（万m²）：24.32</div>
                  <div class="text">出租率（%）：96%</div>
                  <div class="text">空置率（%）：3%</div>
                  <div class="text">本年度退租面积（万m²）：56.32</div>
                  <div class="text">本月度退租面积（万m²）：14.32</div>
                  <div class="text">本年度已收租金（万元）：730.32</div>
                  <div class="text">本月度已收租金（万元）：60.33</div>
                </div>
              </div>
            </template>
            <div class="measure-popover"></div>
          </a-popover> -->
        </div>
      </a-col>
      <!-- tag:右边数据栏 -->
      <a-col style="
        width:100%;
        height: calc(50% + 20px);
        position: relative;
        background:#ffffff;
        border-radius: 16px 16px 0px 0px;
        margin-top:-20px;
        box-shadow: 0px 2px 24px 0 rgba(21, 28, 81, 0.15);
        overflow-x:scroll;
        bottom:0;
      ">
        <Allresources :id="parseInt(bId)" :level="'building'"></Allresources>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Api } from "../../api/api.js";
import "ol/ol.css";
import { Map, View, Overlay, Feature } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorSource, ImageStatic } from "ol/source";
import { Vector as VectorLayer, Image } from "ol/layer";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
// import render3D from "ol-ext/layer/Render3D";
import { Draw, Select, DoubleClickZoom } from "ol/interaction";
import { getCenter, boundingExtent } from "ol/extent";
import { createBox } from "ol/interaction/Draw";
import { click, doubleClick } from "ol/events/condition";
import * as THREE from "three";
import Stats from "three/examples/jsm/libs/stats.module.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import Allresources from "../../components/webview/allResources.vue";
import wx from 'weixin-js-sdk';
var container, stats, controls;
var camera, scene, renderer, light, mesh;
export default {
  components: {
    Allresources,
  },
  data() {
    return {
      measureVisible: false,
      key: 0,
      tabShow: false,
      width: 0,
      height: 0,
      parkId: undefined,
      parkData: {},
      bId: undefined,
      areaName: null,
      buildingGroupName: null,
      buildImage:null,
      buildJson:null
    };
  },
  created(){
    this.bId = this.$route.query.bId;
  },
  async mounted() {
    let vm = this;
    this.width = document.getElementById("mapContent").clientWidth;
    this.height = document.getElementById("mapContent").clientHeight;
    await vm.getBuildingGroups();
    await vm.getBuilding();
    vm.mapInit();
  },
  methods: {
    keyadd() {
      this.key++;
    },
    // done:地图初始化
    mapInit() {
      var image = new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({ color: "red", width: 1 }),
      });
      var styles = {
        Point: new Style({
          image: image,
        }),
        LineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiLineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiPoint: new Style({
          image: image,
        }),
        MultiPolygon: new Style({
          stroke: new Stroke({
            color: "black",
            width: 1,
          }),
          fill: new Fill({
            color: "white",
          }),
        }),
        Polygon: new Style({
          // stroke: new Stroke({
          //   color: "blue",
          //   lineDash: [4],
          //   width: 3,
          // }),
          fill: new Fill({
            color: "rgba(0, 0, 255, 0.1)",
          }),
        }),
        GeometryCollection: new Style({
          stroke: new Stroke({
            color: "magenta",
            width: 2,
          }),
          fill: new Fill({
            color: "magenta",
          }),
          image: new CircleStyle({
            radius: 10,
            fill: null,
            stroke: new Stroke({
              color: "magenta",
            }),
          }),
        }),
        Circle: new Style({
          stroke: new Stroke({
            color: "red",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(255,0,0,0.2)",
          }),
        }),
      };

      var styleFunction = function (feature) {
        return styles[feature.getGeometry().getType()];
      };
      var aOBJ = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [-885.4211376688197, -1194.7756263440276],
                  [1861.5345663297019, -1194.7756263440276],
                  [1861.5345663297019, -1029.9582841041163],
                  [-885.4211376688197, -1029.9582841041163],
                  [-885.4211376688197, -1194.7756263440276],
                ],
              ],
            },
            properties: {id:140},
          },
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [-885.4211376688197, -1034.73559837194],
                  [1859.1459091957902, -1034.73559837194],
                  [1859.1459091957902, -848.4203419268229],
                  [-885.4211376688197, -848.4203419268229],
                  [-885.4211376688197, -1034.73559837194],
                ],
              ],
            },
           properties: {id:137},
          },
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [-883.0324805349079, -848.4203419268229],
                  [1859.1459091957902, -848.4203419268229],
                  [1859.1459091957902, -664.4937426156173],
                  [-883.0324805349079, -664.4937426156173],
                  [-883.0324805349079, -848.4203419268229],
                ],
              ],
            },
            properties: {id:139},
          },
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [-885.4211376688197, -669.2710568834409],
                  [1861.5345663297019, -669.2710568834409],
                  [1861.5345663297019, -482.9558004383238],
                  [-885.4211376688197, -482.9558004383238],
                  [-885.4211376688197, -669.2710568834409],
                ],
              ],
            },
            properties: {id:4},
          },
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [-883.0324805349079, -485.3444575722356],
                  [1861.5345663297019, -485.3444575722356],
                  [1861.5345663297019, -306.1951725288538],
                  [-883.0324805349079, -306.1951725288538],
                  [-883.0324805349079, -485.3444575722356],
                ],
              ],
            },
            properties: {id:5},
          },
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [-627.4461672063499, -306.1951725288538],
                  [1594.004967331585, -306.1951725288538],
                  [1594.004967331585, -50.60885920029568],
                  [-627.4461672063499, -50.60885920029568],
                  [-627.4461672063499, -306.1951725288538],
                ],
              ],
            },
           properties: {id:6},
          },
        ],
      };

      this.vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(this.buildJson),
        wrapX: false,
        zIndex: 9998,
      });

      this.vectorLayer = new VectorLayer({
        source: this.vectorSource,
        style: styleFunction,
        zIndex: 9998,
      });
      var center = [500, -600];
      var extent = [
        center[0] - (1700 * 2) / 2,
        center[1] - (1050 * 2) / 2,
        center[0] + (1700 * 2) / 2,
        center[1] + (1050 * 2) / 2,
      ];
      this.map = new Map({
        layers: [this.vectorLayer],
        view: new View({
          center: center,
          extent: extent,
          //   center: [500, -600],
          zoom: 15, // 定义地图显示层级为2
          // padding: [100, 0, 0, 0],
        }),
        // 让id为map的div作为地图的容器
        target: "mapContent",
      });
      const dblClickInteraction = this.map
        .getInteractions()
        .getArray()
        .find((interaction) => {
          return interaction instanceof DoubleClickZoom;
        });
      this.map.removeInteraction(dblClickInteraction);
      // done:添加底图
      this.map.addLayer(
        new Image({
          source: new ImageStatic({
            // url: "https://minio.yshinfo.com/yinxing/mryeov2eyx6nluj3hgof3utsh56k",
            url: this.buildImage, // 静态地图
            imageExtent: extent, //映射到地图的范围
          }),
        })
      );
      // console.log(this.map);
      let that = this;

      // done:图形双击
      let selectClick = new Select({
        // 事件类型
        condition: doubleClick,
        // 点击后的样式
        style: new Style({
          fill: new Fill({
            color: "rgba(99,149,250,0.5)",
          }),
        }),
      });
      selectClick.on("select", function (e) {
        // console.log(e);
        
      });
      this.map.addInteraction(selectClick);
      //done:图形点击
      var select = new Select({
        style: function (fea, res) {
          return [
            new Style({
              fill: new Fill({
                color: "rgba(99,149,250,0.5)",
              }),
            }),
          ];
        },
      });
      select.on("select", function (e) {
        let parkId = that.$route.query.parkId;
        let aId = that.$route.query.aId;
        let bId = that.$route.query.bId;
        let fId = e.selected[0].values_.id
        wx.miniProgram.navigateTo(
          {
            url: '/pages/overview/viewRoom/room?parkId='+parkId+'&aId='+aId+'&bId='+bId+'&fId='+fId
          }
        )
      });
      // //done:弹窗展示
      // var element;
      // var itemCenter;
      // var popup;
      // var popupName;
      // let featureList = this.vectorSource.getFeatures();
      // featureList.forEach((item, index) => {
      //   popupName = "popup" + index;
      //   popup = new Overlay({
      //     element: document.getElementById(popupName),
      //   });
      //   element = popup.element;
      //   that.map.addOverlay(popup);
      //   itemCenter = getCenter(
      //     boundingExtent(item.getGeometry().getCoordinates()[0])
      //   ); // 获取图形中点
      //   itemCenter[1] = itemCenter[1] + 20;
      //   popup.setPosition(itemCenter); // 将弹窗位置设置为图形中点
      //   element.innerHTML =
      //     "<p style='color:white;font-size:20px;'> " + item.values_.id + "</p>";
      // });
      this.map.addInteraction(select);
      //   var r3D = new render3D({ height: 100, defaultHeight: 10 });
      //   vectorLayer.setRender3D(r3D);
    },
    callback() {
      this.key++;
      setTimeout(() => {
        this.mapInit();
      }, 1000);
    },
    next() {
      this.key++;
      setTimeout(() => {
        this.map3DInit();
      }, 1000);
    },
    // done:查询所有区域
    // async getAreasList(){
    //   const res =
    // }
    // done:根据组别id查询所有楼层
    async getBuildingGroups() {
      const res = await Api.getBuildingGroups(this.bId);
      // console.log(res);
    },
    // done:获取楼栋数据
    async getBuilding() {
      const res = await Api.getBuilding(parseInt(this.bId));
      this.areaName = res.data.areaName;
      this.buildingGroupName = res.data.buildingGroupName;
      this.buildImage = res.data.buildingGroupImage
      this.buildJson = res.data.buildingGroupData
    },
    backPark() {
      this.$router.push({
        path: "/resources/park",
        query: {
          parkId: this.$route.query.parkId,
        },
      });
    },
    backArea() {
      this.$router.push({
        path: "/resources/area",
        query: {
          parkId: this.$route.query.parkId,
          aId: this.$route.query.aId,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.resources {
  height: 100%;
  overflow: hidden;
  .left-content {
    height: 100%;
    position: relative;
    background: white;
    .map-name {
      height: 54px;
      width: calc(100% - 10px);
      background-image: url("../../assets/index/name_bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 9998;
      left: 50%;
      transform: translateX(-50%);
      top: 5px;
      .ol-control {
        display: none;
      }
      .breadcrumb {
        position: absolute;
        top: 50%;
        left: 18%;
        font-size: 14px;
        color: var(--blue-color);
        transform: translateY(-50%);
        span:last-child a {
          font-family: Microsoft YaHei UI;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0.12em;
          color: #055fcd;
        }
      }
    }
    .floor-change {
      position: absolute;
      width: calc(100% - 60px);
      z-index: 9999;
      left: 50%;
      transform: translateX(-50%);
      top: 11px;
    //   background: white;
      color: var(--blue-color);
      font-size: 20px !important;
      font-weight: 600;
    }
    .measure-popover {
      position: absolute;
      bottom: 20px;
    }
  }
}
.popover-box {
  padding: 12px;
  width: 432px;
  height: 380px;
  .box-title {
    height: 44px;
  }
}
.box-content {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .text {
    font-size: 16px;
    color: #151a30;
    line-height: 200%;
    margin-right: 20px;
  }
}
</style>
<style lang="scss">
.webview{
  .breadcrumb {
    span:last-child a {
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.12em;
        color: #055fcd !important;
    }
    }
    .ol-control {
    display: none;
    }
    #mapContent {
    .ant-tabs-nav .ant-tabs-tab {
        background: #f0f5ff;
        border-radius: 6px;
        width: 54px;
    }
    .ant-tabs-nav-wrap {
        padding-left: 10px;
    }
    .ant-tabs-tab-prev-icon,
    .ant-tabs-tab-next-icon {
        line-height: 40px;
        width: 100%;
        background-color: #f0f5ff;
        /* margin-right: 20px !important; */
        color: var(--blue-color);
    }
    .ant-tabs-bar {
        border: none !important;
    }
    .ant-carousel .slick-dots {
      position: fixed;
      bottom: 30px;
    }
    .ant-carousel .slick-dots li {
      width:10px;
      height:10px;
      border:1px solid #6395FA;
      border-radius: 100%;
    }
    .ant-carousel .slick-dots li.slick-active button {
      width:10px;
      height:10px;
      border:1px solid #6395FA;
      margin-top: -1px;
      margin-left: -1px;
      background:#6395FA;
      border-radius: 100%;
    }
  }
}
</style>