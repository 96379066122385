<template>
  <div class="container" :class="isWebView == false ? 'min-width' : ''">
    <a-layout style="height: 100%">
      <!-- tag:头部栏 -->
      <a-layout-header class="header" v-if="!isWebView">
        <div class="nav">
          <spannav-item
            :class="index == 1 ? 'nav-item nav-focus' : 'nav-item'"
            @click="navTo(1)"
            >首页</spannav-item
          >
          <span
            :class="index == 2 ? 'nav-item nav-focus' : 'nav-item'"
            @click="navTo(2)"
            >资源总览</span
          >
          <span
            :class="index == 3 ? 'nav-item nav-focus' : 'nav-item'"
            @click="navTo(3)"
            >数据分析</span
          >
          <a :href="url" target="_blank"   :class="index == 4 ? 'nav-item nav-focus' : 'nav-item'">银星科技园数据中心</a>

        </div>
        <div class="logo">
          <!-- <img src="../assets/index/logo.png" width="174" height="24" />
          <div class="logo-text">产业运营管理系统</div> -->
        </div>
        <div class="user">
          <div class="pointer">
            <a-icon type="user" />
            <span style="margin-left: 4px">{{ name }}</span>
          </div>
          <div style="margin-left: 24px" class="pointer" @click="exit">
            <a-icon type="poweroff" style="position: relative; top: 1px" />
            <span style="margin-left: 4px">退出</span>
          </div>
        </div>
      </a-layout-header>
      <!-- tag:主体部分 -->
      <a-layout-content>
        <div class="spin" v-show="show">
          <a-spin tip="Loading..." size="large"> </a-spin>
        </div>
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
import {Api} from "../api/api";
import moment from "moment";

export default {
  data() {
    return {
      index: undefined,
      show: false,
      isWebView: false, //是否是小程序 webview页面
      name: null,
      url:'',
    };
  },
  mounted() {
    this.name = localStorage.getItem("name");
    if (localStorage.getItem("index")) {
      this.index = localStorage.getItem("index");
    }
    if (this.$route.fullPath.indexOf("webView") > -1) {
      this.isWebView = true;
      //缓存微信小程序传过来的token
      localStorage.setItem("token", this.$route.query.token);
      localStorage.setItem("role", JSON.parse(this.$route.query.role));
    }
    let designSize = 1920; // 设计图尺寸
    let html = document.documentElement;
    let wW = html.offsetWidth; // 窗口宽度
    let rem = (wW * 10) / designSize;
    document.documentElement.style.fontSize = rem + "px";
    window.addEventListener("resize", function () {
      let designSize = 1920; // 设计图尺寸
      let html = document.documentElement;
      let wW = html.offsetWidth; // 窗口宽度
      let rem = (wW * 10) / designSize;
      document.documentElement.style.fontSize = rem + "px";
    });
    this.getUrlData();
  },
  updated() {},
  watch: {
    $route: {
      handler(e) {
        if (e.path.indexOf("home") !== -1) {
          setTimeout(() => {
            this.index = 1;
          }, 200);
        } else if (e.path.indexOf("resources") !== -1) {
          setTimeout(() => {
            this.index = 2;
          }, 200);
        } else if (e.path.indexOf("data") !== -1) {
          setTimeout(() => {
            this.index = 3;
          }, 200);
        }

        // switch (e.name) {
        //   case "home":
        //     setTimeout(() => {
        //       this.index = 1;
        //     }, 200);
        //     break;
        //   case "resources":
        //     setTimeout(() => {
        //       this.index = 2;
        //     }, 200);
        //     break;
        //   case "data":
        //     setTimeout(() => {
        //       this.index = 3;
        //     }, 200);
        //     break;
        // }
      },
      immediate: true,
      deep: true,
      // if (e.fullPath == "/editReport") {
      //   this.index = 4;
      // }
    },
  },
  methods: {
    //导航栏
    navTo(index) {
      // if (index !== this.index) {
      this.index = index;
      localStorage.setItem("index", index);
      switch (index) {
        case 1:
          this.$router.push("/home");
          break;
        case 2:
          this.$router.push({ path: "/resources", query: { parkId: 4 } });
          break;
        case 3:
          this.$router.push("/data");
          break;
      }
      // }
    },

    getUrlData(){
      Api.getIndexLink().then(res => {
        if(res) {
          this.url =  res.data;
        }
      });
    },
/*    getURL(url){
      let strURL = "";
      if(url.substr(0,7).toLowerCase() == "http://" || url.substr(0,8).toLowerCase() == "https://"){
        strURL = url;
      }else{
        strURL = "http://" + url;
      }
      return strURL;
    },*/

    //done:退出
    exit() {
      localStorage.clear();
      localStorage.clear();
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  overflow-x: hidden;
  height: 100%;
  .spin {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: white;
  }
  .header {
    background: #151c51;
    padding: 0px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav {
      height: 100%;
      background: transparent;
      .nav-item {
        display: inline-block;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
        margin: 0 20px;
        cursor: pointer;
      }
      .nav-focus {
        color: #ffffff;
        opacity: 1 !important;
        font-size: 20px;
        font-weight: 700;
        position: relative;
      }
      .nav-focus::after {
        content: "";
        height: 5px;
        width: 60%;
        position: absolute;
        background: white;
        bottom: 1px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .logo {
      display: flex;
      align-items: center;
      .logo-text {
        font-family: Microsoft YaHei UI;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        color: #fff;
        margin-left: 36px;
        position: relative;
      }
      .logo-text::before {
        content: "";
        width: 8px;
        background: white;
        border-radius: 50%;
        height: 8px;
        position: absolute;
        top: 45%;
        left: -18px;
      }
    }
    .user {
      color: white;
      display: flex;
      font-size: 16px;
    }
  }
}
</style>
