import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import "animate.css";
import 'ant-design-vue/dist/antd.css';
import '../src/style/global.css'
import {
  message
} from 'ant-design-vue'
import vueToPdf from 'vue-to-pdf';


// const Cesium = require("cesium/Cesium.js");
// const widgets = require("cesium//Widgets/widgets.css");
Vue.config.productionTip = false
Vue.use(Antd);
Vue.use(vueToPdf);
Vue.prototype.$message = message
// Vue.prototype.Cesium = Cesium;
// Vue.prototype.widgets = widgets;


router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.fullPath.indexOf("webView") == -1) {
    if (to.path === '/login') return next()
    const tokenStr = localStorage.getItem('token')
    // 没有token，强制跳转到登录页
    if (!tokenStr) return next('/login')
  }
  // 从 localStorage 中获取到 保存的 token 值
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
