<template>
  <div class="resources">
    <!-- <div class="spin" v-show="show">
      <a-spin tip="Loading..." size="large"> </a-spin>
    </div> -->
    <router-view></router-view>
    <!-- <router-view></router-view> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      transitionName: "",
      show: false,
    };
  },
  watch: {
    // 使用watch 监听$router的变化
    $route(e) {
      // 如果to索引大于from索引,判断为前进状态,反之则为后退状态
      // if (to.meta.index > from.meta.index) {
      //   // 设置动画名称
      //   this.transitionName = "slide-left";
      // } else {
      //   this.transitionName = "slide-right";
      // }
      // this.show = true
      // setTimeout(()=>{
      //   this.show =false
      // },1000)
    },
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
.resources {
  height: 100%;
  position: relative;
  .spin {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: white;
  }
}
</style>
<style>
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 1000ms;
  position: absolute;
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>