<template>
  <div class="resources webview">
    <a-row style="width:100%;height: 100%">
      <!-- tag:左侧地图 -->
      <a-col style="width:100%;height: 50%;background:#fafafa;" class="left-content">
        <!-- <button @click="next()">切换</button>
        <button @click="callback">返回</button> -->
        <!-- <div class="map-name">
          <a-breadcrumb class="breadcrumb">
            <span slot="separator" style="color: var(--blue-color)">></span>
            <a-breadcrumb-item href="" @click.native="backPark"
              >银星科技园</a-breadcrumb-item
            >
            <a-breadcrumb-item href=""> {{ areaName }} </a-breadcrumb-item>
          </a-breadcrumb>
        </div> -->
        <div style="height: 100%" id="mapContent" :key="key">
          <a-tabs
            default-active-key="1"
            tab-position="top"
            :style="{ height: '44px' }"
            @prevClick="callback"
            @nextClick="callback"
            class="floor-change"
            v-show="tabShow"
          >
            <a-tab-pane v-for="i in 30" :key="i" :tab="`${i}F`">
              {{ i + "F" }}
            </a-tab-pane>
          </a-tabs>
        </div>
      </a-col>
      <!-- tag:右边数据栏 -->
      <a-col 
        :style="{ width:clientWidth }"
        style="
          height: 100vh; 
          position: relative;
          background:#ffffff;
          overflow:hidden;
          border-radius: 16px 16px 0px 0px;
          margin-top:-20px;
          box-shadow: 0px 2px 24px 0 rgba(21, 28, 81, 0.15);
          bottom:0;
        ">
        <div class="spin" v-show="spinShow">
          <a-spin tip="Loading..."> </a-spin>
        </div>
        <Allresources :id="parseInt(aId)" :level="'area'" :areaType="areaType"></Allresources>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Api } from "../../api/api.js";
import "ol/ol.css";
import { Map, View, Overlay, Feature } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorSource, ImageStatic } from "ol/source";
import { Vector as VectorLayer, Image } from "ol/layer";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
// import render3D from "ol-ext/layer/Render3D";
import { Draw, Select, DoubleClickZoom } from "ol/interaction";
import { getCenter, boundingExtent } from "ol/extent";
import { createBox } from "ol/interaction/Draw";
import { click, doubleClick } from "ol/events/condition";
import * as THREE from "three";
import Stats from "three/examples/jsm/libs/stats.module.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import Allresources from "../../components/webview/allResources.vue";
import wx from 'weixin-js-sdk';
var container, stats, controls;
var camera, scene, renderer, light, mesh;
export default {
  components: {
    Allresources,
  },
  data() {
    return {
      measureVisible: false,
      key: 0,
      tabShow: false,
      width: 0,
      height: 0,
      aId: undefined,
      buildingData: {},
      areaData: null,
      areaImage: null,
      areaName: null, // 区域名称
      clientWidth:document.documentElement.clientWidth,
      spinShow:true,//加载中
      areaType:0,//
    };
  },
  created(){
    this.aId = this.$route.query.aId;
  },
  async mounted() {
    let vm = this;
    await vm.getAreaData();
    this.width = document.getElementById("mapContent").clientWidth;
    this.height = document.getElementById("mapContent").clientHeight;
    setTimeout(() => {
      if (vm.aId == 1900) {
        // vm.map3DInit();
      } else {
        vm.mapInit();
      }
    }, 300);
    vm.myTimer = setInterval(function(){
      vm.spinShow = false;//加载完毕
      clearInterval(vm.myTimer)
    },1000)
  },
  methods: {
    keyadd() {
      this.key++;
    },
    // done:地图初始化
    mapInit() {
      let that = this;
      var image = new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({ color: "red", width: 1 }),
      });
      var styles = {
        Point: new Style({
          image: image,
        }),
        LineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiLineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiPoint: new Style({
          image: image,
        }),
        MultiPolygon: new Style({
          stroke: new Stroke({
            color: "black",
            width: 1,
          }),
          fill: new Fill({
            color: "white",
          }),
        }),
        Polygon: new Style({
          // stroke: new Stroke({
          //   color: "blue",
          //   lineDash: [4],
          //   width: 3,
          // }),
          fill: new Fill({
            color: "rgba(0, 0, 255, 0.1)",
          }),
        }),
        GeometryCollection: new Style({
          stroke: new Stroke({
            color: "magenta",
            width: 2,
          }),
          fill: new Fill({
            color: "magenta",
          }),
          image: new CircleStyle({
            radius: 10,
            fill: null,
            stroke: new Stroke({
              color: "magenta",
            }),
          }),
        }),
        Circle: new Style({
          stroke: new Stroke({
            color: "red",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(255,0,0,0.2)",
          }),
        }),
      };

      var styleFunction = function (feature) {
        return styles[feature.getGeometry().getType()];
      };
      var aOBJ = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [1035.059197996234, -224.98082997585396],
                  [927.5696269702046, -380.2435436801183],
                  [913.237684166734, -960.6872272206754],
                  [1228.540425843086, -1018.0149984345576],
                  [1314.5320826639093, -834.0883991233522],
                  [1338.418654003027, -267.9766583862657],
                  [1035.059197996234, -224.98082997585396],
                ],
              ],
            },
            properties: { id: 8 },
          },
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [330.40534349226505, -389.7981722157653],
                  [129.7581442436774, -525.9516288487355],
                  [132.1468013775891, -688.3803139547349],
                  [645.7080851686171, -865.1409418642052],
                  [808.1367702746168, -678.8256854190879],
                  [767.5295989981169, -659.7164283477939],
                  [762.7522847302932, -535.5062573843825],
                  [698.258542114676, -521.1743145809121],
                  [683.9265993112053, -490.1217718400591],
                  [330.40534349226505, -389.7981722157653],
                ],
              ],
            },
            properties: { id: 7 },
          },
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [105.8715729045598, -640.6071712764999],
                  [132.1468013775891, -626.2752284730293],
                  [127.36948710976571, -516.3970003130885],
                  [222.91577246623592, -456.6805719652945],
                  [187.08591545755962, 61.65802609355694],
                  [7.936630414177898, 116.59714017352741],
                  [-474.5721106359973, -26.722287861178074],
                  [-379.025825279527, -705.1009138921172],
                  [-47.002483665792624, -886.6388560694108],
                  [120.20351570803041, -803.0358563824993],
                  [105.8715729045598, -640.6071712764999],
                ],
              ],
            },
            properties: { id: 6 },
          },
        ],
      };

      var obj = that.areaData;
      // console.log(obj);
      if (obj !== null) {
        this.vectorSource = new VectorSource({
          features: new GeoJSON().readFeatures(obj),
          wrapX: false,
          zIndex: 9998,
        });
      } else {
        this.vectorSource = new VectorSource({
          // features: new GeoJSON().readFeatures(obj),
          wrapX: false,
          zIndex: 9998,
        });
      }
      this.vectorLayer = new VectorLayer({
        source: this.vectorSource,
        style: styleFunction,
        zIndex: 9998,
      });
      var center = [500, -600];
      // let width = document.getElementById("mapContent").clientWidth;
      // let height = document.getElementById("mapContent").clientHeight;
      //   var center = transform([500, -600], "EPSG:4326", "EPSG:3857");
      //计算静态地图映射到地图上的范围，图片像素为550*344，保持比例的情况下，把分辨率放大一些
      var extent = [
        center[0] - (1700 * 2) / 2,
        center[1] - (1050 * 2) / 2,
        center[0] + (1700 * 2) / 2,
        center[1] + (1050 * 2) / 2,
      ];
      // var extent = [
      //   center[0] - (1320 * 2) / 2,
      //   center[1] - (685 * 2) / 2,
      //   center[0] + (1320 * 2) / 2,
      //   center[1] + (685 * 2) / 2,
      // ];
      this.map = new Map({
        layers: [this.vectorLayer],
        view: new View({
          center: center,
          extent: extent,
          enableRotation: false,
          //   center: [500, -600],
          zoom: 15, // 定义地图显示层级为2
          // padding: [100, 0, 0, 0],
        }),
        // 让id为map的div作为地图的容器
        target: "mapContent",
      });
      const dblClickInteraction = this.map
        .getInteractions()
        .getArray()
        .find((interaction) => {
          return interaction instanceof DoubleClickZoom;
        });
      this.map.removeInteraction(dblClickInteraction);
      // done:添加底图
      this.map.addLayer(
        new Image({
          source: new ImageStatic({
            // url: "https://minio.yshinfo.com/yinxing/mryeov2eyx6nluj3hgof3utsh56k",
            url: this.areaImage, // 静态地图
            imageExtent: extent, //映射到地图的范围
          }),
        })
      );
      // console.log(this.map);

      // done:图形双击
      let selectClick = new Select({
        // 事件类型
        condition: doubleClick,
        // 点击后的样式
        style: new Style({
          fill: new Fill({
            color: "rgba(99,149,250,0.5)",
          }),
        }),
      });
      selectClick.on("select", function (e) {

      });
      this.map.addInteraction(selectClick);
      //done:图形点击
      var select = new Select({
        style: function (fea, res) {
          return [
            new Style({
              fill: new Fill({
                color: "rgba(99,149,250,0.5)",
              }),
            }),
          ];
        },
      });
      
      select.on("select", function (e) {
        let parkId = that.$route.query.parkId;
        let aId = that.$route.query.aId;
        let bId = e.selected[0].values_.id;
        // this.getFeatures().clear();
        //areaType:0智界，1智谷，2公寓
        if(that.areaType == 2){
          //公寓
          wx.miniProgram.navigateTo(
            {
              url: '/pages/overview/apartment/apartment?id=' + aId + '&level=build'
              + '&parkId=' + parkId + '&index=0' + '&isRooms=true'
            }
          )
        }else if(that.areaType == 1){

          //智谷列表
          wx.miniProgram.navigateTo(
            {
              url: '/pages/overview/zhigu/list/list?parkId='+parkId+'&aId='+aId+'&bId='+bId 
              + '&address=' + that.areaName
            }
          )
        }else {
          wx.miniProgram.navigateTo(
            {
              url: '/pages/overview/viewRoom/room?parkId='+parkId+'&aId='+aId+'&bId='+bId
            }
          )
        }
      });
      //done:弹窗展示
      // var element;
      // var itemCenter;
      // var popup;
      // var popupName;
      // let featureList = this.vectorSource.getFeatures();
      // featureList.forEach((item, index) => {
      //   popupName = "popup" + index;
      //   popup = new Overlay({
      //     element: document.getElementById(popupName),
      //   });
      //   element = popup.element;
      //   that.map.addOverlay(popup);
      //   itemCenter = getCenter(
      //     boundingExtent(item.getGeometry().getCoordinates()[0])
      //   ); // 获取图形中点
      //   itemCenter[1] = itemCenter[1] + 20;
      //   popup.setPosition(itemCenter); // 将弹窗位置设置为图形中点
      //   element.innerHTML =
      //     "<p style='color:white;font-size:20px;'> " + item.values_.id + "</p>";
      // });
      this.map.addInteraction(select);
      //   var r3D = new render3D({ height: 100, defaultHeight: 10 });
      //   vectorLayer.setRender3D(r3D);
    },
  
    //模型点击事件
    onMouseClick(event) {
      var raycaster = new THREE.Raycaster();
      var mouse = new THREE.Vector2();
      //通过鼠标点击的位置计算出raycaster所需要的点的位置，以屏幕中心为原点，值的范围为-1到1.
      var container = document.querySelector("#mapContent");
      let getBoundingClientRect = container.getBoundingClientRect();
      mouse.x =
        ((event.clientX - getBoundingClientRect.left) / container.offsetWidth) *
          2 -
        1;
      mouse.y =
        -(
          (event.clientY - getBoundingClientRect.top) /
          container.offsetHeight
        ) *
          2 +
        1;

      // 通过鼠标点的位置和当前相机的矩阵计算出raycaster
      raycaster.setFromCamera(mouse, camera);

      // 获取raycaster直线和所有模型相交的数组集合
      var intersects = raycaster.intersectObjects(scene.children);
      // var intersects = raycaster.intersectObjects(scene);
      // console.log(intersects)
      // console.log(scene);
      // console.log(intersects[0].object)
      // console.log(intersects[0].object.material);
      // intersects[0].object.material.color.set(0x000000);
      switch (intersects[0].object.name) {
        case "Obj3d66-445063-22-880":
        case "Box231":
          this.videoId = 3;
          this.$emit("showModal", 3);
          break;
        case "Obj3d66-445063-22-879":
        case "Box104":
          this.videoId = 2;
          this.$emit("showModal", 2);
          break;
        case "Obj3d66-445063-22-878":
        case "Box132":
          this.videoId = 1;
          this.$emit("showModal", 1);
          break;
      }
      //将所有的相交的模型的颜色设置为红色，如果只需要将第一个触发事件，那就数组的第一个模型改变颜色即可
      for (var i = 0; i < intersects.length; i++) {
        // intersects[i].object.material.color.set(0x000000);
      }
    },

    onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    },
    animate() {
      requestAnimationFrame(this.animate);
      renderer.render(scene, camera);
      // stats.update();
    },
    resetControls() {
      // console.log("reset____________");
      controls.reset();
    },
    callback() {
      this.key++;
      setTimeout(() => {
        this.mapInit();
      }, 1000);
    },
    next() {
      this.key++;
      setTimeout(() => {
        // this.map3DInit();
      }, 1000);
    },
    backPark() {
      this.$router.push({ path: "/resources", query: { parkId: 4 } });
    },
    // done:查询所有区域
    // async getAreasList(){
    //   const res =
    // }
    // done:获取底图与geojson
    async getAreaData() {
      const res = await Api.getAreaData(this.aId);

      this.areaData = res.data.areaData;
      this.areaImage = res.data.areaImage;
      this.areaName = res.data.areaName;
      this.areaType = res.data.areaType;
      document.title = res.data.areaName;
      // console.log(res,'areaData');
    },
    // done:根据组别ID查询楼栋数据
    async getBuilding(bId) {
      const res = await Api.getBuilding(bId);
      // this.buildingData = res.data;
      // this.measureVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.spin {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  z-index: 9999;
  background: white;
}
.resources {
  height: 100%;
  // overflow: hidden;
  .left-content {
    height: 100%;
    position: relative;
    background: white;
    .map-name {
      height: 54px;
      width: calc(100% - 10px);
      background-image: url("../../assets/index/name_bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 9998;
      left: 50%;
      transform: translateX(-50%);
      top: 5px;
      .ol-control {
        display: none;
      }
      .breadcrumb {
        position: absolute;
        top: 50%;
        left: 18%;
        font-size: 14px;
        color: var(--blue-color);
        transform: translateY(-50%);
        span:last-child a {
          font-family: Microsoft YaHei UI;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0.12em;
          color: #055fcd;
        }
      }
    }
    .floor-change {
      position: absolute;
      width: calc(100% - 60px);
      z-index: 9999;
      left: 50%;
      transform: translateX(-50%);
      top: 84px;
      background: white;
      color: var(--blue-color);
      font-size: 20px !important;
      font-weight: 600;
    }
    .measure-popover {
      position: absolute;
      bottom: 20px;
    }
  }
}
.popover-box {
  padding: 12px;
  width: 432px;
  height: 380px;
  .box-title {
    height: 44px;
  }
}
.box-content {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .text {
    font-size: 16px;
    color: #151a30;
    line-height: 200%;
    margin-right: 20px;
  }
}
</style>
<style lang="scss">
.webview {
  .breadcrumb {
    span:last-child a {
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.12em;
      color: #055fcd !important;
    }
  }
  .ol-control {
    display: none;
  }
  #mapContent {
    .ant-tabs-nav .ant-tabs-tab {
      background: #f0f5ff;
      border-radius: 6px;
      width: 54px;
    }
    .ant-tabs-tab-prev-icon,
    .ant-tabs-tab-next-icon {
      line-height: 40px;
      width: 100%;
      background-color: #f0f5ff;
      /* margin-right: 20px !important; */
      color: var(--blue-color);
    }
    .ant-tabs-bar {
      border: none !important;
    }
    .ant-carousel .slick-dots {
      position: fixed;
      bottom: 30px;
    }
    .ant-carousel .slick-dots li {
      width:10px;
      height:10px;
      border:1px solid #6395FA;
      border-radius: 100%;
    }
    .ant-carousel .slick-dots li.slick-active button {
      width:10px;
      height:10px;
      border:1px solid #6395FA;
      margin-top: -1px;
      margin-left: -1px;
      background:#6395FA;
      border-radius: 100%;
    }
  }
}
</style>