<template>
  <div class="table">
    <!-- <a-button @click="updateProjectStatistics">更新</a-button> -->
    <!-- <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="false"
      align="center"
    >
      <template slot="name" slot-scope="text">
        <a>{{ text }}</a>
      </template>
      <template slot="title" slot-scope="currentPageData">
        <div style="text-align: center">各项目汇总统计</div>
      </template>
    </a-table>
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="false"
      align="center"
    >
      <template slot="name" slot-scope="text">
        <a>{{ text }}</a>
      </template>
    </a-table> -->
    <table class="formdata">
      <caption>
<!--        各项目汇总统计（{{
          startDate + "-" + endDate
        }}）-->
        本月各项目汇总统计
      </caption>
      <tr class="t-header">
        <th>办公&厂房项目</th>
        <th
          scope="col"
          v-for="(item, index) in obj.work.header"
          :key="'work' + index"
          :style="item.edit == 1 ? 'color:#FF6636' : ''"
        >
          {{ item.name }}
        </th>
      </tr>
      <tr
        v-for="(item, index) in obj.work.data"
        :key="'data' + index"
        class="t-body"
      >
        <th scope="row">{{ item.name }}</th>
        <td v-for="(data, num) in item.data" :key="num">
          <div v-if="num == 1 || num == 2 || num == 3">
            <input
              type="text"
              v-model="obj.work.data[index].data[num]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ data }}</div>
        </td>
      </tr>
      <tr class="total-style">
        <th scope="col">{{ obj.work.total.name }}</th>
        <td
          v-for="(item, index) in obj.work.total.data"
          :key="'work-total' + index"
        >
          {{ item }}
        </td>
      </tr>
      <tr class="t-header">
        <th>公寓&宿舍项目</th>
        <th
          scope="col"
          v-for="(item, index) in obj.room.header"
          :key="'key' + index"
          :style="item.edit == 1 ? 'color:#FF6636' : ''"
        >
          {{ item.name }}
        </th>
      </tr>
      <tr v-for="(item, index) in obj.room.data" :key="index" class="t-body">
        <th scope="row">{{ item.name }}</th>
        <td v-for="(data, num) in item.data" :key="num">
          <div v-if="num == 1 || num == 2 || num == 3">
            <input
              type="text"
              v-model="obj.room.data[index].data[num]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ data }}</div>
        </td>
      </tr>
      <tr class="total-style">
        <th scope="col">{{ obj.room.total.name }}</th>
        <td v-for="(item, index) in obj.room.total.data" :key="index">
          {{ item }}
        </td>
      </tr>
    </table>

    <!-- 厂房/办公/商业招商周进度表（2.14-2.20） -->
    <table class="formdata">
      <caption>
        本月厂房/办公/商业招商进度表<!--（{{
          startDate + "-" + endDate
        }}）-->
      </caption>
      <tr class="t-header">
        <th>类别</th>
        <th
          scope="col"
          v-for="(item, index) in process.header"
          :key="index"
          :style="item.edit == 1 ? 'color:#FF6636' : ''"
        >
          {{ item.name }}
        </th>
      </tr>
      <tr class="t-body">
        <th scope="row" rowspan="2">厂房</th>
        <th>1</th>
        <th>北区厂房</th>
        <th>{{ process.data[0].name }}</th>
        <td v-for="(item, index) in process.data[0].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[0].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th>2</th>
        <th>竹园厂区</th>
        <th>{{ process.data[1].name }}</th>
        <td v-for="(item, index) in process.data[1].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[1].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="total-style">
        <th scope="row" colspan="4">合计:</th>
        <td v-for="(item, index) in process.total[0]" :key="index">
          <div>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th scope="row" rowspan="19">办公</th>
      </tr>
      <tr class="t-body">
        <th scope="col" rowspan="1">1</th>
        <th scope="col" rowspan="1">银星科技大厦</th>
        <th>{{ process.data[2].name }}</th>
        <td v-for="(item, index) in process.data[2].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[2].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>

      <!-- 138项目 -->
      <tr class="t-body">
        <th scope="col" rowspan="5">2</th>
        <th scope="col" rowspan="5">138项目</th>
      </tr>
      <tr class="t-body" rowspan="1">
        <th>{{ process.data[3].name }}</th>
        <td v-for="(item, index) in process.data[3].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[3].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body" rowspan="1">
        <th>{{ process.data[4].name }}</th>
        <td v-for="(item, index) in process.data[4].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[4].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body" rowspan="1">
        <th>{{ process.data[5].name }}</th>
        <td v-for="(item, index) in process.data[5].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[5].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body" rowspan="1">
        <th>{{ process.data[6].name }}</th>
        <td v-for="(item, index) in process.data[6].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[6].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="total-style" rowspan="1">
        <th scope="col" colspan="3">小计：</th>
        <td v-for="(item, index) in process.total[1]" :key="index">
          <div>{{ item }}</div>
        </td>
      </tr>

      <!-- 134项目 -->
      <tr class="t-body">
        <th scope="col" rowspan="4">3</th>
        <th scope="col" rowspan="4">134项目</th>
      </tr>
      <tr class="t-body" rowspan="1">
        <th>{{ process.data[7].name }}</th>
        <td v-for="(item, index) in process.data[7].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[7].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body" rowspan="1">
        <th>{{ process.data[8].name }}</th>
        <td v-for="(item, index) in process.data[8].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[8].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body" rowspan="1">
        <th>{{ process.data[9].name }}</th>
        <td v-for="(item, index) in process.data[9].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[9].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="total-style" rowspan="1">
        <th scope="col" colspan="3">小计：</th>
        <td v-for="(item, index) in process.total[2]" :key="index">
          <div>{{ item }}</div>
        </td>
      </tr>

      <!-- 143项目 -->
      <tr class="t-body">
        <th scope="col" rowspan="5">4</th>
        <th scope="col" rowspan="5">143项目</th>
      </tr>
      <tr class="t-body" rowspan="1">
        <th>{{ process.data[10].name }}</th>
        <td v-for="(item, index) in process.data[10].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[10].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body" rowspan="1">
        <th>{{ process.data[11].name }}</th>
        <td v-for="(item, index) in process.data[11].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[11].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body" rowspan="1">
        <th>{{ process.data[12].name }}</th>
        <td v-for="(item, index) in process.data[12].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[12].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body" rowspan="1">
        <th>{{ process.data[13].name }}</th>
        <td v-for="(item, index) in process.data[13].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[13].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="total-style" rowspan="1">
        <th scope="col" colspan="3">小计：</th>
        <td v-for="(item, index) in process.total[3]" :key="index">
          <div>{{ item }}</div>
        </td>
      </tr>
      <tr class="total-style">
        <th scope="row" colspan="4">合计:</th>
        <td v-for="(item, index) in process.total[4]" :key="index">
          <div>{{ item }}</div>
        </td>
      </tr>
      <!-- 商业 -->
      <tr class="t-body">
        <th scope="row" rowspan="8">商业</th>
      </tr>
      <tr class="t-body">
        <th scope="col" rowspan="1">1</th>
        <th scope="col" rowspan="1">富上佳商业</th>
        <th>{{ process.data[14].name }}</th>
        <td v-for="(item, index) in process.data[14].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[14].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th scope="col" rowspan="1">2</th>
        <th scope="col" rowspan="2">沿河商业</th>
        <th>{{ process.data[15].name }}</th>
        <td v-for="(item, index) in process.data[15].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[15].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th scope="col" rowspan="1">3</th>
        <!-- <th scope="col" rowspan="1">富上佳商业</th> -->
        <th>{{ process.data[16].name }}</th>
        <td v-for="(item, index) in process.data[16].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[16].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th scope="col" rowspan="1">4</th>
        <th scope="col" rowspan="1">电力商业</th>
        <th>{{ process.data[17].name }}</th>
        <td v-for="(item, index) in process.data[17].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[17].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th scope="col" rowspan="1">5</th>
        <th scope="col" rowspan="2">134商业</th>
        <th>{{ process.data[18].name }}</th>
        <td v-for="(item, index) in process.data[18].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[18].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th scope="col" rowspan="1">6</th>
<!--        <th scope="col" rowspan="1">134商业</th>-->
        <th>{{ process.data[19].name }}</th>
        <td v-for="(item, index) in process.data[19].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="process.data[19].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th scope="col" rowspan="1">7</th>
        <th scope="col" rowspan="1">143商业</th>
        <th>{{ process.data[20].name }}</th>
        <td v-for="(item, index) in process.data[20].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
                type="text"
                v-model="process.data[20].data[index]"
                @input="input"
                @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>



      <tr class="total-style">
        <th scope="row" colspan="4">合计:</th>
        <td v-for="(item, index) in process.total[5]" :key="index">
          <div>{{ item }}</div>
        </td>
      </tr>
    </table>

    <!-- 公寓/宿舍招商周进度表（2.14-2.20） -->
    <table class="formdata">
      <caption>
        本月公寓/宿舍招商进度表<!--（{{
          startDate + "-" + endDate
        }}）-->
      </caption>
      <tr class="t-header">
        <th>类别</th>
        <th
          scope="col"
          v-for="(item, index) in residence.header"
          :key="index"
          :style="item.edit == 1 ? 'color:#FF6636' : ''"
        >
          {{ item.name }}
        </th>
      </tr>
      <tr class="t-body">
        <th scope="row" rowspan="7">公寓</th>
      </tr>
      <tr class="t-body">
        <th>1</th>
        <th>138公寓</th>
        <th>{{ residence.data[0].name }}</th>
        <td v-for="(item, index) in residence.data[0].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="residence.data[0].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th>2</th>
        <th>电力公寓</th>
        <th>{{ residence.data[1].name }}</th>
        <td v-for="(item, index) in residence.data[1].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="residence.data[1].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th>3</th>
        <th>银星科技大厦公寓</th>
        <th>{{ residence.data[2].name }}</th>
        <td v-for="(item, index) in residence.data[2].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="residence.data[2].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th>4</th>
        <th>143公寓</th>
        <th>{{ residence.data[3].name }}</th>
        <td v-for="(item, index) in residence.data[3].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="residence.data[3].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th>5</th>
        <th>竹园公寓</th>
        <th>{{ residence.data[4].name }}</th>
        <td v-for="(item, index) in residence.data[4].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="residence.data[4].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th>6</th>
        <th>施乐D栋公寓</th>
        <th>{{ residence.data[5].name }}</th>
        <td v-for="(item, index) in residence.data[5].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="residence.data[5].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="total-style">
        <th colspan="4">合计:</th>
        <td v-for="(item, index) in process.total[6]" :key="index">
          <div>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th scope="row" rowspan="10">宿舍</th>
      </tr>
      <tr class="t-body">
        <th rowspan="3">1</th>
        <th rowspan="3">富上佳宿舍</th>
      </tr>
      <tr class="t-body">
        <th>{{ residence.data[6].name }}</th>
        <td v-for="(item, index) in residence.data[6].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="residence.data[6].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th>{{ residence.data[7].name }}</th>
        <td v-for="(item, index) in residence.data[7].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="residence.data[7].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th rowspan="3">2</th>
        <th rowspan="3">施乐宿舍</th>
      </tr>
      <tr class="t-body">
        <th>{{ residence.data[8].name }}</th>
        <td v-for="(item, index) in residence.data[8].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="residence.data[8].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th>{{ residence.data[9].name }}</th>
        <td v-for="(item, index) in residence.data[9].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="residence.data[9].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th rowspan="3">3</th>
        <th rowspan="3">沿河宿舍</th>
      </tr>
      <tr class="t-body">
        <th>{{ residence.data[10].name }}</th>
        <td v-for="(item, index) in residence.data[10].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="residence.data[10].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="t-body">
        <th>{{ residence.data[11].name }}</th>
        <td v-for="(item, index) in residence.data[11].data" :key="index">
          <div v-if="index == 2 || index == 3 || index == 4">
            <input
              type="text"
              v-model="residence.data[11].data[index]"
              @input="input"
              @change="change"
            />
          </div>
          <div v-else>{{ item }}</div>
        </td>
      </tr>
      <tr class="total-style">
        <th colspan="4">合计:</th>
        <td v-for="(item, index) in process.total[7]" :key="index">
          <div>{{ item }}</div>
        </td>
      </tr>
    </table>

    <!-- 回款情况明细表 -->
    <table class="formdata">
      <caption>
        回款情况明细表<!--（{{
          startDate + "-" + endDate
        }}）-->
      </caption>
      <tr class="t-header">
          <th colspan="1">
          </th>
        <template v-for="item in receiptsList.header2">
          <th v-if="item.name != null" colspan="3">
            <div v-if="item.name">{{item.name}}</div>
          </th>
        </template>
<!--        <th colspan="1">
        </th>
        <th colspan="1">
        </th>-->
      <tr class="t-header">
        <template v-for="item in receiptsList.header">
          <th>
            <div>{{item.name}}</div>
            </th>
        </template>
      </tr>

      <template v-for="(item,index) in receiptsList.data">
        <tr :class="index == receiptsList.data.length-1 && 'total-style'">
        <td :style="{fontWeight:700}">{{item.areaName}}</td>
        <td>{{item.currentMonthReceivable}}</td>
        <td>{{item.lastMonthReceivable}}</td>
        <td>{{item.totalReceivable}}</td>
        <td>{{item.currentMonthReceipt}}</td>
        <td>{{item.lastMonthReceipt}}</td>
        <td>{{item.totalReceipt}}</td>
        <td>{{item.currentMonthArrears}}</td>
        <td>{{item.lastMonthArrears}}</td>
        <td>{{item.totalArrears}}</td>
        <td>{{(item.monthCollectionRate*100).toFixed(2)+"%"}}</td>
        <td>{{(item.historyCollectionRate*100).toFixed(2)+"%"}}</td>

      </tr></template>
    </table>



  </div>
</template>

<script>
import { Api } from "../api/api.js";
import moment from "moment";
export default {
  data() {
    return {
      obj: {
        work: {
          data: [],
          total: {},
          header: [
            { name: "总面积（㎡）", edit: 0 },
            { name: "本月出租（㎡）", edit: 1 },
            { name: "本月退租（㎡）", edit: 1 },
            { name: "本月新租（㎡）", edit: 1 },
            { name: "空置面积（㎡）", edit: 0 },
            { name: "出租率（%）", edit: 0 },
          ],
        },

        room: {
          data: [],
          total: {},
          header: [
            { name: "总套数（间）", edit: 0 },
            { name: "本月出租（间）", edit: 1 },
            { name: "本月退租（间）", edit: 1 },
            { name: "本月新租（间）", edit: 1 },
            { name: "空置（间）", edit: 0 },
            { name: "出租率（%）", edit: 0 },
          ],
        },
      },
      process: {
        //   header:['序号','项目/区域','栋别/楼层','可出租面积（㎡）','已出租（㎡）','本月出租（㎡）','本月退租（㎡）','本月新租（㎡）','剩余可租（㎡）','出租率']
        header: [
          { name: "序号", edit: 0 },
          { name: "项目/区域", edit: 0 },
          { name: "栋别/楼层", edit: 0 },
          { name: "可出租面积（㎡）", edit: 0 },
          { name: "已出租（㎡）", edit: 0 },
          { name: "本月出租（㎡）", edit: 1 },
          { name: "本月退租（㎡）", edit: 1 },
          { name: "本月新租（㎡）", edit: 1 },
          { name: "剩余可租（㎡）", edit: 0 },
          { name: "出租率（%）", edit: 0 },
        ],

        data: [],
        total: [],
      },
      residence: {
        header: [
          { name: "序号", edit: 0 },
          { name: "项目/区域", edit: 0 },
          { name: "栋别/楼层", edit: 0 },
          { name: "可出租间数（间）", edit: 0 },
          { name: "已出租（间）", edit: 0 },
          { name: "本月出租（间）", edit: 1 },
          { name: "本月退租（间）", edit: 1 },
          { name: "本月新租（间）", edit: 1 },
          { name: "剩余可租（间）", edit: 0 },
          { name: "出租率（%）", edit: 0 },
        ],
        data: [],
      },
      receiptsList: {
        header: [
          { name: "管理区", edit: 0 },
          { name: "本月应收", edit: 0 },
          { name: "往月应收", edit: 0 },
          { name: "应收合计", edit: 0 },
          { name: "实收本月", edit: 0 },
          { name: "实收往月", edit: 0 },
          { name: "实收合计", edit: 0 },
          { name: "本月欠款", edit: 0 },
          { name: "往月欠款", edit: 0 },
          { name: "欠款合计", edit: 0 },
          { name: "本月收缴率", edit: 0 },
          { name: "累计收缴率", edit: 0 },
        ],
        header2: [
          {},
          { name: "应收费用"},
          { name: "实收费用"},
          { name: "欠款费用"},
          { name: "收缴率"}
        ],
        data: [],
      },

      investKey: 0,
      startDate: undefined,
      endDate: undefined,
    };
  },
  mounted() {
    let weekOfday = parseInt(moment().format("d"));
    weekOfday = weekOfday + -this.investKey * 7;
    this.startDate = moment()
      .subtract(weekOfday - 1, "days")
      .format("M.DD"); // 周一日期

    this.endDate = moment()
      .add(7 - weekOfday, "days")
      .format("M.DD"); // 周日日期
    this.getProjectStatistics();
    this.getInvestmentProgresses();
    this.getReceivablesAndActualReceiptsList()
  },
  methods: {
    change(e) {
      e._value = e.target.value;
      e.srcElement._value = e.target.value;
    },
    input(e) {
      //   this.data = e.data;
      //   console.log(this.data,e.target.value);
    },
    //根据园区ID查询各项目统计表
    async getProjectStatistics() {
      const res = await Api.getProjectStatistics(4);
      this.obj.work.data = res.data.work.data;
      this.obj.work.total = res.data.work.total;
      this.obj.room.data = res.data.room.data;
      this.obj.room.total = res.data.room.total;
      // console.log(this.obj);
    },
    //保存项目统计表
    async updateProjectStatistics() {
      // console.log(this.obj);
      var a = this.obj.work.data;
      var b = this.obj.room.data;
      var postObj = a.concat(b);
      //   const res = await Api.updateProjectStatistics(postObj);
      Api.updateProjectStatistics(postObj).then((res) => {
        if (res.success == "success") {
          this.getProjectStatistics();
        }
      });
    },

    async getReceivablesAndActualReceiptsList(){
     // console.log('getReceivablesAndActualReceiptsList')
     const res =  await Api.getReceivablesAndActualReceiptsList()
      this.receiptsList.data = res.data
     // console.log(this.receiptsList.data)
    },
    //查询进度表数据
    async getInvestmentProgresses() {
      const res = await Api.getInvestmentProgresses(4);
      const obj = res.data.report;
      var arr1 = [];
      var arr2 = [];
      obj.forEach((item, index) => {
        if (index < 21) {
          arr1.push(item);
        } else {
          arr2.push(item);
        }
      });
      this.process.data = arr1;
      this.residence.data = arr2;
      this.process.total = res.data.total;
      // console.log(this.process, this.residence);
    },
    //保存进度表
    updateInvestment() {
      // const res = await Api.getInvestmentProgresses(4);
      var a = this.process.data;
      var b = this.residence.data;
      var postObj = a.concat(b);
      // console.log(this.process, this.residence);
      Api.updateInvestment(postObj).then((res) => {
        if (res.success == "success") {
          this.getInvestmentProgresses();
        }
      });
    },
    async save() {
      await this.updateProjectStatistics();
      await this.updateInvestment();
      this.$message.success("保存成功！");
    },
    refresh() {
      Api.refreshInvestment(4).then(async (res) => {
        if (res.success == "success") {
          await this.getInvestmentProgresses();
        }
      });
      Api.refreshRroject(4).then(async (res) => {
        if (res.success == "success") {
          await this.getProjectStatistics();
          this.$message.success("刷新成功！");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
table.formdata {
  border: 1px solid #151c51;
  border-collapse: collapse;
  font-family: Arial;
  width: 100%;
  margin-bottom: 40px;
}
table.formdata caption {
  /*标题*/
  text-align: center;
  padding: 12px 0;
  caption-side: top;
  color: #151c51;
  font-weight: bold;
  font-size: 2.4rem;
  border-top: 1px solid #151c51;
  border-left: 1px solid #151c51;
  border-right: 1px solid #151c51;
}
table.formdata .t-header th {
  /*行列标题*/
  border: 1px solid #151c51;
  background-color: rgba($color: #ff6636, $alpha: 0.15);
  color: #151c51;
  text-align: left;
  font-weight: 800;
  //   font-family: 'Microsoft YaHei UI';
  padding: 6px 8px 6px 6px;
  margin: 0px;
  text-align: center;
  font-family: "SimHei";
  font-size: 1.8rem;
}
table.formdata .t-body th {
  /*行列标题*/
  border: 1px solid #151c51;
  //   background-color: rgba($color: #ff6636, $alpha: 0.15);
  color: #151c51;
  text-align: left;
  font-weight: normal;
  padding: 6px 8px 6px 6px;
  margin: 0px;
  text-align: center;
  font-weight: 600;
  font-family: "system-ui";
  font-size: 1.8rem;
}
table.formdata .total-style th {
  /*行列标题*/
  border: 1px solid #151c51;
  background-color: rgba(255, 102, 54, 0.5);
  color: #151c51;
  text-align: left;
  font-weight: normal;
  padding: 6px 8px 6px 6px;
  margin: 0px;
  text-align: center;
  font-weight: 800;
  font-family: "SimHei";
  font-size: 1.8rem;
}
table.formdata .total-style td {
  /*行列标题*/
  border: 1px solid #151c51;
  background-color: rgba(255, 102, 54, 0.5);
  color: #151c51;
  text-align: left;
  font-weight: normal;
  padding: 6px 8px 6px 6px;
  margin: 0px;
  text-align: center;
  font-weight: 800;
  font-family: "SimHei";
  font-size: 1.8rem;
}
table.formdata td {
  /*表格行*/
  margin: 0px;
  padding: 0px;
  text-align: center;
  border: 1px solid #151c51; /* 单元格边框 */
  height: 37px;
  color: #151c51;
  font-size: 1.8rem;
  //   min-width: 150px;
  //   font-family: "SimHei"
  min-width: 15rem;
  max-width: 10%;
}
table.formdata td.total-style {
  background-color: rgba($color: #ff6636, $alpha: 0.15);
}
table.formdata input {
  /*可输入区域样式*/
  //   width: 100%;
  //   padding: 1px 3px 1px 3px;
  margin: 0px;
  border: none; /* 输入框不要边框 */
  outline: none;
  text-align: center;
  color: #151c51;
  font-size: 1.8rem;
  max-width: 18rem;
}
</style>
<style lang="scss">
</style>
