<template>
  <div class="all">
    <div class="right-content flex-column">
      <!-- tag:资源面积 -->
      <div class="resources-measure box-style" :class="level == 'park' || level == 'area' ? 'pointer' : ''">
        <div class="title">资源面积（m²）</div>
        <a-row>
          <a-col :span="8">
            <canvas id="canvas0" width="210" height="210" style="background: transparent; width: 100%" @click="measureVisibleShow(1)"></canvas>
          </a-col>
          <a-col :span="8">
            <canvas id="canvas1" width="210" height="210" style="background: transparent; width: 100%" @click="measureVisibleShow(2)"></canvas>
          </a-col>
          <a-col :span="8">
            <canvas id="canvas2" width="210" height="210" style="background: transparent; width: 100%" @click="measureVisibleShow(3)"></canvas>
          </a-col>
        </a-row>
        <a-row v-if="parkData">
          <a-col :span="8" @click="measureVisibleShow(4)">
            <div class="num" style="color: var(--blue-color)">
              {{
                parkData.rentedArea ?
                parseInt(parkData.rentedArea)
                  .toFixed(0)
                  .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") :
                  0
              }}
            </div>
            <div class="name">已出租</div>
            <div class="line blue"></div>
          </a-col>
          <a-col :span="8" @click="measureVisibleShow(5)">
            <div class="num" style="color: var(--green-color)">
              {{
                parkData.selfArea ?
                parseInt(parkData.selfArea)
                  .toFixed(0)
                  .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") :
                  0
              }}
            </div>
            <div class="name">自用</div>
            <div class="line green" style=""></div>
          </a-col>
          <a-col :span="8" @click="measureVisibleShow(7)">
            <div class="num" style="color: var(--yellow-color)">
              {{
                parkData.monthSurrenderTenancyArea ?
                parseInt(parkData.monthSurrenderTenancyArea)
                  .toFixed(0)
                  .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") :
                  0
              }}
            </div>
            <div class="name">本月度退租</div>
            <div class="line yellow"></div>
          </a-col>
        </a-row>
        <a-row class="bottom-box" v-if="parkData">
          <a-col :span="8" @click="measureVisibleShow(6)">
            <div class="num" style="color: var(--blue-color)">
              {{
                parkData.availableArea ?
                parseInt(parkData.availableArea)
                  .toFixed(0)
                  .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") :
                  0
              }}
            </div>
            <div class="name">空置</div>
            <div class="line blue"></div>
          </a-col>
          <a-col :span="8" @click="measureVisibleShow(9)">
            <div class="num" style="color: var(--green-color)">
              {{
                parkData.newRent ?
                parseInt(parkData.newRent)
                  .toFixed(0)
                  .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") :
                  0
              }}
            </div>
            <div class="name">本月新租</div>
            <div class="line green"></div>
          </a-col>
          <a-col :span="8" @click="measureVisibleShow(8)">
            <div class="num" style="color: var(--yellow-color)">
              {{
                parkData.yearSurrenderTenancyArea ?
                parseInt(parkData.yearSurrenderTenancyArea)
                  .toFixed(0)
                  .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") :
                  0
              }}
            </div>
            <div class="name">本年度退租</div>
            <div class="line yellow"></div>
          </a-col>
        </a-row>
        <a-modal
          v-model="measureVisible"
          :title="false"
          :footer="null"
          :centered="true"
          :forceRender="true"
          :maskStyle="{ background: 'rgba(16, 36, 87, 0.65)' }"
          width="800px"
          :closable="false"
          :zIndex="9999"
        >
          <div>
            <div class="title-background-long flex-row modal-title">
              <div class="" style="margin-left: 20px">资源详情</div>
              <a-icon type="close" class="pointer" style="color: #6395fa" @click="measureVisible = false" />
            </div>
            <div class="measure-box">
              <a-spin tip="Loading..." style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" v-show="measureSpin"> </a-spin>
              <a-tabs
                default-active-key="1"
                v-model="activeKey"
                :tabBarStyle="{
                  color: 'black',
                  fontSize: '16px',
                  fontWeight: '700',
                }"
              >
                <a-tab-pane :key="0" tab="总览">
                  <div class="measure-overview" v-if="groupSpaceData">
                    <div>
                      总面积：<span>{{ groupSpaceData.allSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                      >m²
                    </div>
                    <div style="margin-top: 12px">
                      <img src="../assets/data/workshop.png" alt="" style="width: 70px" />
                      <div class="flex-row measure-overview-row">
                        <div>
                          总面积：<span style="color: #6395fa">{{ groupSpaceData.workShop.allWorkSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >m²
                        </div>
                        <div>
                          已租面积：<span style="color: #6395fa">{{ groupSpaceData.workShop.rentedWorkSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >m²
                        </div>
                        <div v-if="groupSpaceData.workShop.rentedPercentage == '无出租面积'">出租率：<span style="color: #6395fa">无出租面积</span></div>
                        <div v-else>
                          出租率：<span style="color: #6395fa">{{ (groupSpaceData.workShop.rentedPercentage * 100).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >%
                        </div>
                        <div>
                          待租面积：<span style="color: #6395fa">{{ groupSpaceData.workShop.waitRentedWorkShopSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >m²
                        </div>
                      </div>
                    </div>
                    <div style="margin-top: 12px">
                      <img src="../assets/data/office.png" alt="" style="width: 70px" />
                      <div class="flex-row measure-overview-row">
                        <div>
                          总面积：<span style="color: #79dfb7">{{ groupSpaceData.office.allOfficeSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >m²
                        </div>
                        <div>
                          已租面积：<span style="color: #79dfb7">{{ groupSpaceData.office.rentedOfficeSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >m²
                        </div>
                        <div v-if="groupSpaceData.office.rentedPercentage == '无出租面积'">出租率：<span style="color: #79dfb7">无出租面积</span></div>
                        <div v-else>
                          出租率：<span style="color: #79dfb7">{{ (groupSpaceData.office.rentedPercentage * 100).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >%
                        </div>
                        <div>
                          待租面积：<span style="color: #79dfb7">{{ groupSpaceData.office.waitRentedOfficeSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >m²
                        </div>
                      </div>
                    </div>
                    <div style="margin-top: 12px">
                      <img src="../assets/data/business.png" alt="" style="width: 70px" />
                      <div class="flex-row measure-overview-row">
                        <div>
                          总面积：<span style="color: #ffca14">{{ groupSpaceData.sale.allSaleSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >m²
                        </div>
                        <div>
                          已租面积：<span style="color: #ffca14">{{ groupSpaceData.sale.rentedSaleSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >m²
                        </div>
                        <div v-if="groupSpaceData.sale.rentedPercentage == '无出租面积'">出租率：<span style="color: #ffca14">无出租面积</span></div>
                        <div v-else>
                          出租率：<span style="color: #ffca14">{{ (groupSpaceData.sale.rentedPercentage * 100).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >%
                        </div>
                        <div>
                          待租面积：<span style="color: #ffca14">{{ groupSpaceData.sale.waitRentedSaleSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >m²
                        </div>
                      </div>
                    </div>
                    <div style="margin-top: 12px">
                      <img src="../assets/data/apartment.png" alt="" style="width: 70px" />
                      <div class="flex-row measure-overview-row">
                        <div>
                          总面积：<span style="color: #fb9879">{{ groupSpaceData.flat.allFlatSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >套
                        </div>
                        <div>
                          已租面积：<span style="color: #fb9879">{{ groupSpaceData.flat.rentedFlatSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >套
                        </div>
                        <div v-if="groupSpaceData.flat.rentedPercentage == '无出租面积'">出租率：<span style="color: #fb9879">无出租面积</span></div>
                        <div v-else>
                          出租率：<span style="color: #fb9879">{{ (groupSpaceData.flat.rentedPercentage * 100).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >%
                        </div>

                        <div>
                          待租面积：<span style="color: #fb9879">{{ groupSpaceData.flat.waitRentedFlatSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >套
                        </div>
                      </div>
                    </div>
                    <div style="margin-top: 12px">
                      <img src="../assets/data/dormitory.png" alt="" style="width: 70px" />
                      <div class="flex-row measure-overview-row">
                        <div>
                          总面积：<span style="color: #6170f4">{{ groupSpaceData.dorm.allDormSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span>
                          套
                        </div>
                        <div>
                          已租面积：<span style="color: #6170f4">{{ groupSpaceData.dorm.rentedDormSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span>
                          套
                        </div>
                        <div v-if="groupSpaceData.dorm.rentedPercentage == '无出租面积'">出租率：<span style="color: #6170f4">无出租面积</span></div>
                        <div v-else>
                          出租率：<span style="color: #6170f4">{{ (groupSpaceData.dorm.rentedPercentage * 100).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >%
                        </div>
                        <div>
                          待租面积：<span style="color: #6170f4">{{ groupSpaceData.dorm.waitRentedDormSpace.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                          >套
                        </div>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="1" tab="总面积">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in measureData[0]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.space.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>m²</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="2" tab=" 出租率" force-render>
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in measureData[1]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ (item.rentedPercentage * 100).toFixed(3) }}</span
                        ><span>%</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="3" tab="空置率">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in measureData[2]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ (item.availablePercentage * 100).toFixed(3) }}</span
                        ><span>%</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="4" tab="已出租">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in measureData[3]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.rentedSpace.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>m²</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="5" tab="自用">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in measureData[4]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.selfSpace.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>m²</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="6" tab="空置面积">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in measureData[5]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.availableSpace.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>m²</span>
                      </div>
                    </div>
                  </div></a-tab-pane
                >
                <a-tab-pane :key="7" tab="本月度退租">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in measureData[6]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.monthSurrenderTenancy.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>m²</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="8" tab="本年度退租">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in measureData[7]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.yearSurrenderTenancy.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>m²</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="9" tab="本月新租">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in measureData[8]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.newRent.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>m²</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
        </a-modal>
      </div>
      <!-- tag:房间数量 -->
      <div class="resources-measure box-style pointer" v-if="areaType == 2">
        <div class="title">房间数量（共计：{{ parkData.allRoomNumber }}套）</div>
        <a-row style="margin: 16px 0px">
          <a-col :span="8" @click="roomVisibleShow(4)">
            <div class="num" style="color: var(--blue-color)">
              {{
                parkData.rentedRoomNumber ?
                parseInt(parkData.rentedRoomNumber)
                  .toFixed(0)
                  .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") :
                  0
              }}
            </div>
            <div class="name">已出租</div>
            <div class="line blue"></div>
          </a-col>
          <a-col :span="8" @click="roomVisibleShow(5)">
            <div class="num" style="color: var(--green-color)">
              {{
                parkData.selfRoomNumber ?
                parseInt(parkData.selfRoomNumber)
                  .toFixed(0)
                  .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") :
                  0
              }}
            </div>
            <div class="name">自用</div>
            <div class="line green" style=""></div>
          </a-col>
          <a-col :span="8" @click="roomVisibleShow(7)">
            <div class="num" style="color: var(--yellow-color)">
              {{
                parkData.monthSurrenderTenancyRoomNumber ?
                parseInt(parkData.monthSurrenderTenancyRoomNumber)
                  .toFixed(0)
                  .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") :
                  0
              }}
            </div>
            <div class="name">本月度退租</div>
            <div class="line yellow"></div>
          </a-col>
        </a-row>
        <a-row style="margin: 16px 0px">
          <a-col :span="8" @click="roomVisibleShow(6)">
            <div class="num" style="color: var(--blue-color)">
              {{
                parkData.availableRoomNumber ?
                parseInt(parkData.availableRoomNumber)
                  .toFixed(0)
                  .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") :
                  0
              }}
            </div>
            <div class="name">空置</div>
            <div class="line blue"></div>
          </a-col>
          <a-col :span="8" @click="roomVisibleShow(9)">
            <div class="num" style="color: var(--green-color)">
              {{
                parkData.newRentRoomNumber ?
                parseInt(parkData.newRentRoomNumber)
                  .toFixed(0)
                  .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") :
                  0
              }}
            </div>
            <div class="name">本月新租</div>
            <div class="line green"></div>
          </a-col>
          <a-col :span="8" @click="roomVisibleShow(8)">
            <div class="num" style="color: var(--yellow-color)">
              {{
                parkData.yearSurrenderTenancyRoomNumber ?
                parseInt(parkData.yearSurrenderTenancyRoomNumber)
                  .toFixed(0)
                  .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") :
                  0
              }}
            </div>
            <div class="name">本年度退租</div>
            <div class="line yellow"></div>
          </a-col>
        </a-row>
        <a-modal
          v-model="roomNumberVisible"
          :title="false"
          :footer="null"
          :centered="true"
          :forceRender="true"
          :maskStyle="{ background: 'rgba(16, 36, 87, 0.65)' }"
          width="800px"
          :closable="false"
          :zIndex="9999"
        >
          <div>
            <div class="title-background-long flex-row modal-title">
              <div class="" style="margin-left: 20px">各户型房间数量</div>
              <a-icon type="close" class="pointer" style="color: #6395fa" @click="roomNumberVisible = false" />
            </div>
            <div class="measure-box">
              <a-spin tip="Loading..." style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" v-show="measureSpin"> </a-spin>
              <a-tabs
                default-active-key="1"
                v-model="roomActiveKey"
                :tabBarStyle="{
                  color: 'black',
                  fontSize: '16px',
                  fontWeight: '700',
                }"
              >
                <a-tab-pane :key="0" tab="总面积">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in roomListData[8]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.roomArea.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>m²</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="1" tab="总数">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in roomListData[0]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.Number.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>套</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="2" tab=" 出租率" force-render>
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in roomListData[1]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ (item.rentedRoomPer * 100).toFixed(3) }}</span
                        ><span>%</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="3" tab="空置率">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in roomListData[2]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ (item.availableRoomPer * 100).toFixed(3) }}</span
                        ><span>%</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="4" tab="已出租">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in roomListData[3]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.rentedRoomNumber.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>套</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="5" tab="自用">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in roomListData[4]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.selfRoomNumber.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>套</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="6" tab="空置">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in roomListData[5]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.availableRoomNumber.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>套</span>
                      </div>
                    </div>
                  </div></a-tab-pane
                >
                <a-tab-pane :key="7" tab="本月度退租">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in roomListData[6]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.monthRoomNumber.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>套</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="8" tab="本年度退租">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in roomListData[7]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.yearRoomNumber.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>套</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="9" tab="本月新租">
                  <div class="measure-row">
                    <div class="measure-col" v-for="(item, index) in roomListData[9]">
                      <div class="name">{{ item.name }}</div>
                      <div>
                        <span class="number">{{ item.newRentRoomNumber.toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>套</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
        </a-modal>
      </div>
      <!-- tag:租金 -->
      <div class="resources-rent box-style flex-column" :class="level == 'park' || level == 'area' ? 'pointer' : ''" v-if="check1()">
        <div class="title">租金（万元）</div>
        <a-modal
          v-model="rentVisible"
          :title="false"
          :footer="null"
          :centered="true"
          :maskStyle="{ background: 'rgba(16, 36, 87, 0.65)' }"
          width="800px"
          :closable="false"
          :zIndex="9999"
          v-if="level == 'park' || level == 'area'"
        >
          <div>
            <div class="title-background-long flex-row modal-title">
              <div class="" style="margin-left: 20px">{{ rentTitle }}</div>
              <div>
                <a-icon type="left-circle" class="pointer" style="color: #6395fa" v-if="backShow" @click="backLevel" />
                <a-icon type="close" class="pointer" style="color: #6395fa; margin-left: 8px" @click="rentModalClose" />
              </div>
            </div>
            <div class="measure-box">
              <!-- <a-spin
                tip="加载中..."
                style="
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                "
                size="large"
                v-show="!rentData[0]"
              >
              </a-spin> -->
              <a-tabs
                v-model="rentActiveKey"
                :tabBarStyle="{
                  color: 'black',
                  fontSize: '16px',
                  fontWeight: '700',
                }"
              >
                <a-tab-pane :key="1" tab="本年度已收租金">
                  <div class="measure-row">
                    <div v-show="!rentData[0]" style="height: 400px; width: 100%; display: flex; align-items: center; justify-content: center; background: white; z-index: 10">
                      <a-spin size="large" tip="加载中..."> </a-spin>
                    </div>
                    <div class="rent-col" v-for="(item, index) in rentData[0]" @click="changeLevel(item.id, item.name)">
                      <div class="name" :class="rentLevel == 'park' ? 'hoverstyle' : ''">
                        {{ item.name }}
                      </div>
                      <div>
                        <span class="number">{{ item.yearAmount.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>元</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="2" tab=" 本月度已收租金" force-render v-if="monthReceivedArr">
                  <div class="measure-row">
                    <div v-show="!monthReceivedArr[0]" style="height: 400px; width: 100%; display: flex; align-items: center; justify-content: center; background: white; z-index: 10">
                      <a-spin size="large" tip="加载中..."> </a-spin>
                    </div>
                    <div v-show="monthReceivedArr[0]" class="rent-col" v-for="(item, index) in monthReceivedArr" @click="changeLevel(item.id, item.name)">
                      <div class="name" :class="rentLevel == 'park' ? 'hoverstyle' : ''">
                        {{ item.name }}
                      </div>
                      <div>
                        <span class="number">{{ item.monthAmount.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>元</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="3" tab="当月未回款">
                  <div class="measure-row">
                    <div v-show="!notReceivedArr[0]" style="height: 400px; width: 100%; display: flex; align-items: center; justify-content: center; background: white; z-index: 10">
                      <a-spin size="large" tip="加载中..."> </a-spin>
                    </div>
                    <div v-show="notReceivedArr[0]" class="rent-col" v-for="(item, index) in notReceivedArr" @click="changeLevel(item.id, item.name)">
                      <div class="name" :class="rentLevel == 'park' ? 'hoverstyle' : ''">
                        {{ item.name }}
                      </div>
                      <div>
                        <span class="number">{{ item.monthAmount.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>元</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :contenteditable="4" :tab="'未回款详情(' + notReceived.length + ')'">
                  <!-- <div class="measure-row"> -->
                  <!-- <div
                        class="measure-col"
                        v-for="(item, index) in notReceived"
                      >
                        <a-col :span="18" class="text">
                          {{ item.companyName }}
                        </a-col>
                      </div>
                    </div> -->
                  <div v-if="notReceived.length > 0">
                    <a-row>
                      <a-col :span="9">
                        <a-input placeholder="请输入公司名称" allow-clear @change="notReceivedSearch" style="margin-bottom: 16px; color: black" v-model="searchCompany" />
                        <div class="not-received-content">
                          <div v-for="(item, index) in notReceived" :key="index" style="margin-bottom: 16px">
                            <div class="not-received-name flex-row pointer" @click="showNotReceivedDetail(item)">
                              <div :style="selectdNotReceived == item.id ? 'color:#055FCD;font-weight:800' : ''">
                                {{ item.companyName }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="15" v-if="!notReceivedDetail" style="font-size: 18px; color: black; justify-content: center" class="flex-row notReceived-detail"> 该合同暂无数据！ </a-col>
                      <a-col :span="15" class="notReceived-detail" v-if="notReceivedDetail">
                        <div class="flex-row right-item">
                          <div class="name">楼栋名称</div>
                          <div>{{ notReceivedDetail.buildingName }}</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">楼层</div>
                          <div>{{ notReceivedDetail.floorName }}</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">房间名称</div>
                          <div>{{ notReceivedDetail.roomName }}</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">公司名称</div>
                          <div>{{ notReceivedDetail.companyName }}</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">总金额</div>
                          <div>{{ notReceivedDetail.shouldPay }}元</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">已收金额</div>
                          <div>{{ notReceivedDetail.revAmount }}元</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">未回款金额</div>
                          <div>{{ notReceivedDetail.amount }}元</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">建筑面积</div>
                          <div>{{ notReceivedDetail.buildingArea }}m²</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">客户名称</div>
                          <div>{{ notReceivedDetail.companyName }}</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">联系人电话</div>
                          <div>{{ notReceivedDetail.linkPhone }}</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">合同编号</div>
                          <div>{{ notReceivedDetail.contractNumber }}</div>
                        </div>
                        <!-- <div class="flex-row right-item">
                          <div class="name">经办人名称</div>
                          <div>{{ notReceivedDetail.manager }}</div>
                        </div>

                        <div class="flex-row right-item">
                          <div class="name">电话</div>
                          <div>{{ notReceivedDetail.phone }}</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">证件号码</div>
                          <div>{{ notReceivedDetail.certificateNumber }}</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">开始日期</div>
                          <div>{{ notReceivedDetail.startDate }}</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">结束日期</div>
                          <div>{{ notReceivedDetail.endDate }}</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">合同租金</div>
                          <div>{{ notReceivedDetail.rentAmount }}元</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">乙方名称</div>
                          <div>{{ notReceivedDetail.partBName }}</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">甲方名称</div>
                          <div>{{ notReceivedDetail.parkAName }}</div>
                        </div>
                        <div class="flex-row right-item">
                          <div class="name">证件类型</div>
                          <div>{{ notReceivedDetail.certificateType }}</div>
                        </div> -->
                      </a-col>
                    </a-row>
                  </div>
                </a-tab-pane>
                <!-- <a-tab-pane :key="5" tab="月度实收金额">
                  <div>
                    <a-month-picker
                      placeholder="本月"
                      @change="onMonthChange"
                      :popupStyle="{ zIndex: 9999 }"
                      style="width: 45%; margin-right: 5%"
                      :default-value="moment(moment().startOf('month'), 'YYYY-MM')"
                    />
                    <a-select style="width: 45%" v-model="acAreaAmountType" @change="handleAmountTypeChange" :dropdownStyle="{ zIndex: 9999 }">
                      <a-select-option value="全部租金"> 全部租金 </a-select-option>
                      <a-select-option value="租金"> 租金 </a-select-option>
                      <a-select-option value="押金"> 押金 </a-select-option>
                      <a-select-option value="预收租金"> 预收租金 </a-select-option>
                    </a-select>

                    <br />
                  </div>
                  <div class="measure-row">
                    <div v-show="!AcAreaAmountDate[0]" style="height: 400px; width: 100%; display: flex; align-items: center; justify-content: center; background: white; z-index: 10">
                      <a-spin size="large" tip="加载中..."> </a-spin>
                    </div>
                    <div class="rent-col" v-for="(item, index) in AcAreaAmountDate" v-if="!acSpin">
                      <div class="name">
                        {{ item.name }}
                      </div>
                      <div>
                        <span class="number">{{ item.data.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>元</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane> -->
                <a-tab-pane :key="5" tab="本月收款">
                  <div class="measure-row">
                    <div v-if="acSpin" style="height: 400px; width: 100%; display: flex; align-items: center; justify-content: center; background: white; z-index: 10">
                      <a-spin size="large" tip="加载中..."> </a-spin>
                    </div>
                    <div class="rent-col" v-for="(item, index) in AreaAmountDate_proceeds" v-if="!acSpin" @click="changeLevel(item.id, item.name)">
                      <div class="name" :class="rentLevel == 'park' ? 'hoverstyle' : ''">
                        {{ item.name }}
                      </div>
                      <div>
                        <span class="number">{{ item.monthAmount.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>元</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="6" tab="本月应收">
                  <div class="measure-row">
                    <div v-if="acSpin" style="height: 400px; width: 100%; display: flex; align-items: center; justify-content: center; background: white; z-index: 10">
                      <a-spin size="large" tip="加载中..."> </a-spin>
                    </div>
                    <div class="rent-col" v-for="(item, index) in AreaAmountDate_transfer" v-if="!acSpin" @click="changeLevel(item.id, item.name)">
                      <div class="name" :class="rentLevel == 'park' ? 'hoverstyle' : ''">
                        {{ item.name }}
                      </div>
                      <div>
                        <span class="number">{{ item.monthAmount.toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,") }}</span
                        ><span>元</span>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
        </a-modal>
        <a-row style="margin-top: 1.6rem" type="flex" justify="space-between" v-if="parkData">
          <a-col :span="8" class="rent-box" style="border-color: var(--blue-color)" @click="rentVisibleShow(6)">
            <div class="num" style="font-size: 2.4rem; color: var(--blue-color)">
              {{ numData.transferAmount ? (parseInt(numData.transferAmount) / 10000).toFixed(1) : 0 }}
            </div>
            <div class="name">本月度</div>
            <div class="name">应收租金</div>
          </a-col>
          <a-col :span="8" class="rent-box" style="border-color: var(--green-color)" @click="rentVisibleShow(2)">
            <div class="num" style="font-size: 2.4rem; color: var(--green-color)" v-if="allMonthAmount !== undefined">
              {{ allMonthAmount ? (parseInt(allMonthAmount) / 10000).toFixed(1) : 0 }}
            </div>
            <div class="num" style="font-size: 2.4rem; color: var(--green-color)" v-if="allMonthAmount == undefined">
              {{ parkData.monthAmount ? (parseInt(parkData.monthAmount) / 10000).toFixed(1) : 0 }}
            </div>
            <!-- <div class="num" style="color: var(--green-color)" @click="rentVisibleShow(2)">
              {{(numData.monthAmount && numData.transferAmount)
              ? (parseInt(numData.monthAmount + numData.transferAmount) / 10000).toFixed(2)
              : 0}}
            </div> -->
            <div class="name">本月度</div>
            <div class="name">已收租金</div>
          </a-col>
          <a-col :span="8" class="rent-box" style="border-color: var(--yellow-color)" @click="rentVisibleShow(3)">
            <div class="num" style="font-size: 2.4rem; color: var(--yellow-color)" v-if="allNotReceivedAmount">
              {{ allNotReceivedAmount ? (parseInt(allNotReceivedAmount) / 10000).toFixed(1) : 0 }}
            </div>
            <div class="num" style="font-size: 2.4rem; color: var(--yellow-color)" v-else>
              {{ parkData.notReceivedAmountCount ? (parseInt(parkData.notReceivedAmountCount) / 10000).toFixed(1) : 0 }}
            </div>
            <div class="name">当月未回款</div>
            <div class="name" v-if="allNotReceivedCount">（{{ allNotReceivedCount || 0 }}）</div>
            <div class="name" v-if="!allNotReceivedCount">（{{ parkData.notReceivedCount || 0 }}）</div>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between">
          <a-col :span="12">
            <div class="ac-rent" @click="rentVisibleShow(5)">
              <div class="num" style="font-size: 2.4rem; color: #6170f4">
                {{ numData.monthAmount ? (parseInt(numData.monthAmount) / 10000).toFixed(1) : 0 }}
              </div>
              <div class="name">本月度</div>
              <div class="name">收款金额</div>
            </div>
          </a-col>
          <a-col :span="11">
            <div class="ac-rent" @click="rentVisibleShow(1)">
              <div class="num" style="font-size: 2.4rem; color: #6170f4">
                {{ parkData && parkData.yearAmount ? (parseInt(parkData.yearAmount) / 10000).toFixed(1) : 0 }}
              </div>
<!--              <div class="name">本月转款金额</div>-->
              <div class="name">本年度</div>
              <div class="name">已收租金</div>
            </div>
          </a-col>
        </a-row>
      </div>
      <!-- tag:合同 -->
      <div class="resources-expiredContract box-style" v-if="parkData">
        <div class="title">合同将到期</div>
        <a-row class="expired-box">
          <a-col :span="11" class="pointer" @click="showOneContract()">
            <div class="flex-row" style="align-items: flex-end">
              <div class="num" style="color: var(--blue-color)">
                {{ parkData.ExpireOneMonthCount }}
              </div>
              <div class="name" style="line-height: 40px; margin-left: 8px">近一月</div>
            </div>
          </a-col>
          <a-col :span="11" class="pointer" @click="showThreeContract(3)">
            <div class="flex-row" style="justify-content: flex-end; align-items: flex-end">
              <div class="num" style="color: var(--blue-color)">
                {{ parkData.ExpireThreeMonthCount }}
              </div>
              <div class="name" style="line-height: 40px; margin-left: 8px">近三月</div>
            </div>
          </a-col>
        </a-row>
        <a-modal
          v-model="expireVisible"
          :title="false"
          :footer="null"
          :centered="true"
          :maskStyle="{ background: 'rgba(16, 36, 87, 0.65)' }"
          :closable="false"
          :bodyStyle="{
            width: '100%',
          }"
          width="800px"
          :zIndex="9999"
        >
          <div>
            <div class="title-background-long flex-row modal-title">
              <div style="margin-left: 20px">{{ contractName }} ({{ contractCount }})</div>
              <a-icon type="close" class="pointer" style="color: #6395fa" @click="expireVisible = false" />
            </div>
            <a-row class="expire-box">
              <a-col :span="9">
                <a-input placeholder="请输入公司名称" allow-clear @change="notExpire" style="margin-top: 20px; color: black" v-model="searchExpire" />
                <div class="box-content">
                  <div v-for="(item, index) in contractArr" :key="index" style="margin-bottom: 16px">
                    <div class="contract-data-title flex-row">
                      <div style="color: #6395fa">
                        {{ item.endDate }}
                      </div>
                    </div>
                    <div class="contract-data flex-row pointer" @click="showCompanyInfo(item)">
                      <div :class="item.id == selectdCompany ? 'selectdCompany' : ''">
                        {{ item.companyName }}
                      </div>
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col :span="15" style="padding-left: 8px; margin-top: 16px">
                <div class="flex-row right-item">
                  <div class="name">合同编号</div>
                  <div>{{ companyInfo.number }}</div>
                </div>
                <div class="flex-row right-item">
                  <div class="name">总金额</div>
                  <div>{{ companyInfo.amount }}元</div>
                </div>
                <div class="flex-row right-item">
                  <div class="name">联系人电话</div>
                  <div>{{ companyInfo.linkPhone }}</div>
                </div>
                <div class="flex-row right-item">
                  <div class="name">经办人名称</div>
                  <div>{{ companyInfo.manager }}</div>
                </div>
                <div class="flex-row right-item">
                  <div class="name">房间名称</div>
                  <div>{{ companyInfo.roomName }}</div>
                </div>
                <div class="flex-row right-item">
                  <div class="name">电话</div>
                  <div>{{ companyInfo.phone }}</div>
                </div>
                <div class="flex-row right-item">
                  <div class="name">证件号码</div>
                  <div>{{ companyInfo.certificateNumber }}</div>
                </div>
                <div class="flex-row right-item">
                  <div class="name">开始日期</div>
                  <div>{{ companyInfo.startDate }}</div>
                </div>
                <div class="flex-row right-item">
                  <div class="name">结束日期</div>
                  <div>{{ companyInfo.endDate }}</div>
                </div>
                <div class="flex-row right-item">
                  <div class="name">合同租金</div>
                  <div>{{ companyInfo.rentAmount }}元</div>
                </div>
                <div class="flex-row right-item">
                  <div class="name">乙方名称</div>
                  <div>{{ companyInfo.partBName }}</div>
                </div>
                <div class="flex-row right-item">
                  <div class="name">甲方名称</div>
                  <div>{{ companyInfo.parkAName }}</div>
                </div>
                <div class="flex-row right-item">
                  <div class="name">证件类型</div>
                  <div>{{ companyInfo.certificateType }}</div>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-modal>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "../api/api.js";
import moment from "moment";
export default {
  props: {
    id: Number,
    level: String,
  },
  data() {
    return {
      resources: {
        measure: undefined, //面积资源
        rent: undefined, //租金
        expiredCcontract: undefined, //到期合同
      },
      canvasArr: [
        { color: "#6395FA", bg: "rgba(99, 149, 250, 0.2)", name: "总面积" },
        { color: "#79DFB7", bg: "rgba(99, 218, 171, 0.2)", name: "出租率" },
        { color: "#FFCA14", bg: "rgba(246, 210, 85, 0.2)", name: "空置率" },
      ],
      rentVisible: false,
      contractVisible: false,
      contractArr: [],
      parkId: undefined, //园区ID
      parkData: {}, //园区总数据
      canvasData: [], //环形图数据
      contractName: undefined, //合同到期名称
      contractCount: undefined, //合同到期数量
      notReceived: [], //未回款列表
      notReceivedClone: [], //未回款列表克隆
      measureVisible: false,
      expireVisible: false,
      notReceivedVisible: false,
      selectdCompany: null,
      companyInfo: {},
      measureData: [],
      rentData: [],
      selectdNotReceived: null,
      notReceivedDetail: {},
      measureSpin: true,
      rentsSpin: true,
      activeKey: 1,
      rentActiveKey: 5,
      searchCompany: undefined,
      timer: undefined,
      groupSpaceData: undefined,
      rentLevel: undefined,
      rentTitle: "各区域租金",
      searchExpire: undefined,
      contractArrClone: undefined,
      backShow: false,
      areaType: 1,
      roomActiveKey: 1,
      roomNumberVisible: false,
      roomListData: [],
      monthString: moment().startOf("month").format("YYYY-MM"),
      acAreaAmountType: "全部租金",
      AcAreaAmountDate: [],
      AreaAmountDate_transfer: [], //转款
      AreaAmountDate_proceeds: [], //收款
      acSpin: true,
      monthReceivedArr: [],
      notReceivedArr: [],
      allMonthAmount: undefined,
      allNotReceivedAmount: undefined,
      allNotReceivedCount: undefined,
      rentId: undefined,
      numData:{
        transferAmount:0,
        monthAmount:0
      },
    };
  },
  watch: {
    id(e) {
      this.parkData = undefined;
      const vm = this;
      setTimeout(async () => {
        if (this.level == "floor") {
          await this.getFloor();
          vm.canvasArr.forEach((item, index) => {
            vm.toCanvas("canvas" + index, vm.canvasData[index], item.name, item.color, item.bg);
          });
        }
        // switch (this.level) {
        //   case "park":
        //     await this.getParkData();
        //     break;
        //   case "area":
        //     await this.getAreaData();
        //     break;
        //   case "building":
        //     await this.getBuilding();
        //     break;
        //   case "floor":
        //     await this.getFloor();
        //     // await this.getAllAreaAmount()
        //     // await this.showRent();
        //     break;
        // }
        // // await this.getParkData(this.id);
        // vm.canvasArr.forEach((item, index) => {
        //   vm.toCanvas(
        //     "canvas" + index,
        //     vm.canvasData[index],
        //     item.name,
        //     item.color,
        //     item.bg
        //   );
        // });
      }, 0);
    },
  },
  created(){
    this.rentLevel = this.level;
    this.rentId = this.id;
  },
  async mounted() {
    let vm = this;
    setTimeout(async () => {
      this.getAllAreaData();
      // this.getAllAreaAmount();
      this.getAcAreaAmount();
      this.showRent();
      this.getParkAllMonthAmount();
      this.getParkNotReceivedAmount();
      switch (this.level) {
        case "park":
          await this.getParkData();
          break;
        case "area":
          await this.getAreaData();
          break;
        case "building":
          await this.getBuilding();
          break;
        case "floor":
          await this.getFloor();
          break;
      }
      // await this.getParkData(this.id);
      vm.canvasArr.forEach((item, index) => {
        vm.toCanvas("canvas" + index, vm.canvasData[index], item.name, item.color, item.bg);
      });
    }, 0);
  },
  methods: {
    check1() {
      console.log(this.level)
     /* alert("1111")
      alert(localStorage.getItem('role'))*/
      if (this.level == "park"){
        return (localStorage.getItem('role').includes('YJQX') || localStorage.getItem('role').includes('EJQX'));
      }else if (this.level == "area"){
        return (localStorage.getItem('role').includes('YJQX') || localStorage.getItem('role').includes('EJQX')
            || localStorage.getItem('role').includes('CQSJQX') || localStorage.getItem('role').includes('DSSJQX')
            || localStorage.getItem('role').includes('ZJSJQX') || localStorage.getItem('role').includes('GYSSSJQX'));
      }
    },
    // done:资源面积圆形图
    toCanvas(id, progress, name, color, bgColor) {
      //canvas进度条
      var canvas = document.getElementById(id);
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      var percent = progress; //最终百分比
      var circleX = canvas.width / 2; //中心x坐标
      var circleY = canvas.height / 2; //中心y坐标
      var radius = 75; //圆环半径
      var lineWidth = 14; //圆形线条的宽度
      //中间的字
      var fontSize = 35;
      // var fontWeight = 600
      // var fontWeight = 600
      ctx.font = "900" + fontSize + "px April";
      ctx.font = " 700 35px arial";
      ctx.textAlign = "center";
      // fontWeight = null
      ctx.fillStyle = color;
      ctx.fillText(percent, circleX, circleY + 8);
      fontSize = 16;
      ctx.font = " 500 22px arial";
      ctx.fillStyle = "black";
      ctx.fillText(name, circleX, circleY + 35);
      // ctx.fillStyle = "#999";
      // ctx.fillText("还差20积分升级会员", circleX, circleY + 40);
      //画圆
      function circle(cx, cy, r) {
        ctx.beginPath();
        //ctx.moveTo(cx + r, cy);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = bgColor;
        ctx.arc(cx, cy, r, 0, (Math.PI * 6) / 3);
        ctx.stroke();
      }
      // 画弧线
      function sector(cx, cy, r, startAngle, endAngle) {
        ctx.beginPath();
        //ctx.moveTo(cx, cy + r); // 从圆形底部开始画
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;
        //圆弧两端的样式
        ctx.lineCap = "butt";
        //圆弧
        ctx.arc(cx, cy, r, (Math.PI * 1) / 2, (Math.PI * 1) / 2 + (endAngle / 100) * ((Math.PI * 6) / 3), false);
        ctx.stroke();
      }
      circle(circleX, circleY, radius);

      //圆弧
      if (name == "总面积") {
        sector(circleX, circleY, radius, (Math.PI * 2) / 3, 100);
      } else {
        sector(circleX, circleY, radius, (Math.PI * 2) / 3, parseInt(percent));
      }
    },
    // done:未回款详情
    async showRent() {
      var res;
      switch (this.level) {
        case "park":
          res = await Api.getNotReceived(this.id);
          break;
        case "area":
          res = await Api.getAreaNotReceived(this.id);
          break;
        case "building":
          // res = await Api.getbuildingNotReceived(this.id);
          break;
        case "floor":
          res = await Api.getFloorNotReceived(this.id);
          break;
      }
      if (res) {
        this.notReceived = res.data.data;
        this.notReceivedClone = res.data.data;
      }

      if (this.notReceived[0]) {
        this.selectdNotReceived = this.notReceived[0].id;
        this.showNotReceivedDetail(this.notReceived[0]);
      }
    },
    // done:查询资源面积详情
    async getAllAreaData() {
      var res;
      var result;
      switch (this.level) {
        case "park":
          result = await Api.getParkGroupSpace(4);
          res = await Api.getAllAreaData(4);
          break;
        case "area":
          result = await Api.getAreaGroupSpace(this.id);
          res = await Api.getAllBuildingGroupData(this.id);
          break;
        case "building":
          // res = await Api.getbuildingNotReceived(this.id);
          break;
        case "floor":
          // res = await Api.getAllRoomAmount(this.id);
          break;
      }

      if (res) {
        if (this.check1()){
          this.measureData = res.data.data;
          this.measureSpin = false;
        }

      }
      if (result) {
        this.groupSpaceData = result.data;
      }
    },
    // done:查询金额详情
    async getAllAreaAmount() {
      var res;
      switch (this.level) {
        case "park":
          this.getMonthAndNotReceived();
          res = await Api.getAllAreaAmount(4);
          break;
        case "area":
          this.getMonthAndNotReceived();
          res = await Api.getAllBuildingGroupAmount(this.id);
          break;
        case "building":
          // res = await Api.getbuildingNotReceived(this.id);
          break;
        case "floor":
          res = await Api.getAllRoomAmount(this.id);
          break;
      }
      if (res) {
        this.rentData = res.data.data;
        this.rentsSpin = false;
      }
    },
    // done:查询近一个月到期合同详情
    async showOneContract() {
      let item = localStorage.getItem("role");
      let items = item.split(",")
      let item1 = ["YJQX","EJQX","CQSJQX","DSSJQX","ZJSJQX","GYSSSJQX"];
      for (let itemElement of items) {
        if (item1.includes(itemElement)){
          var res;
          switch (this.level) {
            case "park":
              res = await Api.getOneExpired(this.id);
              break;
            case "area":
              res = await Api.getAreaOneExpired(this.id);
              break;
            case "building":
              res = await Api.getBuildingGroupsOneExpired(this.id);
              break;
            case "floor":
              res = await Api.getFloorOneExpired(this.id);
              break;
          }
          if(res.data.count > 0) {
            this.selectdCompany = res.data.data[0].id;
            this.showCompanyInfo(res.data.data[0]); //默认展示第一条
            this.contractName = "近一月合同将到期";
            this.contractCount = res.data.count;
            this.contractArr = res.data.data;
            this.contractArrClone = res.data.data;
            this.contractArrClone.forEach((item) => {
              item.endDate = moment(item.endDate).format("YYYY/MM/DD");
            });
            this.contractArr.forEach((item) => {
              item.endDate = moment(item.endDate).format("YYYY/MM/DD");
            });
            this.expireVisible = true;
          } else {
            this.$message.info("没有数据");
          }
          return;
        }
      }
    },
    // done:查询近三个月到期合同详情
    async showThreeContract() {
      let item = localStorage.getItem("role");
      let items = item.split(",")
      let item1 = ["YJQX","EJQX","CQSJQX","DSSJQX","ZJSJQX","GYSSSJQX"];
      for (let itemElement of items) {
        if (item1.includes(itemElement)){
          var res;
          switch (this.level) {
            case "park":
              res = await Api.getThreeExpired(this.id);
              break;
            case "area":
              res = await Api.getAreaThreeExpired(this.id);
              break;
            case "building":
              res = await Api.getBuildingGroupsThreeExpired(this.id);
              break;
            case "floor":
              res = await Api.getFloorThreeExpired(this.id);
              break;
          }
          if(res.data.count > 0) {
            this.selectdCompany = res.data.data[0].id;
            this.showCompanyInfo(res.data.data[0]); //默认展示第一条
            this.contractName = "近三月合同将到期";
            this.contractCount = res.data.count;
            this.contractArr = res.data.data;
            this.expireVisible = true;
            this.contractArrClone = res.data.data;
            this.contractArrClone.forEach((item) => {
              item.endDate = moment(item.endDate).format("YYYY/MM/DD");
            });
            this.contractArr.forEach((item) => {
              item.endDate = moment(item.endDate).format("YYYY/MM/DD");
            });
          }else {
            this.$message.info("没有数据");
          }
          return;
        }
      }

    },
    // done:获取园区数据
    async getParkData() {
      const res = await Api.getPark(this.id);
      var arr = [];
      arr.push(parseInt(res.data.area));
      arr.push((res.data.rentedPercentage * 100).toFixed(2) + "%");
      arr.push((res.data.availablePercentage * 100).toFixed(2) + "%");

      this.canvasData = arr;
      this.parkData = res.data;

      //本月转款金额
      if (this.check1()){
        Api.getParkTransferAmount(this.id).then((res) => {
          this.numData.transferAmount = res.data.transfer;
        })
        //本月收款金额
        Api.getParkReceiptAmount(this.id).then((res) => {
          this.numData.monthAmount = res.data.monthAmount;
        })
      }

    },
    // done:获取区域数据
    async getAreaData() {
      const res = await Api.getAreaData(this.id);
      var arr = [];
      arr.push(parseInt(res.data.space));
      arr.push((res.data.rentedPercentage * 100).toFixed(2) + "%");
      arr.push((res.data.availablePercentage * 100).toFixed(2) + "%");
      this.canvasData = arr;
      this.parkData = res.data;
      this.areaType = res.data.areaType;
      if (this.areaType == 2) {
        this.getAreaRoomNumber();
      }

      if (this.check1()){
        //本月转款金额
        Api.getAreaTransferAmount(this.id).then((res) => {
          this.numData.transferAmount  = res.data.transfer;
        })
        //本月收款金额
        Api.getAreaReceiptAmount(this.id).then((res) => {
          this.numData.monthAmount = res.data.monthAmount;
        })
      }
    },
    // done:获取楼栋数据
    async getBuilding() {
      const res = await Api.getBuilding(parseInt(this.id));
      this.areaName = res.data.areaName;
      this.buildingGroupName = res.data.buildingGroupName;
      var arr = [];
      arr.push(parseInt(res.data.space));
      arr.push((res.data.rentedPercentage * 100).toFixed(2) + "%");
      arr.push((res.data.availablePercentage * 100).toFixed(2) + "%");
      this.canvasData = arr;
      this.parkData = res.data;

       //本月转款金额
      Api.getBuildTransferAmount(this.id).then((res) => {
        this.numData.transferAmount= res.data.transfer;
      })
      //本月收款金额
      Api.getBuildReceiptAmount(this.id).then((res) => {
        this.numData.monthAmount = res.data.monthAmount;
      })
    },
    // done:获取楼层数据
    async getFloor() {
      const res = await Api.getFloor(parseInt(this.id));
      this.areaName = res.data.areaName;
      this.buildingGroupName = res.data.buildingGroupName;
      var arr = [];
      arr.push(parseInt(res.data.space));
      arr.push((res.data.rentedPercentage * 100).toFixed(2) + "%");
      arr.push((res.data.availablePercentage * 100).toFixed(2) + "%");
      this.canvasData = arr;
      this.parkData = res.data;

      //本月转款金额
      Api.getFloorTransferAmount(this.id).then((res) => {
        this.numData.transferAmount = res.data.transfer;
      })
      //本月收款金额
      Api.getFloorReceiptAmount(this.id).then((res) => {
        this.numData.monthAmount = res.data.monthAmount;
      })
    },
    // 获取公司信息
    async showCompanyInfo(item) {
      this.selectdCompany = item.id;
      // const res = await Api.getcontractTenancyId(id);
      this.companyInfo = item;
      this.companyInfo.startDate = moment(this.companyInfo.startDate).format("YYYY/MM/DD");
      this.companyInfo.endDate = moment(this.companyInfo.endDate).format("YYYY/MM/DD");
    },
    // 获取公司未回款详情
    async showNotReceivedDetail(item) {
      this.selectdNotReceived = item.id;
      // const res = await Api.getNotReceivedDetail(id);
      // this.notReceivedDetail = res.data.data[0];
      this.notReceivedDetail = item;
      if (this.notReceivedDetail) {
        this.notReceivedDetail.startDate = moment(this.companyInfo.startDate).format("YYYY/MM/DD");
        this.notReceivedDetail.endDate = moment(this.companyInfo.endDate).format("YYYY/MM/DD");
      }
    },
    measureVisibleShow(num) {
      let item = localStorage.getItem("role");
      let items = item.split(",")
      let item1 = ["YJQX","EJQX","CQSJQX","DSSJQX","ZJSJQX","GYSSSJQX"];
      for (let itemElement of items) {
        if (item1.includes(itemElement)){
          this.activeKey = num;
          if (this.level == "park" || this.level == "area") {
            this.measureVisible = true;
            return;
          }
        }
      }
    },
    roomVisibleShow(num) {
      this.roomActiveKey = num;
      if (this.level == "park" || this.level == "area") {
        this.roomNumberVisible = true;
      }
    },
    rentVisibleShow(num) {
      this.getAllAreaAmount();
      this.rentActiveKey = num;
      if (this.level == "park" || this.level == "area") {
        this.rentVisible = true;
      }
    },
    //搜索未回款公司
    notReceivedSearch() {
      clearTimeout(this.timer);
      var newArr = [];
      this.timer = setTimeout(() => {
        this.notReceivedClone.filter((item) => {
          if (item.companyName.toLowerCase().indexOf(this.searchCompany) !== -1) {
            newArr.push(item);
          }
        });
        this.notReceived = newArr;
      }, 300);
    },
    //搜索未回款公司
    notExpire() {
      clearTimeout(this.timer);
      var newArr = [];
      this.timer = setTimeout(() => {
        this.contractArrClone.filter((item) => {
          if (item.companyName.toLowerCase().indexOf(this.searchExpire) !== -1) {
            newArr.push(item);
          }
        });
        this.contractArr = newArr;
      }, 300);
    },
    async changeLevel(id, name) {
      if (this.rentLevel == "park") {
        var res;
        var result;
        this.rentId = id;
        this.notReceivedArr = [];
        this.monthReceivedArr = [];
        this.rentData = [];
        this.AcAreaAmountDate = [];
        this.rentsSpin = true;
        this.rentLevel = "area";
        res = await Api.getAllBuildingGroupAmount(id);
        result = await Api.getAreaNotReceived(id);
        Api.getAllBuildMonthAmount(id).then((res) => {
          this.monthReceivedArr = res.data.data;
        });
        Api.getAllBuildNotReceived(id).then((res) => {
          this.notReceivedArr = res.data.data;
        });
        // Api.getAcBldAmount([id, this.monthString, this.acAreaAmountType]).then(
        //   (res) => {
        //     this.AcAreaAmountDate = res.data.data;
        //   }
        // );
        this.getAcAreaAmount();
        this.rentData = res.data.data;
        this.notReceived = result.data.data;

        this.backShow = true;
        this.rentTitle = name;
        this.rentsSpin = false;
      }
    },
    async backLevel() {
      this.rentId = this.id;
      this.rentsSpin = true;
      this.notReceivedArr = [];
      this.monthReceivedArr = [];
      this.rentData = [];
      this.AcAreaAmountDate = [];
      this.rentLevel = "park";
      this.getAcAreaAmount();
      this.getAllAreaAmount();
      this.showRent();
      this.rentTitle = "各区域租金";
      this.backShow = false;

      this.areaType = 1;
      this.rentsSpin = false;
    },
    rentModalClose() {
      this.rentVisible = false;
      // if (this.rentLevel == "park") {
      //   this.backLevel();
      // }
    },
    //根据区域ID查询户型间数
    async getAreaRoomNumber() {
      const res = await Api.getAreaRoomNumber(this.id);
      this.roomListData = res.data.data;
    },
    //根据园区ID查询各区域ID
    async getMonthAndNotReceived() {
      // var monthReceivedArr = [];
      // var arr = [];
      // var notReceivedArr = [];
      // this.monthReceivedArr = [];
      // // const res = await Api.getAreaId(4);
      // arr = res.data.data;
      if (this.level == "park") {
        Api.getAllAreaMonthAmount(this.id).then((res) => {
          this.monthReceivedArr = res.data.data;
        });
        Api.getAllAreaNotReceived(this.id).then((res) => {
          this.notReceivedArr = res.data.data;
        });
      } else if (this.level == "area") {
        Api.getAllBuildMonthAmount(this.id).then((res) => {
          this.monthReceivedArr = res.data.data;
        });
        Api.getAllBuildNotReceived(this.id).then((res) => {
          this.notReceivedArr = res.data.data;
        });
      }
    },
    async onMonthChange(date, dateString) {
      // console.log(this.monthString);
      if (dateString) {
        this.monthString = dateString;
        this.getAcAreaAmount();
      }
    },
    handleAmountTypeChange() {
      this.getAcAreaAmount();
    },
    //根据园区ID查询月已收总额
    async getParkAllMonthAmount() {
      if (this.check1()){
        if (this.level == "park") {
          Api.getParkAllMonthAmount(4).then((res) => {
            this.allMonthAmount = res.data.monthAmount;
          });
        } else if (this.level == "area") {
          Api.getAreaAllMonthAmount(this.id).then((res) => {
            this.allMonthAmount = res.data.monthAmount;
          });
        }
      }

    },
    //根据园区ID查询未回款总额
    async getParkNotReceivedAmount() {
      if (this.check1()){
        if (this.level == "park") {
          Api.getParkNotReceivedAmount(4).then((res) => {
            this.allNotReceivedAmount = res.data.notReceivedAmountCount;
            this.allNotReceivedCount = res.data.notReceivedCount;
          });
        } else if (this.level == "area") {
          Api.getAreaNotReceivedAmount(this.id).then((res) => {
            this.allNotReceivedAmount = res.data.notReceivedAmountCount;
            this.allNotReceivedCount = res.data.notReceivedCount;
          });
        }
      }
    },
    //获取各区域当月实收
    async getAcAreaAmount() {
      console.log(this.rentLevel,'level')
      this.acSpin = true;
      // var res;
      var proceeds_res;
      var transfer_res;
      this.AcAreaAmountDate = [];
      this.AreaAmountDate_proceeds = [];
      this.AreaAmountDate_transfer = [];
      switch (this.rentLevel) {
        case "park":
          // res = await Api.acAreaAmount([this.id, this.monthString, this.acAreaAmountType]);
          proceeds_res = await Api.getParkAreaReceiptAmount(this.id); //获取各区域当月收款金额
          transfer_res = await Api.getParkAreaTransferAmount(this.id); //获取各区域当月转款金额
          this.AreaAmountDate_proceeds = proceeds_res.data.data || [];
          this.AreaAmountDate_transfer = transfer_res.data.data || [];
          break;
        case "area":
          // res = await Api.getAcBldAmount([this.rentId, this.monthString, this.acAreaAmountType]);
          proceeds_res = await Api.getBuildAreaReceiptAmount(this.rentId); //获取各区域当月收款金额
          transfer_res = await Api.getBuildAreaTransferAmount(this.rentId); //获取各区域当月转款金额
          this.AreaAmountDate_proceeds = proceeds_res.data.data || [];
          this.AreaAmountDate_transfer = transfer_res.data.data || [];
          break;
        case "building":
          proceeds_res = [];
          transfer_res = [];
          break;
      }
      // this.AcAreaAmountDate = res.data.data;

      this.acSpin = false;
    },
    moment,
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1400px) {
  // .num {
  //   font-size: 24px !important;
  // }
  // .box-style {
  //   background: white;
  //   position: relative;
  // }
  .all {
    height: 100%;
    .num {
      font-family: DIN;
      font-style: normal;
      font-weight: bold;
      // font-size: 20px !important;
      text-align: center;
    }
    .right-content {
      height: 100%;
      .title {
        background-image: url("../assets/index/nameBg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        // height: 28px !important;
        color: #151c51;
        // font-family: Microsoft YaHei UI;
        font-style: normal;
        font-weight: bold;
        // font-size: 16px !important;
        padding-left: 52px;
        padding-top: 4px !important;
      }

      .box-style {
        background: white;
        // padding: 10px !important;
        position: relative;
      }

      .name {
        font-family: Microsoft YaHei UI;
        // font-size: 14px !important;
        color: #000;
        text-align: center;
      }
      .resources-measure {
        height: 100%;
        // margin-bottom: 16px !important;
        .blue {
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--blue-color), rgba(0, 0, 0, 0) 100%);
        }
        .green {
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--green-color), rgba(0, 0, 0, 0) 100%);
        }
        .yellow {
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--yellow-color), rgba(0, 0, 0, 0) 100%);
        }
        .line {
          position: relative;
          top: 4px;
          left: 50%;
          transform: translateX(-50%);
          height: 2px;
          width: 70%;
        }
        .bottom-box {
          margin-top: 12px;
        }
      }
      .resources-rent {
        height: 50%;
        // margin-bottom: 16px !important;
        position: relative;
        .rent-popover {
          content: "";
          position: absolute;
          left: -16px;
          top: 240px !important;
        }
        .rent-box {
          border: 1px solid;
          box-sizing: border-box;
          width: calc(33% - 10px);
          // padding: 8px 0px !important;
          .num {
            font-family: DIN;
            font-style: normal;
            font-weight: bold;
            // font-size: 18px !important;
            text-align: center;
          }
        }
      }
      .resources-expiredContract {
        height: 34%;
        position: relative;
        .line {
          height: 12px;
          background-image: url("../assets/index/expiredBg.png");
          background-size: 100% 100%;
        }
        .expired-box {
          margin-top: 2px !important;
        }
        .contract-popover {
          content: "";
          position: absolute;
          left: -40px;
          top: 50px !important;
        }
      }
    }
  }
}
@media screen and (max-width: 1900px) and (min-width: 1400px) {
  // .num {
  //   font-size: 24px !important;
  // }
  // .box-style {
  //   background: white;
  //   position: relative;
  // }
  .all {
    height: 100%;
    .num {
      font-family: DIN;
      font-style: normal;
      font-weight: bold;
      // font-size: 24px !important;
      text-align: center;
    }
    .right-content {
      height: 100%;
      .title {
        background-image: url("../assets/index/nameBg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        // height: 36px !important;
        color: #151c51;
        font-style: normal;
        font-weight: bold;
        // font-size: 18px;
        padding-left: 52px;
        padding-top: 4px !important;
      }

      .box-style {
        background: white;
        // padding: 14px !important;
        position: relative;
      }

      .name {
        font-family: Microsoft YaHei UI;
        // font-size: 14px !important;
        color: #000;
        text-align: center;
      }
      .resources-measure {
        height: 100%;
        // margin-bottom: 16px !important;
        .blue {
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--blue-color), rgba(0, 0, 0, 0) 100%);
        }
        .green {
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--green-color), rgba(0, 0, 0, 0) 100%);
        }
        .yellow {
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--yellow-color), rgba(0, 0, 0, 0) 100%);
        }
        .line {
          position: relative;
          top: 4px;
          left: 50%;
          transform: translateX(-50%);
          height: 2px;
          width: 70%;
        }
        .bottom-box {
          margin-top: 12px;
        }
      }
      .resources-rent {
        height: 50%;
        // margin-bottom: 16px !important;
        position: relative;
        .rent-popover {
          content: "";
          position: absolute;
          left: -16px;
          top: 300px !important;
        }
        .rent-box {
          border: 1px solid;
          box-sizing: border-box;
          width: calc(33% - 10px);
          // padding: 8px 0px !important;
          .num {
            font-family: DIN;
            font-style: normal;
            font-weight: bold;
            // font-size: 20px !important;
            text-align: center;
          }
        }
      }
      .resources-expiredContract {
        height: 34%;
        position: relative;
        .line {
          height: 12px;
          background-image: url("../assets/index/expiredBg.png");
          background-size: 100% 100%;
        }
        .expired-box {
          margin-top: 2px !important;
        }
        .contract-popover {
          content: "";
          position: absolute;
          left: -40px;
          top: 50px !important;
        }
      }
    }
  }
}
.all {
  height: 100%;
  overflow: auto;
  .num {
    font-family: DIN;
    font-style: normal;
    font-weight: bold;
    font-size: 3.2rem;
    line-height: 4rem;
    text-align: center;
  }
  .right-content {
    height: 100%;
    .title {
      background-image: url("../assets/index/nameBg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 4.4rem;
      color: #151c51;
      // font-family: Microsoft YaHei UI;
      font-style: normal;
      font-weight: bold;
      font-size: 2.2rem;
      padding-left: 52px;
      padding-top: 8px;
    }

    .box-style {
      background: white;
      padding: 1.6rem;
      position: relative;
    }

    .name {
      font-family: Microsoft YaHei UI;
      font-size: 1.6rem;
      color: #000;
      text-align: center;
    }
    .resources-measure {
      height: 100%;
      margin-bottom: 1.6rem;
      .blue {
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--blue-color), rgba(0, 0, 0, 0) 100%);
      }
      .green {
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--green-color), rgba(0, 0, 0, 0) 100%);
      }
      .yellow {
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--yellow-color), rgba(0, 0, 0, 0) 100%);
      }
      .line {
        position: relative;
        top: 4px;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: 70%;
      }
      .bottom-box {
        margin-top: 12px;
      }
    }
    .resources-rent {
      height: 50%;
      margin-bottom: 1.6rem;
      position: relative;
      .rent-popover {
        content: "";
        position: absolute;
        left: -16px;
        top: 0;
      }
      .rent-box {
        border: 1px solid;
        box-sizing: border-box;
        width: calc(33% - 10px);
        padding: 0.8rem;
        padding-top: 0.4rem !important;
      }
      .ac-rent {
        border: 1px solid;
        box-sizing: border-box;
        width: 100%;
        border-color: #6170f4;
        padding: 0.8rem;
        padding-top: 0.4rem !important;
        margin-top: 1.6rem;
      }
    }
    .resources-expiredContract {
      height: 34%;
      position: relative;
      .line {
        height: 12px;
        background-image: url("../assets/index/expiredBg.png");
        background-size: 100% 100%;
      }
      .expired-box {
        margin-top: 8px;
      }
      .contract-popover {
        content: "";
        position: absolute;
        left: -40px;
        top: 76px;
      }
    }
  }
}
.expire-box {
  width: 100%;
  .right-box {
    max-height: 554px;
    overflow-y: auto;
  }
  .right-item {
    height: 100%;
    justify-content: space-between;
    color: #151a30;
    font-size: 15px;
    padding: 10px 8px;
    .name {
      font-weight: 800;
    }
    .number {
    }
  }
}
.measure-overview {
  font-size: 16px;
  color: #151a30;
  font-weight: 600;
  span {
    font-size: 18px;
    font-weight: 700;
  }
  .measure-overview-row {
    justify-content: space-between;
    margin-top: 8px;
    div {
      width: 25%;
    }
  }
}
.modal-title {
  height: 44px;
  justify-content: space-between;
}
.popover-box {
  padding: 12px;
  width: 432px;
  height: 380px;
  .box-title {
    height: 44px;
  }
}
.box-content {
  margin-top: 20px;
  width: 100%;
  max-height: 496px;
  overflow: auto;
  padding-right: 4px;
  .text {
    font-size: 16px;
    color: #151a30;
  }
  .selectdCompany {
    color: #055fcd;
    font-weight: 800;
  }
  .number {
    color: #63daab;
    font-size: 16px;
  }
  .contract-data-title {
  }
  .contract-data {
    justify-content: space-between;
    color: black !important;
    font-size: 15px;
    margin-top: 4px;
    .text {
    }
    .num {
      color: #6395fa;
      font-weight: 700;
      font-size: 1.6rem;
    }
  }
}
.notReceived-detail {
  max-height: 466px;
  overflow-y: auto;
  padding-left: 8px;
  // border-left: 1px solid #d9d9d9;
  .right-item {
    height: 100%;
    justify-content: space-between;
    color: #151a30;
    font-size: 15px;
    padding: 10px 8px;
  }
  .name {
    font-weight: 800;
  }
}
.not-received-content {
  max-height: 416px;
  overflow: auto;
  padding-right: 4px;
}
.not-received-name {
  color: #191e42;
  font-weight: 600;
  font-size: 15px;
}
.all::-webkit-scrollbar,
.notReceived-detail::-webkit-scrollbar,
.not-received-content::-webkit-scrollbar,
.box-content::-webkit-scrollbar {
  width: 4px;
  position: relative;
  left: 10px;
}
.all::-webkit-scrollbar-thumb,
.notReceived-detail::-webkit-scrollbar-thumb,
.not-received-content::-webkit-scrollbar-thumb,
.box-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.all::-webkit-scrollbar-track,
.notReceived-detail::-webkit-scrollbar-track,
.not-received-content::-webkit-scrollbar-track,
.box-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.right-box::-webkit-scrollbar {
  width: 4px;
  position: relative;
  left: 10px;
}
.right-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.right-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.measure-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.measure-col {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-right: 46px;
  font-size: 16px;
  color: #151a30;
  .name {
    font-weight: 700;
    font-style: normal;
  }
  .number {
    color: #63daab;
    font-weight: 700;
  }
}
.rent-col:hover {
  .hoverstyle {
    text-decoration: underline;
    cursor: pointer;
  }
}
.rent-col {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-right: 46px;
  font-size: 16px;
  color: #151a30;

  .name {
    font-weight: 700;
    font-style: normal;
  }
  .number {
    color: #63daab;
    font-weight: 700;
  }
}
</style>
<style lang="scss">
.ant-popover-arrow {
  display: none !important;
}
.ant-popover-inner {
  background: rgba(255, 255, 255, 0.9) !important;
  border: 2px solid #6395fa !important;
}
.popover-text {
}

.measure-box {
  height: 521px;
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 !important;
    padding: 12px 12px !important;
  }
}
</style>
