<template>
  <div class="company">
    <div class="flex-column content-box data">
      <div
        style="height: 100%; width: 100%; background: white; z-index: 3"
        v-show="roomSpin"
      >
        <a-spin
          tip="Loading..."
          size="large"
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #151c51 !important;
          "
        >
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 30px"
            spin
          />
        </a-spin>
      </div>
      <div v-if="companyData">
        <div
          class="title title-background"
          style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap"
          v-if="!showRoom"
          :title="companyData.roomName"
        >
          {{ companyData.roomName }}
        </div>
        <div
          class="title title-background"
          style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap"
          v-if="showRoom"
        >
          {{ "公司信息" }}
        </div>
        <div class="">
          <div class="companyName flex-row" @click="showCompanyInfo">
            <img src="../assets/data/company.svg" />
            <div style="margin-left: 12px">
              所属公司：{{ companyData.companyName }}
            </div>
          </div>
          <a-modal
            v-model="companyVisible"
            :title="false"
            :footer="null"
            :centered="true"
            :maskStyle="{ background: 'rgba(16, 36, 87, 0.65)' }"
            :closable="false"
            :forceRender="true"
            :bodyStyle="{
              width: '100%',
            }"
            width="720px"
            :zIndex="9999"
          >
            <div>
              <a-spin
                tip="Loading..."
                style="
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                "
                v-show="companySpin"
              >
              </a-spin>
              <div class="title-background flex-row modal-title">
                <div style="margin-left: 20px">
                  {{ companyData.companyName }}
                </div>
                <a-icon
                  type="close"
                  class="pointer"
                  style="color: #6395fa"
                  @click="companyVisible = false"
                />
              </div>
              <a-row class="expire-box">
                <a-col :span="10">
                  <div class="box-content">
                    <a-row type="flex">
                      <a-col
                        :span="12"
                        class="flex-column"
                        style="align-items: center"
                      >
                        <div class="num blue-content content-bg">
                          {{ companyAllData.allArea ? companyAllData.allArea.toFixed(1) : 0 }}
                        </div>
                        <div class="name">租赁面积(m²)</div>
                      </a-col>
                      <a-col
                        :span="12"
                        class="flex-column"
                        style="align-items: center"
                      >
                        <div class="num green-content content-bg">
                          {{
                            companyData.countAvg ?
                            (companyData.countAvg).toFixed(2)  :
                            0
                          }}
                        </div>
                        <div class="name">月租金(元/m²)</div>
                      </a-col>
                    </a-row>
                    <a-row class="rent-content">
                      <a-col :span="12" class="rent-box">
                        <div class="num" style="color: #6395fa">
                          {{ companyAllData.yearAllCount ? (companyAllData.yearAllCount / 10000).toFixed(2) : 0 }}
                        </div>
                        <div class="name">本年度已收租金</div>
                        <div class="name">（万元）</div>
                        <div class="blue-line" style="margin-top: 12px"></div>
                      </a-col>
                      <a-col :span="12" class="rent-box">
                        <div class="num" style="color: #79dfb7">
                          {{
                            companyAllData.monthAllCount ? (companyAllData.monthAllCount / 10000).toFixed(2) : 0
                          }}
                        </div>
                        <div class="name">本月度已收租金</div>
                        <div class="name">（万元）</div>
                        <div class="green-line" style="margin-top: 12px"></div>
                      </a-col>
                    </a-row>
                    <a-row class="rent-content">
                      <a-col :span="12" class="rent-box">
                        <div class="num" style="color: #6395fa">
                          {{
                            companyAllData.allBillsCount ? (companyAllData.allBillsCount / 10000).toFixed(2) : 0
                          }}
                        </div>
                        <div class="name">累计收入租金</div>
                        <div class="name">（万元）</div>
                        <div class="blue-line" style="margin-top: 12px"></div>
                      </a-col>
                      <a-col :span="12" class="rent-box">
                        <div class="num" style="color: #79dfb7">
                          {{
                            companyAllData.allNotReceivedCount ?
                            (
                              companyAllData.allNotReceivedCount / 10000
                            ).toFixed(2) :
                            0
                          }}
                        </div>
                        <div class="name">拖欠租金</div>
                        <div class="name">（万元）</div>
                        <div class="green-line" style="margin-top: 12px"></div>
                      </a-col>
                    </a-row>
                  </div>
                </a-col>
                <a-col :span="14" class="">
                  <div class="company-introduction">
                    <div class="enterprise-info">
                      所属行业：{{ companyAllData.companyBeBigIntIndustry }}
                    </div>
                    <div class="enterprise-info">
                      企业关键人：{{ companyAllData.companyKeyPerson }}
                    </div>
                    <div class="enterprise-info">
                      企业联系人：{{ companyAllData.companyLinkPerson }}
                    </div>
                    <div class="enterprise-info">
                      企业资质：{{
                        companyAllData.companyEnterpriseQualification
                      }}
                    </div>
                    <div class="enterprise-info">
                      高层次人才数量：{{
                        companyAllData.companyHighLevelTalentCount
                      }}
                    </div>
                    <div class="enterprise-info">
                      企业融资情况：{{ companyAllData.companyFinancing }}
                    </div>
                    <div class="enterprise-info" style="text-align: justify">
                      企业简介：{{ companyAllData.companyIntroduction }}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-modal>
        </div>
        <a-row type="flex">
          <a-col :span="12" class="flex-column" style="align-items: center">
            <div class="num blue-content content-bg">
              {{ companyData.allArea ? companyData.allArea.toFixed(1) : 0 }}
            </div>
            <div class="name">租赁面积(m²)</div>
          </a-col>
          <a-col :span="12" class="flex-column" style="align-items: center">
            <div class="num green-content content-bg">
              {{ companyData.countAvg ? companyData.countAvg.toFixed(1) : 0 }}
            </div>
            <div class="name">月租金(元/m²)</div>
          </a-col>
          <!-- <a-col :span="8" class="flex-column" style="align-items: center">
            <div class="num yellow-content content-bg">
              {{ (companyData.increaseAvg * 100).toFixed(1) }}%
            </div>
            <div class="name">递增幅度(年)</div>
          </a-col> -->
        </a-row>
        <a-row class="rent-content">
          <a-col :span="12" class="rent-box">
            <div class="num" style="color: var(--blue-color)">
              {{ companyData.yearAllCount ? (companyData.yearAllCount / 10000).toFixed(2) : 0 }}
            </div>
            <div class="name">本年度已收租金</div>
            <div class="name">（万元）</div>
            <div class="blue-line" style="margin-top: 12px"></div>
          </a-col>
          <a-col :span="12" class="rent-box">
            <div class="num" style="color: var(--green-color)">
              {{ companyData.monthAllCount ? (companyData.monthAllCount / 10000).toFixed(2) : 0 }}
            </div>
            <div class="name">本月度已收租金</div>
            <div class="name">（万元）</div>
            <div class="green-line" style="margin-top: 12px"></div>
          </a-col>
        </a-row>
        <a-row class="rent-content">
          <a-col :span="12" class="rent-box">
            <div class="num" style="color: var(--blue-color)">
              {{ companyData.allBillsCount ? (companyData.allBillsCount / 10000).toFixed(2) : 0 }}
            </div>
            <div class="name">累计收入租金</div>
            <div class="name">（万元）</div>
            <div class="blue-line" style="margin-top: 12px"></div>
          </a-col>
          <a-col :span="12" class="rent-box">
            <div class="num" style="color: var(--green-color)">
              {{ companyData.allNotReceivedCount ? (companyData.allNotReceivedCount / 10000).toFixed(2) : 0 }}
            </div>
            <div class="name">拖欠租金</div>
            <div class="name">（万元）</div>
            <div class="green-line" style="margin-top: 12px"></div>
          </a-col>
        </a-row>
      </div>
      <!-- <div class="title title-background" v-if="showRoom">合同信息</div> -->
      <div class="contractTitle flex-row" v-if="companyData">
        <img src="../assets/data/contract.svg" />
        <div style="margin-left: 12px">合同信息</div>
      </div>
      <div v-if="companyData">
        <div class="contract-box">
          <div>
            <a-row class="row-title">
              <a-col :span="8" style="text-align: center">开始时间</a-col>
              <a-col :span="8" style="text-align: center">结束时间</a-col>
              <a-col :span="8" style="text-align: center"></a-col>
            </a-row>
            <a-row
              v-for="(item, index) in contract"
              :key="index"
              class="row-content"
            >
              <a-col :span="8" style="text-align: center">{{
                item.startDate
              }}</a-col>
              <a-col :span="8" style="text-align: center">{{
                item.endDate
              }}</a-col>
              <a-col
                :span="8"
                class="pointer"
                style="text-align: center; color: var(--blue-color)"
                @click="showContract(item.id)"
                >详情</a-col
              >
            </a-row>
          </div>
          <div class="popover-bottom"></div>
        </div>
      </div>
      <a-modal
        v-model="contractVisible"
        :title="false"
        :footer="null"
        :centered="true"
        :closable="false"
        :maskStyle="{ background: 'rgba(16, 36, 87, 0.65)' }"
        :bodyStyle="{
          width: '100%',
        }"
        width="500px"
        :zIndex="9999"
      >
        <div>
          <div class="title-background flex-row modal-title">
            <div style="margin-left: 20px">合同信息</div>
            <a-icon
              type="close"
              class="pointer"
              style="color: #6395fa"
              @click="contractVisible = false"
            />
          </div>
          <div class="company-box">
            <div class="flex-row right-item">
              <div class="name">合同编号</div>
              <div>{{ companyInfo.number }}</div>
            </div>
            <div class="flex-row right-item">
              <div class="name">总金额</div>
              <div>{{ companyInfo.amount }}元</div>
            </div>
            <div class="flex-row right-item">
              <div class="name">联系人电话</div>
              <div>{{ companyInfo.linkPhone }}</div>
            </div>
            <div class="flex-row right-item">
              <div class="name">经办人名称</div>
              <div>{{ companyInfo.manager }}</div>
            </div>
            <div class="flex-row right-item">
              <div class="name">房间名称</div>
              <div>{{ companyInfo.roomName }}</div>
            </div>
            <div class="flex-row right-item">
              <div class="name">电话</div>
              <div>{{ companyInfo.phone }}</div>
            </div>
            <div class="flex-row right-item">
              <div class="name">证件号码</div>
              <div>{{ companyInfo.certificateNumber }}</div>
            </div>
            <div class="flex-row right-item">
              <div class="name">开始日期</div>
              <div>{{ companyInfo.startDate }}</div>
            </div>
            <div class="flex-row right-item">
              <div class="name">结束日期</div>
              <div>{{ companyInfo.endDate }}</div>
            </div>
            <div class="flex-row right-item">
              <div class="name">合同租金</div>
              <div>{{ companyInfo.rentAmount }}元</div>
            </div>
            <div class="flex-row right-item">
              <div class="name">乙方名称</div>
              <div>{{ companyInfo.partBName }}</div>
            </div>
            <div class="flex-row right-item">
              <div class="name">甲方名称</div>
              <div>{{ companyInfo.parkAName }}</div>
            </div>
            <div class="flex-row right-item">
              <div class="name">证件类型</div>
              <div>{{ companyInfo.certificateType }}</div>
            </div>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Api } from "../api/api.js";
export default {
  props: {
    rId: Number,
    showRoom: Boolean,
  },
  data() {
    return {
      companyData: null,
      contract: [],
      companyVisible: false,
      contractVisible: false,
      companyInfo: {},
      companyId: null,
      companyAllData: {},
      companySpin: true,
      roomSpin: false,
    };
  },
  watch: {
    async rId(e) {
      // console.log(e,'rId');
      this.getCompany(e);
      this.getCompanyContract(e);
    },
  },
  async created() {
    // this.fId = this.$route.query.fId;
    // this.getCompany(this.rId);
    // this.getCompanyContract(this.rId);
    // this.getCompanysInfo(this.companyId);
  },
  mounted() {

  },
  methods: {
    async getCompany(id) {

      this.companyData = null;
      this.roomSpin = true;
      const res = await Api.getCompany(id);
      this.companyData = res.data.data[0];
      this.roomSpin = false;
      if (this.companyData.insideTime) {
        this.companyData.insideTime = moment(
          this.companyData.insideTime
        ).format("YYYY/MM/DD");
      } else {
        this.companyData.insideTime = "";
      }
      this.companyId = this.companyData.companyId;
      let item = localStorage.getItem("role");
      let items = item.split(",")
      let item1 = ["YJQX","CQSJQX","DSSJQX","ZJSJQX","GYSSSJQX","SJQX"];
      for (let itemElement of items) {
        if (item1.includes(itemElement)){
          this.getCompanysInfo(this.companyId);
          return;
        }
      }
    },
    async getCompanysInfo(id) {
      this.companySpin = true;
      const res = await Api.getCompanysInfo(id);
      this.companyAllData = res.data.data[0];
      this.companySpin = false;
    },
    async getCompanyContract(id) {
      const res = await Api.getCompanyContract(id);
      this.contract = res.data.data;
      this.contract.forEach((item, index) => {
        item.startDate = moment(item.startDate).format("YYYY/MM/DD");
        item.endDate = moment(item.endDate).format("YYYY/MM/DD");
      });
    },
    showCompanyInfo() {
      let item = localStorage.getItem("role");
      let items = item.split(",")
      let item1 = ["YJQX","CQSJQX","DSSJQX","ZJSJQX","GYSSSJQX","SJQX"];
      for (let itemElement of items) {
        if (item1.includes(itemElement)){
          this.companyVisible = true;
          return;
        }
      }
      // if (localStorage.getItem('role') !== 'EJQX') {
      //   this.companyVisible = true;
      // }
    },
    async showContract(id) {
      const res = await Api.getcontractTenancyId(id);
      this.companyInfo = res.data;
      this.companyInfo.startDate = moment(this.companyInfo.startDate).format(
        "YYYY/MM/DD"
      );
      this.companyInfo.endDate = moment(this.companyInfo.endDate).format(
        "YYYY/MM/DD"
      );
      this.contractVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .num {
    font-family: DIN;
    font-style: normal;
    font-weight: bold;
    // font-size: 24px !important;
    text-align: center;
  }
  .title {
    // font-size: 18px !important;
  }
  .companyName {
    // font-size: 16px !important;
  }
  .contract-box {
    .row-title {
      color: #6395fa;
      // font-size: 14px !important;
      border-bottom: 1px solid #6395fa;
      line-height: 46px;
    }
    .row-content {
      color: black;
      // font-size: 14px !important;
      border-bottom: 1px solid rgba(1, 103, 255, 0.3);
      line-height: 46px;
    }
    font-size: 16px;
  }
  .contractTitle {
    font-weight: 800;
    // font-size: 18px;
    color: #055fcd;
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 1300px) {
  .company {
    height: 100%;
    background: #ffffff;
    padding: 14px !important;
    .content-box {
      height: 100%;
    }
    .box-title {
      font-size: 18px !important;
    }
    .title {
      // height: 48px;
      // line-height: 24px !important;
      // font-size: 16px !important;

      // justify-content: space-between;
    }
    .num {
      font-family: DIN;
      font-style: normal;
      font-weight: bold;
      // font-size: 20px !important;
      text-align: center;
    }
    .name {
      // font-size: 12px !important;
      color: #000;
      text-align: center;
    }
    .rent-box {
    }
    .rent-content {
      margin-top: 16px !important;
    }
    .data {
      .blue-content {
        background-image: url("../assets/index/companyBlue.png");

        color: var(--blue-color);
      }
      .green-content {
        background-image: url("../assets/index/companyGreen.png");
        color: var(--green-color);
      }
      .yellow-content {
        background-image: url("../assets/index/companyYellow.png");
        color: var(--yellow-color);
      }
      .enterprise-status {
        margin-top: 24px !important;
        position: relative;
        .contract-popover {
          content: "";
          left: -40px;
        }
        .yellow-text {
          color: var(--yellow-color);
          font-weight: bold;
          font-size: 14px;
        }
        .red-text {
          color: var(--red-color);
          font-weight: bold;
          font-size: 14px;
        }
      }
      .company-introduction {
        margin-top: 24px !important;
      }
      .enterprise-info {
        // height: 20px;
        background-image: url("../assets/index/circle.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;
        padding: 0 20px;
        background-position-y: top;
        font-size: 14px !important;
        color: #000;
        margin-bottom: 12px !important;

        // background-position: ;
      }
    }
  }
}
@media screen and (max-width: 1600px) and (min-width: 1300px) {
  .company {
    height: 100%;
    background: #ffffff;
    padding: 14px !important;
    .content-box {
      height: 100%;
    }
    .title {
      // height: 48px;
      // line-height: 24px !important;
      // justify-content: space-between;
    }
    .num {
      font-family: DIN;
      font-style: normal;
      font-weight: bold;
      // font-size: 20px !important;
      text-align: center;
    }
    .name {
      // font-size: 12px !important;
      color: #000;
      text-align: center;
    }
    .rent-box {
    }
    .rent-content {
      margin-top: 16px !important;
    }
    .data {
      height: 100%;
      .enterprise-status {
        margin-top: 24px !important;
        position: relative;
        .contract-popover {
          content: "";
          position: fixed;
          left: -40px;
        }
        .yellow-text {
          color: var(--yellow-color);
          font-weight: bold;
          font-size: 14px;
        }
        .red-text {
          color: var(--red-color);
          font-weight: bold;
          font-size: 14px;
        }
      }
      .company-introduction {
        margin-top: 24px !important;
      }
      .enterprise-info {
        // height: 20px;
        background-image: url("../assets/index/circle.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;
        padding: 0 20px;
        background-position-y: top;
        font-size: 14px !important;
        color: #000;
        margin-bottom: 12px !important;

        // background-position: ;
      }
    }
  }
}

.content-bg {
  height: 100px !important;
  width: 100px !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  padding-bottom: 8px;
}
.blue-content {
  background-image: url("../assets/index/companyBlue.png");
  color: #6395fa;
}
.green-content {
  background-image: url("../assets/index/companyGreen.png");
  color: #79dfb7;
}
.yellow-content {
  background-image: url("../assets/index/companyYellow.png");
  color: #ffca14;
}
.blue-line {
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    #6395fa,
    rgba(0, 0, 0, 0) 100%
  );
  width: 80%;
  margin: auto;
}
.green-line {
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    #79dfb7,
    rgba(0, 0, 0, 0) 100%
  );
  width: 80%;
  margin: auto;
}
.modal-title {
  height: 44px;
  justify-content: space-between;
}
.title {
  height: 4.8rem;
  line-height: 4.8rem;
  display: flex;
  align-items: center;
  font-size: 2rem;
  margin-bottom: 24px;
  // justify-content: space-between;
}
.num {
  font-family: DIN;
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
}
.rent-content {
  margin-top: 22px;
}
.name {
  font-size: 1.6rem;
  color: #000;
  text-align: center;
}
.company {
  height: 100%;
  background: #ffffff;
  padding: 24px;
  .content-box {
    height: 100%;
  }

  .companyName {
    font-weight: 800;
    // font-size: 18px;
    font-size: 1.8rem;
    color: #055fcd;
    cursor: pointer;
  }
  .title {
    // height: 48px;
    // line-height: 48px;
    display: flex;
    align-items: center;

    // justify-content: space-between;
  }
  .num {
    font-family: DIN;
    font-style: normal;
    font-weight: bold;
    // font-size: 30px;
    text-align: center;
  }
  .rent-content {
    margin-top: 22px;
  }
  .name {
    // font-size: 16px;
    color: #000;
    text-align: center;
  }
  .rent-box {
  }
  .data {
    height: 100%;
    overflow-y: auto;
    .contract-box {
      .row-title {
        color: #6395fa;
        font-size: 1.6rem;
        border-bottom: 1px solid #6395fa;
        line-height: 46px;
      }
      .row-content {
        color: black;
        font-size: 1.6rem;
        border-bottom: 1px solid rgba(1, 103, 255, 0.3);
        line-height: 46px;
      }
    }
    .contractTitle {
      font-weight: 800;
      font-size: 1.8rem;
      color: #055fcd;
      margin-top: 32px;
    }
    .enterprise-status {
      margin-top: 40px;
      position: relative;
      .contract-popover {
        content: "";
        position: fixed;
        left: -10px;
        bottom: 20px;
      }
      .yellow-text {
        color: var(--yellow-color);
        font-weight: bold;
        font-size: 14px;
      }
      .red-text {
        color: var(--red-color);
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
.company-introduction {
  margin-top: 40px;
  height: 388px;
  overflow-y: auto;
  padding-left: 16px;
}
.enterprise-info {
  // height: 20px;
  background-image: url("../assets/index/circle.png");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  padding: 0 20px;
  background-position-y: top;
  font-size: 16px;
  color: #000;
  margin-bottom: 20px;

  // background-position: ;
}
.popover-box {
  padding: 12px;
  width: 432px;
  height: 380px;
  .box-title {
    height: 44px;
  }
  .popover-bottom {
    position: absolute;
    width: calc(100% - 70px);
    background-image: url("../assets/index/line.png");
    background-size: 100% 100%;
    height: 1px;
    bottom: 24px;
  }
}
.right-item {
  height: 100%;
  justify-content: space-between;
  color: #151a30;
  font-size: 15px;
  padding: 0 8px;
  margin-top: 20px;
  .name {
    font-weight: 800;
  }
}
.company-box {
  max-height: 580px;
  overflow-y: auto;
}
.box-content {
  margin-top: 20px;
  width: 100%;
}
.company-box::-webkit-scrollbar {
  width: 2px;
  position: relative;
  left: 10px;
}
.company-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.company-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.data::-webkit-scrollbar {
  width: 2px;
  position: relative;
  left: 10px;
}
.data::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.data::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.company-introduction::-webkit-scrollbar {
  width: 2px;
  position: relative;
  left: 10px;
}
.company-introduction::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.company-introduction::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
</style>
