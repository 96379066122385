import axios from 'axios'
import QS from 'qs';
import {
    Spin
} from 'ant-design-vue'
import {
    message
} from 'ant-design-vue'
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

axios.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        config.headers.Authorization = localStorage.getItem('token')
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        Spin.hide();
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    config => {
        //在response拦截器中，隐藏进度条
        // console.log(config)
        return config
    },
    error => {
        Spin.hide();
        message.error('服务内部错误');
        console.log('1111', error);
        // 对响应错误做点什么
        return Promise.reject(error);
    })

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,

        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

export function cancelGet(url, cancelData) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            cancelToken: cancelData
        }).then(res => {
            resolve(res.data);
        }).catch(err => reject(err));
    });
}

export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}


export function postJson(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params, {
                'Content-Type': 'application/json'
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

export function getJson(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, params,  {
            'Content-Type': 'application/json'
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}
export function getArray(url, params) {

    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
            responseType: "blob"
        }
        ).then(res => {
            resolve(res.data);
            /*console.log(data);
            const content = data;
            const blob = new Blob([content]);
            if ("download" in document.createElement("a")) {
                const elink = document.createElement("a");
                elink.download = "123.pdf";
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href);
                document.body.removeChild(elink);
            } else {
                navigator.msSaveBlob(blob, "123.pdf");
            }*/

        }).catch(err => {
            reject(err.data)
        })
    });

}
