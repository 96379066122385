<template>
  <div class="resources">
    <a-row style="height: 100%" type="flex" class="flex-row">
      <!-- tag:左侧地图 -->
      <a-col :span="18" style="height: 100%" class="left-content">
        <div :class="navClass">
          <a-breadcrumb class="breadcrumb">
            <span slot="separator" style="color: var(--blue-color)">></span>
            <a-breadcrumb-item href="">银星科技园</a-breadcrumb-item>
          </a-breadcrumb>
          <!-- <div
            style="color: var(--blue-color); font-size: 16px"
            class="pointer"
            @click="backPre"
          >
            返回
          </div> -->
        </div>
        <div style="height: 100%" id="mapContent" :key="key" :class="mapClass">
          <a-tabs
            default-active-key="1"
            tab-position="top"
            :style="{ height: '44px' }"
            @prevClick="callback"
            @nextClick="callback"
            class="floor-change"
            v-show="tabShow"
          >
            <a-tab-pane v-for="i in 30" :key="i" :tab="`${i}F`">
              {{ i + "F" }}
            </a-tab-pane>
          </a-tabs>
          <a-popover
            v-model="measureVisible"
            trigger="click"
            placement="leftTop"
          >
            <template slot="content">
              <div class="popover-box" @click="enter">
                <div
                  class="box-title title-background flex-row pointer"
                  style="justify-content: space-between"
                >
                  {{ areaData.areaName }}
                  <!-- <span
                    style="
                      cursor: pointer;
                      color: #055fcd !important;
                      font-size: 18px;
                    "
                    @click="enter"
                    >进入</span
                  > -->
                </div>
                <div class="box-content pointer">
                  <div class="text">
                    资源面积（万m²）：{{
                      (parseInt(areaData.space) / 10000).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    可出租面积（万m²）：{{
                      (parseInt(areaData.leasableArea) / 10000).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    已出租面积（万m²）：{{
                      (parseInt(areaData.rentedArea) / 10000).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    空置面积（万m²）：{{
                      (parseInt(areaData.availableArea) / 10000).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    自用面积（万m²）：{{
                      (parseInt(areaData.selfArea) / 10000).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    出租率（%）：{{
                      (areaData.rentedPercentage * 100).toFixed(2)
                    }}%
                  </div>
                  <div class="text">
                    空置率（%）：{{
                      (areaData.availablePercentage * 100).toFixed(2)
                    }}%
                  </div>
                  <div class="text">
                    本年度退租面积（万m²）：{{
                      (
                        parseInt(areaData.yearSurrenderTenancyArea) / 10000
                      ).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    本月度退租面积（万m²）：{{
                      (
                        parseInt(areaData.monthSurrenderTenancyArea) / 10000
                      ).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    本年度已收租金（万元）：{{
                      (parseInt(areaData.yearAmount) / 10000).toFixed(2)
                    }}
                  </div>
                  <div class="text">
                    本月度已收租金（万元）：{{
                      (parseInt(areaData.monthAmount) / 10000).toFixed(2)
                    }}
                  </div>
                </div>
              </div>
            </template>
            <div class="measure-popover"></div>
          </a-popover>
        </div>
      </a-col>
      <!-- tag:右边数据栏 -->
      <a-col
        :span="6"
        style="
          height: 100%;
          padding-left: 24px;
          position: relative;
          right: -600px;
        "
        :class="transform"
      >
        <Allresources :id="4" :level="'park'"></Allresources>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Api } from "../../../api/api.js";
import "ol/ol.css";
import { Map, View, Overlay, Feature } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorSource, ImageStatic } from "ol/source";
import { Vector as VectorLayer, Image } from "ol/layer";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
// import render3D from "ol-ext/layer/Render3D";
import { Draw, Select, DoubleClickZoom } from "ol/interaction";
import { getCenter, boundingExtent } from "ol/extent";
import { createBox } from "ol/interaction/Draw";
import { click, doubleClick, pointerMove, focus } from "ol/events/condition";
import Allresources from "../../../components/allResources.vue";
var container, stats, controls;
var camera, scene, renderer, light, mesh;
export default {
  components: {
    Allresources,
  },
  data() {
    return {
      measureVisible: false,
      key: 0,
      tabShow: false,
      width: 0,
      height: 0,
      parkId: undefined,
      parkData: {},
      areaData: {},
      parkJson: null,
      parkImage: null,
      aId: null,
      transform: null,
      mapClass: ["animate__animated", "animate__delay-0.5s", "animate__zoomIn"],
      navClass: ["map-name", "animate__animated", "animate__backInDown"],
      zoom: 16,
    };
  },
  async mounted() {
    this.parkId = this.$route.query.parkId;
    this.width = document.getElementById("mapContent").clientWidth;
    this.height = document.getElementById("mapContent").clientHeight;
    await this.getAreasList();
    await this.getPark();
    if (window.innerWidth < 1900 && window.innerWidth > 1600) {
      this.zoom = 15.8;
    } else if (window.innerWidth < 1600) {
      this.zoom = 15.6;
    }
    this.mapInit();
    setTimeout(() => {
      this.transform = "right";
    }, 500);
  },
  methods: {
    keyadd() {
      this.key++;
    },
    getPower({ code, arType })  {
      if (null != code){
        let item = localStorage.getItem("role");
        console.log("localStorage_item")
        console.log(item)
        if(item){
          let items = item.split(",")
          for (let item of items) {
            if (code.includes(item)){
              return true;
            }
          }
        }

      }

      // if (code.find((item)=>data.indexOf(item) !== -1)) {
      //   return true;
      // }
    },
    // done:地图初始化
    mapInit() {
      var image = new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({ color: "red", width: 1 }),
      });
      var styles = {
        Point: new Style({
          image: image,
        }),
        LineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiLineString: new Style({
          stroke: new Stroke({
            color: "green",
            width: 1,
          }),
        }),
        MultiPoint: new Style({
          image: image,
        }),
        MultiPolygon: new Style({
          stroke: new Stroke({
            color: "black",
            width: 1,
          }),
          fill: new Fill({
            color: "white",
          }),
        }),
        Polygon: new Style({
          // stroke: new Stroke({
          //   color: "rgba(255,255,255,1)",
          //   width: 3,
          // }),
          fill: new Fill({
            color: "rgba(119,136,153, 0.1)",
          }),
        }),
        GeometryCollection: new Style({
          stroke: new Stroke({
            color: "magenta",
            width: 2,
          }),
          fill: new Fill({
            color: "magenta",
          }),
          image: new CircleStyle({
            radius: 10,
            fill: null,
            stroke: new Stroke({
              color: "magenta",
            }),
          }),
        }),
        Circle: new Style({
          stroke: new Stroke({
            color: "red",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(255,0,0,0.2)",
          }),
        }),
      };

      var styleFunction = function (feature) {
        return styles[feature.getGeometry().getType()];
      };
      this.vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(this.parkJson),
        wrapX: false,
        zIndex: 9998,
      });

      this.vectorLayer = new VectorLayer({
        source: this.vectorSource,
        style: styleFunction,
        zIndex: 9998,
      });
      var center = [500, -600];
      // let width = document.getElementById("mapContent").clientWidth;
      // let height = document.getElementById("mapContent").clientHeight;
      //   var center = transform([500, -600], "EPSG:4326", "EPSG:3857");
      //计算静态地图映射到地图上的范围，图片像素为550*344，保持比例的情况下，把分辨率放大一些
      var extent = [
        center[0] - (1700 * 2) / 2,
        center[1] - (1050 * 2) / 2,
        center[0] + (1700 * 2) / 2,
        center[1] + (1050 * 2) / 2,
      ];
      var extent2 = [
        center[0] - (1700 * 2.6) / 2,
        center[1] - (1050 * 2.6) / 2,
        center[0] + (1700 * 2.6) / 2,
        center[1] + (1050 * 2.6) / 2,
      ];
      // var extent = [
      //   center[0] - (1320 * 2) / 2,
      //   center[1] - (685 * 2) / 2,
      //   center[0] + (1320 * 2) / 2,
      //   center[1] + (685 * 2) / 2,
      // ];
      this.map = new Map({
        layers: [this.vectorLayer],

        view: new View({
          center: center,
          extent: extent2,
          //   center: [500, -600],
          zoom: this.zoom, // 定义地图显示层级为2
          // padding: [100, 0, 0, 0],
        }),
        // 让id为map的div作为地图的容器
        target: "mapContent",
      });
      const dblClickInteraction = this.map
        .getInteractions()
        .getArray()
        .find((interaction) => {
          return interaction instanceof DoubleClickZoom;
        });
      this.map.removeInteraction(dblClickInteraction);
      // done:添加底图
      this.map.addLayer(
        new Image({
          source: new ImageStatic({
            // url: "https://minio.yshinfo.com/yinxing/mryeov2eyx6nluj3hgof3utsh56k",
            url: this.parkImage, // 静态地图
            // url: 'index.jpg', // 静态地图
            imageExtent: extent, //映射到地图的范围
          }),
        })
      );
      let that = this;


      // done:图形双击
      let selectClick = new Select({
        // 事件类型
        condition: doubleClick,
        // 点击后的样式
        style: new Style({
          fill: new Fill({
            color: "rgba(99,149,250,0.5)",
          }),
        }),
      });
      // selectClick.on("select", function (e) {
      //   that.$router.push({
      //     path: "/resources/area",
      //     query: { parkId: that.parkId, aId: e.selected[0].values_.id },
      //   });
      // });
      // this.map.addInteraction(selectClick);
      //done:图形点击
      var select = new Select({
        condition: click,
        style: function (fea, res) {
          return [
            new Style({
              fill: new Fill({
                color: "rgba(99,149,250,0.5)",
              }),
            }),
          ];
        },
      });
      select.on("select", function (e) {
        // var view = that.map.values_.view

        // // 设置地图等级
        // view.setZoom(16.5);
        if (e.selected.length == 0) {
          // that.measureVisible = false;
        } else {
          that.aId = e.selected[0].values_.id;
          console.log("e.selected[0]")
          console.log(e.selected[0])
         if(that.getPower({code: e.selected[0].values_.role})){
           that.enter();
         }else {
           that.$message.error('无权限访问')
         }
          // that.getAreaData(e.selected[0].values_.id);
          // const idList = {
          //   1:
          // }
        }

      });
      //done:弹窗展示
      var element;
      var itemCenter;
      var popup;
      var popupName;
      let featureList = this.vectorSource.getFeatures();
      // featureList.forEach((item, index) => {
      //   popupName = "popup" + index;
      //   popup = new Overlay({
      //     element: document.getElementById(popupName),
      //   });
      //   element = popup.element;
      //   that.map.addOverlay(popup);
      //   itemCenter = getCenter(
      //     boundingExtent(item.getGeometry().getCoordinates()[0])
      //   ); // 获取图形中点
      //   itemCenter[1] = itemCenter[1] + 20;
      //   popup.setPosition(itemCenter); // 将弹窗位置设置为图形中点
      //   element.innerHTML =
      //     "<p style='color:white;font-size:20px;'> " + item.values_.id + "</p>";
      // });
      this.map.addInteraction(select);
      //   var r3D = new render3D({ height: 100, defaultHeight: 10 });
      //   vectorLayer.setRender3D(r3D);
    },
    callback() {
      this.key++;
      setTimeout(() => {
        this.mapInit();
      }, 1000);
    },
    next() {
      this.key++;
      setTimeout(() => {
        this.map3DInit();
      }, 1000);
    },
    // done:查询所有区域
    async getAreasList() {
      const res = Api.getAreasList(this.parkId);
    },
    // done:获取区域数据
    // async getAreaData(areaId) {
    //   const res = await Api.getAreaData(areaId);
    //   this.areaData = res.data;
    //   // this.measureVisible = true;
    // },
    // done:获取园区底图和geojson
    async getPark() {
      const res = await Api.getParkJson(this.parkId);
      this.parkJson = res.data.parkData;
      this.parkImage = res.data.parkPicture;
    },
    // done:进入
    enter() {
      this.transform = "left";
      this.measureVisible = false;
      this.mapClass.splice(1, 1);
      this.navClass.push("animate__backOutUp");
      this.mapClass.push("animate__zoomOut");
      setTimeout(() => {
        this.$router.push({
          path: "/resources/area",
          query: { parkId: this.parkId, aId: this.aId },
        });
      }, 400);
    },
    backPre() {
      history.back();
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1600px) {
  .map-name {
    height: 44px !important;
    width: calc(100% - 50px);
    background-image: url("../../../assets/index/yinxingName.png");
    background-size: 100% 112%;
    position: absolute;
    z-index: 9998;
    left: 25px;
    // left: 50%;
    // transform: translateX(-50%);
    top: 16px;
  }
}
.resources {
  height: 100%;
  padding: 24px;
  .left-content {
    height: 100%;
    position: relative;
    background: white;
    // background-image: url("../../assets/index/yinxingBg.png");
    // background-size: 100% 100%;
    // background-position: center;
    .map-name {
      height: 60px;
      width: calc(100% - 50px);
      background-image: url("../../../assets/index/yinxingName.png");
      background-size: 100% 112%;
      position: absolute;
      z-index: 9998;
      left: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 86px;
      // left: 50%;
      // transform: translateX(-50%);
      top: 16px;
      .ol-control {
        display: none;
      }
      .breadcrumb {
        // position: absolute;
        // top: 50%;
        font-size: 16px;
        color: var(--blue-color);
        // transform: translateY(-50%);
        span:last-child a {
          font-family: Microsoft YaHei UI;
          font-weight: bold;
          font-size: 20px;
          letter-spacing: 0.12em;
          color: #055fcd;
        }
      }
    }
    .measure-popover {
      position: absolute;
      // top: 50% !important;
      left: 67% !important;
      top: 25%;
      // transform: translate();
    }
  }
}
.popover-box {
  padding: 12px;
  width: 432px;
  height: 380px;
  .box-title {
    height: 44px;
  }
}
.box-content {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .text {
    font-size: 16px;
    color: #151a30;
    line-height: 200%;
    margin-right: 20px;
  }
}
</style>
<style lang="scss">
.breadcrumb {
  span:last-child a {
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.12em;
    color: #055fcd !important;
  }
}
.ol-control {
  display: none;
}
#mapContent {
  .ant-tabs-nav .ant-tabs-tab {
    background: #f0f5ff;
    border-radius: 6px;
    width: 54px;
  }
  .ant-tabs-tab-prev-icon,
  .ant-tabs-tab-next-icon {
    line-height: 40px;
    width: 100%;
    background-color: #f0f5ff;
    /* margin-right: 20px !important; */
    color: var(--blue-color);
  }
  .ant-tabs-bar {
    border: none !important;
  }
}
</style>
